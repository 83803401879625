import { JSX, Show } from "solid-js";
import { Container } from "../../common/uiKit/Container";
import { Spinner } from "../../common/uiKit/Spinner";

export function PatientSubpage(props: {
  title: JSX.Element;
  loading?: boolean;
  children?: JSX.Element;
}) {
  return (
    <div>
      <h3>{props.title}:</h3>

      <Show
        when={!props.loading}
        fallback={
          <Container row justifyContent="center">
            <Spinner />
          </Container>
        }
      >
        {props.children}
      </Show>
    </div>
  );
}
