import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { medicalOptions } from "../../common/constants";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellRadioField } from "../../common/uiKit/tables/cellFields/CellRadioField";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddAnyData } from "./AddAnyData";

export function AddAnyDataTable(props: { patient: Patient }) {
  const model = reactive(new AddAnyData(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      columns={[
        {
          header: "Measured at",
          mandatory: true,
          cell: (item) => (
            <CellDateField
              attr="measurement_datetime"
              model={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Measurement type",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="measurement_type"
              model={item}
              values={model.variables}
              listTitle="Select data to add"
            />
          ),
        },
        {
          header: "Measurement value",
          mandatory: true,
          cell: (item) => (
            <>
              <Show when={!(item.measurement_type in medicalOptions)}>
                <CellTextField
                  attr="measurement_value"
                  model={item}
                  width={50}
                />
              </Show>
              <Show when={item.measurement_type in medicalOptions}>
                <CellRadioField<string | number>
                  attr="measurement_value"
                  model={item}
                  values={medicalOptions[item.measurement_type]}
                />
              </Show>
            </>
          ),
        },
        {
          header: "Measurement unit",
          cell: (item) => model.getUnits(item.measurement_type, true),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
        {
          header: "Remove data row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
      model={model}
      canAddRows
      setAllDates="datetime"
    />
  );
}
