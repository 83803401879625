import { Show } from "solid-js";
import { ChoosingModalModel } from "../../choosingModal/ChoosingModalModel";

export function CellSelectRow(props: {
  model: ChoosingModalModel;
  id: string;
  disabled?: boolean;
}) {
  return (
    <>
      <Show when={props.model.selectionMode === "single"}>
        <input
          type="radio"
          checked={props.model.isSelected(props.id)}
          onChange={() => {
            props.model.select(props.id);
          }}
          disabled={props.disabled}
        />
      </Show>

      <Show when={props.model.selectionMode === "multi"}>
        <input
          type="checkbox"
          checked={props.model.isSelected(props.id)}
          onChange={() => {
            if (props.model.isSelected(props.id)) {
              props.model.deselect(props.id);
            } else {
              props.model.select(props.id);
            }
          }}
          disabled={props.disabled}
        />
      </Show>
    </>
  );
}
