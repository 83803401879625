import { apiGetUsers, apiGetUsersPhysicians } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Users extends DataModel {
  userMapping = {};

  constructor(public api: ApiConnector) {
    super([], api.userLevel, api.userUUID);
    this.sortBy = ["username", "created_at"];
    this.sortByNames = ["Username", "Added"];
    this.dateTimeFields = ["created_at", "last_login"];
    this.stringFields = ["username"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["role", "active", "monitoring_team", "added_by"];
    this.editable = [
      "connected_patient_id",
      "preferred_language",
      "managed_patients",
    ];
    this.defaultFilter = {
      role: [
        "admin",
        "seerlinq-user",
        "study-physician",
        "physician",
        "patient",
      ],
    };
    this.queryKey = "username";

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.none },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.none },
      historyMinLevel: null,
    };
  }

  dataInit() {
    this.data = this.data.map((item) => {
      if (item.monitoring_team != null) {
        var monTeam = item.monitoring_team.display_name;
      } else {
        var monTeam = null;
      }
      return { ...item, ["monitoring_team"]: monTeam };
    });
    super.init();
    this.getMapping();
  }

  async init() {
    const users = await apiGetUsers();
    this.data = users.data.users;
    this.dataInit();
  }

  async initPhysiciansOnly() {
    const users = await apiGetUsersPhysicians();

    this.data = users.data.users;
    this.dataInit();
  }

  getMapping() {
    for (const user of this.data) {
      this.userMapping[user.uuid] = user.username;
    }
  }

  generateLinks(ids: number[]) {
    return ids
      .map(
        (id) =>
          `<strong><a style="color: #bb16a3" href="/patient/${id}">${id}</a></strong>`,
      )
      .join(", ");
  }

  getEditedField(index: number) {
    const field = super.getEditedField(index);
    field["managed_patients"] = field["managed_patients"].map(
      (p) => p.patient_id,
    );
    return field;
  }
}
