import { DefaultPatientAlertResponse } from "../../api";
import { DataModel } from "../../common/DataModel";
import { alertTypes } from "../../common/enumToText";
import { AccessRules } from "../../common/roles";

export class Alerts extends DataModel {
  // constants
  alertTypes = alertTypes;

  constructor(
    data: DefaultPatientAlertResponse[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.sortBy = ["patient_id", "alert_timestamp"];
    this.sortByNames = ["Patient", "Timestamp"];
    this.dateTimeFields = ["alert_timestamp", "created_at"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = [
      "alert_kind",
      "alert_variable",
      "alert_type",
      "seenMark",
      "ownerUuid",
    ];
    this.filteringListAttrs = ["alert_tags"];
    this.editable = ["ownerUuid"];

    this.defaultFilter = { seenMark: ["No"] };

    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.none },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.none },
      historyMinLevel: null,
    };
  }

  init() {
    this.data = this.data.map((item) => {
      const ownerUuid = item.alert_owner ? item.alert_owner.uuid : "N/A";
      const seenMark = item.alert_seen ? "Yes" : "No";
      return {
        ...item,
        ["ownerUuid"]: ownerUuid,
        ["seenMark"]: seenMark,
      };
    });
    super.init();
  }
}
