import { reactive } from "@vue/reactivity";
import { JSX } from "solid-js";
import { ChoosingModal } from "../../common/uiKit/choosingModal/ChoosingModal";
import {
  ChoosingModalModel,
  SelectionMode,
} from "../../common/uiKit/choosingModal/ChoosingModalModel";
import { CellSelectRow } from "../../common/uiKit/tables/cells/CellSelectRow";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSelectRows } from "../../common/uiKit/tables/headers/HeaderSelectRows";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Users } from "../users/Users";

export function UsersModal(props: {
  model: Users;
  mode: SelectionMode;
  preselected?: number[];
  filter?: [string, any[]];
  title: JSX.Element;
  onConfirm: (params: { selected: any[] }) => void;
  onClose: () => void;
}) {
  const modal = reactive(
    new ChoosingModalModel(props.model, {
      mode: props.mode,
      filter: props.filter,
      preselected: props.preselected,
    }),
  );

  return (
    <ChoosingModal
      modal={modal}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      title={props.title}
      table={
        <DataTable
          columns={[
            {
              header1: "Choose",
              header2: <HeaderSelectRows model={modal} idAttr="uuid" />,
              cell: (item) => <CellSelectRow id={item.uuid} model={modal} />,
            },
            {
              header1: "Username",
              header2: <HeaderSort attr="username" model={modal.modalData} />,
              cell: (item) => <strong>{item.username}</strong>,
            },
            {
              header1: "Role",
              header2: (
                <HeaderSelectFilter
                  attr="role"
                  model={modal.modalData}
                  name="Role"
                />
              ),
              cell: (item) => item.role,
            },
            {
              header1: "# of managed patients",
              cell: (item) => item.managed_patients?.length ?? 0,
            },
            {
              header1: "# of monitored patients",
              cell: (item) => item.monitoring_patients?.length ?? 0,
            },
          ]}
          model={modal.modalData}
          isRowSelected={(item) => modal.isSelected(item.uuid)}
        />
      }
    />
  );
}
