import { DataModel } from "../../DataModel";

export type SelectionMode = "single" | "multi";

export interface ChoosingModalOptions {
  preselected?: number[];
  filter?: [string, any[]];
  mode: SelectionMode;
}

export class ChoosingModalModel<T extends DataModel = any> {
  selected: any[];

  constructor(
    public modalData: T,
    private options: ChoosingModalOptions,
  ) {
    this.selected = [...(options.preselected ?? [])];

    if (options.filter) {
      this.modalData.filter[options.filter[0]] = options.filter[1];
    }
  }

  get selectionMode() {
    return this.options.mode;
  }

  isSelected(option: any) {
    return this.selected.includes(option);
  }

  select(option: any) {
    if (this.selectionMode === "single") {
      this.selected = [option];
    } else if (!this.isSelected(option)) {
      this.selected.push(option);
    }
  }

  deselect(option: any) {
    if (this.selectionMode === "multi") {
      this.selected = this.selected.filter((opt) => opt !== option);
    }
  }

  selectAll(field: string) {
    const filtered = this.modalData.sortFilterData();
    this.selected = filtered.map((obj: any) => obj[field]);
  }

  deselectAll() {
    this.selected = [];
  }
}
