import { Property } from "csstype";
import { JSX } from "solid-js";

type Props = RowProps | ColumnProps | BlockProps;

interface RowProps extends FlexboxProps {
  row: true;
  column?: never;
}

interface ColumnProps extends FlexboxProps {
  row?: never;
  column: true;
}

interface BlockProps extends CommonProps {
  row?: never;
  column?: never;
}

interface FlexboxProps extends CommonProps {
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
}

interface CommonProps {
  children?: JSX.Element;
}

export function Container(props: Props) {
  return (
    <div
      style={{
        display: isFlexbox(props) ? "flex" : undefined,
        "flex-direction": props.column ? "column" : undefined,
        "align-items": isFlexbox(props) ? props.alignItems : undefined,
        "justify-content": isFlexbox(props) ? props.justifyContent : undefined,
      }}
    >
      {props.children}
    </div>
  );
}

function isFlexbox(props: Props): props is RowProps | ColumnProps {
  return props.row || props.column;
}
