import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { medicalOptions } from "../../common/constants";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellRadioField } from "../../common/uiKit/tables/cellFields/CellRadioField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddEcho } from "./AddEcho";
import { AddOtherExams } from "./AddOtherExams";

export function AddExamsTable(props: {
  patient: Patient;
  variant: "exams" | "echo";
}) {
  const model = reactive(
    props.variant === "exams"
      ? new AddOtherExams(props.patient.api, props.patient)
      : new AddEcho(props.patient.api, props.patient),
  );

  model.initEmpty();

  return (
    <AddTable
      model={model}
      canAddRows={false}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          cell: (item) => (
            <CellDateField
              attr="measurement_datetime"
              model={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          cell: (item) => (
            <label>
              {item.measurement_type.charAt(0).toUpperCase() +
                item.measurement_type.slice(1)}
            </label>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <>
              <Show when={!(item.measurement_type in medicalOptions)}>
                <CellTextField
                  attr="measurement_value"
                  model={item}
                  width={50}
                />
              </Show>
              <Show when={item.measurement_type in medicalOptions}>
                <CellRadioField<string | number>
                  attr="measurement_value"
                  model={item}
                  values={medicalOptions[item.measurement_type]}
                />
              </Show>
            </>
          ),
        },
        {
          header: "Unit",
          cell: (item) => <label>{item.measurement_unit}</label>,
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
    />
  );
}
