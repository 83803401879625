import { Show } from "solid-js";
import { Button } from "../../Button";
import { ChoosingModalModel } from "../../choosingModal/ChoosingModalModel";
import styles from "../../Table.module.css";

export function HeaderSelectRows(props: {
  model: ChoosingModalModel;
  idAttr: string;
}) {
  return (
    <Show when={props.model.selectionMode === "multi"}>
      <Button
        onClick={() => {
          props.model.selectAll(props.idAttr);
        }}
        class={styles.rowButton}
      >
        All
      </Button>
      <Button
        onClick={() => {
          props.model.deselectAll();
        }}
        class={styles.rowButton}
      >
        None
      </Button>
    </Show>
  );
}
