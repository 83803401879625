import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { floatRounding } from "../../common/utils/utils";
import { MedicalPlot } from "../common/MedicalPlot";
import { PatientSubpage } from "../common/PatientSubpage";
import { AddMedicalTable } from "../medical/AddMedicalTable";
import { Patient } from "../Patient";

export function LabsTable(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchLabs());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Labs" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add labs
      </Button>

      <Show when={adding()}>
        <AddMedicalTable patient={props.patient} variant="labs" />
      </Show>

      <MedicalPlot
        dataModel={model()}
        plotTypeOptions={[
          "NT-proBNP",
          "BNP",
          "urea",
          "creatinine",
          "hemoglobin",
          "hematocrit",
        ]}
      />

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: (
              <HeaderSort
                model={model()}
                attr="measurement_datetime"
                name="Measured"
              />
            ),
            cell: (item, index) => (
              <CellDate
                attr="measurement_datetime"
                index={index}
                model={model()}
                type="datetime-local"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="measurement_type" name="Type" />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellText
                attr="measurement_value"
                index={index}
                model={model()}
                displayValue={floatRounding(item.measurement_value, 1)}
                width={50}
                type="number"
              />
            ),
          },
          { header1: "Unit", cell: (item) => item.measurement_unit },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <CellEditButtons
                    model={model()}
                    index={index}
                    onConfirm={(editedField) => {
                      props.patient.updateItem(
                        "medicaldata",
                        item.uuid,
                        editedField,
                        model().dateTimeFields,
                      );
                    }}
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <CellDeleteButton
                    index={index}
                    model={model()}
                    onConfirm={() =>
                      props.patient.deleteItem("medicaldata", item.uuid)
                    }
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </PatientSubpage>
  );
}
