import { JSX } from "solid-js";
import { RadioField } from "./RadioField";

const truthy = "truthy";
const falsy = "falsy";

export function SwitchField(props: {
  attr: string;
  model: Record<string, any>;
  truthy: JSX.Element;
  falsy: JSX.Element;
}) {
  return (
    <RadioField
      values={{ [falsy]: "No", [truthy]: "Yes" }}
      value={props.model[props.attr] ? truthy : falsy}
      onChange={(newValue) => {
        props.model[props.attr] = newValue === truthy;
      }}
    />
  );
}
