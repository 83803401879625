import { reactive } from "@vue/reactivity";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddDiags } from "./AddDiags";

export function AddDiagsTable(props: {
  patient: Patient;
  diagOptions: Record<string, string[]>;
}) {
  const model = reactive(new AddDiags(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      model={model}
      setAllDates="date"
      columns={[
        {
          header: "Name",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="diagnosis_name"
              model={item}
              values={Object.keys(props.diagOptions)}
              listTitle="Select diagnosis"
            />
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="diagnosis_value"
              model={item}
              values={props.diagOptions[item.diagnosis_name]}
            />
          ),
        },
        {
          header: "Diagnosed at",
          cell: (item) => (
            <CellDateField attr="diagnosed_at" model={item} type="date" />
          ),
        },
        {
          header: "Remission",
          cell: (item) => (
            <CellDateField attr="remission" model={item} type="date" />
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
        {
          header: "Remove diagnosis",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
    />
  );
}
