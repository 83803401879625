import { reactive } from "@vue/reactivity";
import { DataModel } from "../../common/DataModel";
import { SymptomPlotModel } from "../../common/plotting";
import { Plot } from "../common/Plot";

export function SymptomPlot(props: {
  dataModel: DataModel;
  plotTypeOptions: string[];
}) {
  const model = reactive(
    new SymptomPlotModel(props.dataModel, props.plotTypeOptions, "plot-canvas"),
  );

  model.initPlot();

  return <Plot model={model} />;
}
