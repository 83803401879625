import { reactive } from "@vue/reactivity";
import { createMemo, createSignal, Show } from "solid-js";
import { apiDeletePhysiciansUuid } from "../../api";
import { seerlinqRealms } from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { ChooseField } from "../../common/uiKit/fields/ChooseField";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TagsField } from "../../common/uiKit/fields/TagsField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { FormRow } from "../../common/uiKit/form/FormRow";
import { CellChoose } from "../../common/uiKit/tables/cells/CellChoose";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellTags } from "../../common/uiKit/tables/cells/CellTags";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { maybeAsArray } from "../../common/utils/utils";
import { AdminConsole } from "../AdminConsole";
import { ClinicsModal } from "../common/ClinicsModal";
import { PatientsModal } from "../common/PatientsModal";
import { UsersModal } from "../common/UsersModal";
import { AddPhysician } from "./AddPhysician";

export function PhysiciansAdmin(props: { admin: AdminConsole }) {
  const model = createMemo(() => props.admin.physicians);
  const [dataAdd, setDataAdd] = createSignal<AddPhysician>();

  return (
    <div id="modal-content">
      <h2>Physicians</h2>
      <span>
        We have <strong>{props.admin.physicians.data.length}</strong>{" "}
        physicians(s) in the database.
      </span>

      <br />
      <br />

      <Button
        onClick={() => {
          if (dataAdd()) {
            setDataAdd(null);
          } else {
            const model = reactive(new AddPhysician(props.admin.api, null));
            model.initEmpty();
            setDataAdd(model);
          }
        }}
      >
        {dataAdd() ? "Cancel" : "Create new physician"}
      </Button>

      <br />
      <br />

      <Show when={!dataAdd()}>
        <DataTable
          columns={[
            {
              header1: "Surname",
              header2: <HeaderSort attr="surname" model={model()} />,
              cell: (item, index) => (
                <CellText
                  attr="surname"
                  index={index}
                  model={model()}
                  width={90}
                  displayValue={<strong>{item.surname}</strong>}
                />
              ),
            },
            {
              header1: "Name",
              cell: (item, index) => (
                <CellText
                  attr="given_name"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Titles",
              cell: (item, index) => (
                <CellText
                  attr="titles"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realm"
                  model={model()}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item, index) => (
                <CellEnum
                  attr="realm"
                  index={index}
                  model={model()}
                  values={seerlinqRealms}
                />
              ),
            },
            {
              header1: "Email",
              cell: (item, index) => (
                <CellText
                  attr="email"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Phone",
              cell: (item, index) => (
                <CellTags attr="phone" index={index} model={model()} />
              ),
            },
            {
              header1: "User",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={
                    model().editedField?.["connected_user_uuid"]
                      ? props.admin.users.userMapping[
                          model().editedField["connected_user_uuid"]
                        ]
                      : ""
                  }
                  valueView={item.username}
                  modal={({ onClose }) => (
                    <UsersModal
                      title={`Change connected user for physician: ${item.surname} ${item.given_name}`}
                      preselected={maybeAsArray(
                        model().editedField["connected_user_uuid"],
                      )}
                      filter={["role", ["study-physician", "physician"]]}
                      mode="single"
                      model={props.admin.createUsers()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["connected_user_uuid"] =
                          selected[0];
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "# of patients",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={`${model().editedField?.["patients"].length} chosen`}
                  valueView={item.numPatients}
                  modal={({ onClose }) => (
                    <PatientsModal
                      model={props.admin.createPatients()}
                      preselected={model().editedField["patients"]}
                      filter={["realm", [model().editedField["realm"]]]}
                      mode="multi"
                      title={`Change patients for physician: ${item.surname} ${item.given_name}`}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["patients"] = selected;
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "# of clinics",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={`${model().editedField?.["clinics"].length} chosen`}
                  valueView={item.numClinics}
                  modal={({ onClose }) => (
                    <ClinicsModal
                      preselected={model().editedField["clinics"]}
                      filter={["realm", [model().editedField["realm"]]]}
                      mode="multi"
                      model={props.admin.createClinics()}
                      title={`Change clinics for physician: ${item.surname} ${item.given_name}`}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["clinics"] = selected;
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updatePhysician(item.uuid, editedField);
                  }}
                />
              ),
            },
            {
              header1: "Delete",
              cell: (item, index) => (
                <CellDeleteButton
                  index={index}
                  model={model()}
                  onConfirm={() =>
                    apiDeletePhysiciansUuid({ path: { uuid: item.uuid } })
                  }
                />
              ),
            },
          ]}
          model={model()}
        />
      </Show>

      <Show when={dataAdd()}>
        <div>
          <h3>Add new physician</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Surname"
                data={<TextField attr="surname" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Given name(s)"
                data={
                  <TextField attr="given_name" model={dataAdd().addingList} />
                }
                mandatory
              />

              <FormRow
                label="Titles"
                data={<TextField attr="titles" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Monitoring realm"
                data={
                  <SelectField
                    attr="realm"
                    model={dataAdd().addingList}
                    values={seerlinqRealms}
                    listTitle="Select realm"
                  />
                }
                mandatory
              />

              <FormRow
                label="Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Phone(s)"
                data={<TagsField attr="phone" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Connected user"
                data={
                  <ChooseField
                    value={
                      dataAdd().addingList["connected_user_uuid"]
                        ? props.admin.users.userMapping[
                            dataAdd().addingList["connected_user_uuid"]
                          ]
                        : "None"
                    }
                    modal={({ onClose }) => (
                      <UsersModal
                        title="Choose connected user for a new physician"
                        preselected={maybeAsArray(
                          dataAdd().addingList["connected_user_uuid"],
                        )}
                        filter={["role", ["study-physician", "physician"]]}
                        mode="single"
                        model={props.admin.createUsers()}
                        onClose={onClose}
                        onConfirm={({ selected }) => {
                          dataAdd().addingList["connected_user_uuid"] =
                            selected[0];
                        }}
                      />
                    )}
                  />
                }
              />

              <FormRow
                label="Physician clinics"
                data={
                  <ChooseField
                    value={`${dataAdd().addingList["clinics_uuids"]?.length ?? 0} chosen`}
                    modal={({ onClose }) => (
                      <ClinicsModal
                        preselected={dataAdd().addingList["clinics_uuids"]}
                        filter={["realm", [dataAdd().addingList.realm]]}
                        mode="multi"
                        model={props.admin.createClinics()}
                        title="Choose clinics for a new physician"
                        onClose={onClose}
                        onConfirm={({ selected }) => {
                          dataAdd().addingList["clinics_uuids"] = selected;
                        }}
                      />
                    )}
                  />
                }
              />

              <FormRow
                label="Physician patients"
                data={
                  <ChooseField
                    value={`${dataAdd().addingList["patient_ids"]?.length} chosen`}
                    modal={({ onClose }) => (
                      <PatientsModal
                        title="Choose patients for a new physician"
                        model={props.admin.createPatients()}
                        preselected={dataAdd().addingList["patient_ids"]}
                        filter={["realm", [dataAdd().addingList.realm]]}
                        mode="multi"
                        onClose={onClose}
                        onConfirm={({ selected }) => {
                          dataAdd().addingList["patient_ids"] = selected;
                        }}
                      />
                    )}
                  />
                }
              />
            </table>

            <br />
            <br />

            <Button
              onClick={() => {
                dataAdd().post(dataAdd().addingList.phone);
              }}
            >
              Add physician
            </Button>
          </form>
        </div>
      </Show>
    </div>
  );
}
