import { reactive } from "@vue/reactivity";
import { createMemo, createSignal, Show } from "solid-js";
import { apiDeleteClinicsUuid } from "../../api";
import { seerlinqRealms } from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { ChooseField } from "../../common/uiKit/fields/ChooseField";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { FormRow } from "../../common/uiKit/form/FormRow";
import { CellChoose } from "../../common/uiKit/tables/cells/CellChoose";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { AdminConsole } from "../AdminConsole";
import { PhysiciansModal } from "../common/PhysiciansModal";
import { AddClinic } from "./AddClinic";

export function ClinicsAdmin(props: { admin: AdminConsole }) {
  const model = createMemo(() => props.admin.clinics);
  const [dataAdd, setDataAdd] = createSignal<AddClinic>();

  return (
    <div id="modal-content">
      <h2>Clinics</h2>
      <span>
        We have <strong>{props.admin.clinics.data.length}</strong> clinic(s) in
        the database.
      </span>

      <br />
      <br />

      <Button
        onClick={() => {
          if (dataAdd()) {
            setDataAdd(null);
          } else {
            const model = reactive(new AddClinic(props.admin.api, null));
            model.initEmpty();
            setDataAdd(model);
          }
        }}
      >
        {dataAdd() ? "Cancel" : "Create new clinic"}
      </Button>

      <br />
      <br />

      <Show when={!dataAdd()}>
        <DataTable
          columns={[
            {
              header1: "Name",
              header2: <HeaderSort attr="name" model={model()} />,
              cell: (item, index) => (
                <CellText
                  attr="name"
                  index={index}
                  model={model()}
                  width={90}
                  displayValue={<strong>{item.name}</strong>}
                />
              ),
            },
            {
              header1: "Ambulance",
              cell: (item, index) => (
                <CellText
                  attr="ambulance"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Address",
              cell: (item, index) => (
                <CellText
                  attr="address"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Country",
              header2: (
                <HeaderSelectFilter
                  attr="country"
                  model={model()}
                  name="Country"
                />
              ),
              cell: (item, index) => (
                <CellText
                  attr="country"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realm"
                  model={model()}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item, index) => (
                <CellEnum
                  attr="realm"
                  values={seerlinqRealms}
                  index={index}
                  model={model()}
                />
              ),
            },
            {
              header1: "Phone",
              cell: (item, index) => (
                <CellText
                  attr="phone"
                  index={index}
                  model={model()}
                  width={60}
                />
              ),
            },
            {
              header1: "Email",
              cell: (item, index) => (
                <CellText
                  attr="email"
                  index={index}
                  model={model()}
                  width={80}
                />
              ),
            },
            {
              header1: "# of physicians",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={`${model().editedField?.["physicians"]?.length ?? 0} chosen`}
                  valueView={item.numPhysicians}
                  modal={({ onClose }) => (
                    <PhysiciansModal
                      title={`Change physicians for clinic: ${item.name}`}
                      preselected={model().editedField["physicians"]}
                      filter={["realm", [model().editedField.realm]]}
                      mode="multi"
                      model={props.admin.createPhysicians()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["physicians"] = selected;
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updateClinic(item.uuid, editedField);
                  }}
                />
              ),
            },
            {
              header1: "Delete",
              cell: (item, index) => (
                <CellDeleteButton
                  index={index}
                  model={model()}
                  onConfirm={() =>
                    apiDeleteClinicsUuid({ path: { uuid: item.uuid } })
                  }
                />
              ),
            },
          ]}
          model={model()}
        />
      </Show>

      <Show when={dataAdd()}>
        <div>
          <h3>Add new clinic</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Name"
                data={<TextField attr="name" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Ambulance"
                data={
                  <TextField attr="ambulance" model={dataAdd().addingList} />
                }
                mandatory
              />

              <FormRow
                label="Address"
                data={<TextField attr="address" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Country"
                data={<TextField attr="country" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Monitoring realm"
                data={
                  <SelectField
                    attr="realm"
                    model={dataAdd().addingList}
                    values={seerlinqRealms}
                    listTitle="Select realm"
                  />
                }
                mandatory
              />

              <FormRow
                label="(General) Phone"
                data={<TextField attr="phone" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="(General) Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Clinic physicians"
                data={
                  <ChooseField
                    value={`${dataAdd().addingList["physicians_uuids"]?.length ?? 0} chosen`}
                    modal={({ onClose }) => (
                      <PhysiciansModal
                        title="Choose physicians for a new clinic"
                        preselected={dataAdd().addingList["physicians_uuids"]}
                        filter={["realm", [dataAdd().addingList.realm]]}
                        mode="multi"
                        model={props.admin.createPhysicians()}
                        onClose={onClose}
                        onConfirm={({ selected }) => {
                          dataAdd().addingList["physicians_uuids"] = selected;
                        }}
                      />
                    )}
                  />
                }
              />
            </table>

            <br />
            <br />

            <Button
              onClick={() => {
                dataAdd().post();
              }}
            >
              Add clinic
            </Button>
          </form>
        </div>
      </Show>
    </div>
  );
}
