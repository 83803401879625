import { reactive } from "@vue/reactivity";
import { debounce } from "lodash";
import { JSX } from "solid-js";
import {
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../../common/enumToText";
import { ChoosingModal } from "../../common/uiKit/choosingModal/ChoosingModal";
import {
  ChoosingModalModel,
  SelectionMode,
} from "../../common/uiKit/choosingModal/ChoosingModalModel";
import { CellSelectRow } from "../../common/uiKit/tables/cells/CellSelectRow";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSelectRows } from "../../common/uiKit/tables/headers/HeaderSelectRows";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { isPatientDto } from "../../common/utils/dataUtils";
import { Patients } from "../../patients/Patients";

export function PatientsModal(props: {
  model: Patients;
  mode: SelectionMode;
  preselected?: number[];
  filter?: [string, any[]];
  title: JSX.Element;
  onConfirm: (params: { selected: any[] }) => void;
  onClose: () => void;
}) {
  const modal = reactive(
    new ChoosingModalModel(props.model, {
      mode: props.mode,
      filter: props.filter,
      preselected: props.preselected,
    }),
  );

  const idFilterChangedDebounced = debounce((value: string) => {
    modal.modalData.queryFilter = value;
    modal.modalData.refetch();
  }, 100);

  return (
    <ChoosingModal
      modal={modal}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      title={props.title}
      table={
        <DataTable
          columns={[
            {
              header1: "Choose",
              header2: <HeaderSelectRows model={modal} idAttr="patient_id" />,
              cell: (item) => (
                <CellSelectRow id={item.patient_id} model={modal} />
              ),
            },
            {
              header1: "Patient ID",
              header2: <HeaderSort attr="patient_id" model={modal.modalData} />,
              header3: (
                <input
                  type="text"
                  placeholder="ID filter..."
                  value={modal.modalData.queryFilter}
                  onInput={(event) => {
                    idFilterChangedDebounced(event.target.value);
                  }}
                  style="width: 75px"
                />
              ),
              cell: (item) => item.patient_id,
            },
            {
              header1: "Added",
              header2: <HeaderSort attr="created_at" model={modal.modalData} />,
              cell: (item) => item.created_at,
            },
            {
              header1: "Added by",
              header2: (
                <HeaderSelectFilter
                  attr="added_by"
                  model={modal.modalData}
                  name="who added"
                />
              ),
              cell: (item) =>
                isPatientDto(item) ? item.user.username : undefined,
            },
            {
              header1: "Status",
              header2: (
                <HeaderSelectFilter
                  attr="patient_status"
                  model={modal.modalData}
                  name="Status"
                  values={patientStatuses}
                />
              ),
              cell: (item) => patientStatuses[item.patient_status],
            },
            {
              header1: "Study",
              header2: (
                <HeaderSelectFilter
                  attr="patient_study"
                  model={modal.modalData}
                  name="Studies"
                  values={seerlinqStudies}
                />
              ),
              cell: (item) =>
                item.patient_study.map((st) => seerlinqStudies[st]).join("; "),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realm"
                  model={modal.modalData}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item) => seerlinqRealms[item.realm],
            },
            {
              header1: "Description",
              cell: (item) => item.description,
            },
          ]}
          model={modal.modalData}
          isRowSelected={(item) => modal.isSelected(item.patient_id)}
        />
      }
    />
  );
}
