import autosize from "autosize";
import {
  createEffect,
  createMemo,
  createReaction,
  createSignal,
  Show,
} from "solid-js";
import styles from "./TextField.module.css";
import { useField } from "./utils";

export function TextField(props: {
  model?: Record<string, any>;
  type?: "text" | "password" | "number";
  value?: string;
  onChange?: (newValue: string) => void;
  attr?: string;
  width?: number;
  multiline?: boolean | "autosize";
  required?: boolean;
  disabled?: boolean;
}) {
  const [element, setElement] = createSignal<
    HTMLInputElement | HTMLTextAreaElement
  >();
  const { showErrors } = useField(element);

  const value = createMemo(
    () =>
      (props.value !== undefined ? props.value : props.model?.[props.attr]) ??
      "",
  );

  const onChange = (newValue: string) => {
    if (props.model && props.attr) {
      props.model[props.attr] = newValue;
    }
    props.onChange?.(newValue);
  };

  createEffect(() => {
    if (props.multiline === "autosize") {
      autosize(element());
    }
  });

  const track = createReaction(() => {
    if (props.multiline === "autosize" && element()) {
      autosize.update(element());
    }
  });

  track(value);

  return (
    <>
      <Show when={!props.multiline}>
        <input
          type={props.type ?? "text"}
          value={value()}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          style={{ width: props.width ? `${props.width}px` : undefined }}
          required={props.required}
          ref={setElement}
          classList={{ [styles.root]: true, [styles.showErrors]: showErrors() }}
          disabled={props.disabled}
        />
      </Show>

      <Show when={props.multiline}>
        <textarea
          value={value()}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          style={{ width: props.width ? `${props.width}px` : undefined }}
          required={props.required}
          ref={setElement}
          classList={{
            [styles.root]: true,
            [styles.textarea]: true,
            [styles.showErrors]: showErrors(),
            autosize: props.multiline === "autosize",
          }}
          rows={props.multiline === "autosize" ? 1 : undefined}
          disabled={props.disabled}
        />
      </Show>
    </>
  );
}
