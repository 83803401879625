import { reactive } from "@vue/reactivity";
import {
  apiGetAudit,
  apiGetHfBasicMedDataPatientId,
  apiGetHfComorbidities,
  apiGetHfComorbiditiesPatientId,
  apiGetHfExamsPatientId,
  apiGetHfLabDataPatientId,
  apiGetHfSymptomsPatientId,
  apiGetHfVitalsPatientId,
  apiGetLabelstudioTaskProjectIdPpgUuid,
  apiGetMedicalReports,
  apiGetPatientsPatientId,
  apiGetPatientsPatientIdAlerts,
  apiGetPatientsPatientIdComputed,
  apiGetPatientsPatientIdDerived,
  apiGetPatientsPatientIdHealthPro,
  apiGetPatientsPatientIdMedications,
  apiGetPatientsPatientIdPpgs,
  apiGetSchedules,
  apiGetThresholdsDefault,
  apiPostPatientsPatientIdUser,
  apiPutPatientsPatientId,
  AuditTables,
  client,
  PatientState,
} from "../api";
import { ApiConnector } from "../api/ApiConnector";
import { labelStudioProjectId } from "../common/config";
import {
  SeerlinqDRIHeartCore,
  SeerlinqHCReport,
} from "../common/quickLookAnalyses";
import { FormModel } from "../common/uiKit/form/FormModel";
import { consentOk, isPaperConsent } from "../common/utils/dataUtils";
import { dateTimeISOString, dateTimeOrNull } from "../common/utils/utils";
import { Alerts } from "./alerts/Alerts";
import { DerivedData } from "./common/DerivedData";
import { MedData } from "./common/MedData";
import { MergedMedData } from "./common/MergedMedData";
import { Diagnoses } from "./diagnoses/Diagnoses";
import { Computed } from "./dri/Computed";
import { Events } from "./events/Events";
import { Logs } from "./logs/Logs";
import { Medications } from "./medications/Medications";
import { PPGs } from "./ppg/PPGs";
import { MedicalReports } from "./reports/Reports";
import { Schedules } from "./Schedules";
import { Symptoms } from "./symptoms/Symptoms";
import { Thresholds } from "./thresholds/Thresholds";

export class Patient {
  loaded = false;
  data: any;
  schedules: Schedules;
  heartCore: any;

  minDRIHeartCore = 4;

  detailsForm = new FormModel();

  driHeartCore: SeerlinqDRIHeartCore;
  heartCoreReport: SeerlinqHCReport;

  private alerts: Alerts;
  private vitals: MergedMedData;
  private symptoms: Symptoms;
  private medications: Medications;
  private diags: Diagnoses;
  private labs: MedData;
  private exams: MedData;
  private basicMed: MedData;
  private ppgs: PPGs;
  private reports: MedicalReports;

  private driData: Computed;
  private thresholds: Thresholds;

  private diagOptions: Record<string, string[]>;

  constructor(
    public patientId: number,
    public api: ApiConnector,
  ) {}

  async init() {
    if (this.api.userLevel >= 3) {
      this.driHeartCore = new SeerlinqDRIHeartCore(this.api);
      this.heartCoreReport = new SeerlinqHCReport(this.api);
    }

    await this.fetch();
  }

  async fetch() {
    await this.api.checkLoggedIn();

    this.data = (
      await apiGetPatientsPatientId({
        path: { patient_id: this.patientId },
      })
    ).data;

    await Promise.all([
      this.fetchDri(),
      this.fetchSchedules(),
      this.fetchHeartCore(),
    ]);

    this.loaded = true;
  }

  loadLogs(type: AuditTables) {
    const model = reactive(new Logs([], this.api.userLevel, this.api.userUUID));

    if (type === AuditTables.users) {
      const users = this.data.connected_users as any[];

      void Promise.all(
        users.map((u) =>
          apiGetAudit({
            query: { audit_table: AuditTables.users, audit_item_uuid: u.uuid },
          }),
        ),
      ).then((results) => {
        model.data = results.flatMap((res) => res.data.audit_items);
        model.init();
      });
    } else {
      void apiGetAudit({
        query: { audit_table: type, audit_patient_id: this.patientId },
      }).then((res) => {
        model.data = res.data.audit_items;
        model.init();
      });
    }

    return model;
  }

  consentOk() {
    return consentOk(this.data);
  }

  isPaperConsent() {
    return isPaperConsent(this.data);
  }

  sanitizeBody(body: object, datetimeIso: string[] = []) {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] === "") {
          body[key] = null;
        }
      }
      if (datetimeIso.includes(key) && body.hasOwnProperty(key)) {
        var datetime = body[key];
        body[key] = dateTimeISOString(datetime);
      }
    }
    // remove null
    body = Object.fromEntries(
      Object.entries(body).filter(([key, value]) => value !== null),
    );
    return body;
  }

  async updatePatientField(body: object) {
    const cleanBody = this.sanitizeBody(body);
    await apiPutPatientsPatientId({
      path: { patient_id: this.patientId },
      body: cleanBody,
    });
    await this.fetch();
  }

  async updateItem(
    endpoint: string,
    uuid: string,
    body: object,
    datetimeIso: string[] = [],
  ) {
    const cleanBody: any = this.sanitizeBody(body, datetimeIso);
    await client.put({
      url: `/api/v1/${endpoint}/${uuid}`,
      body: cleanBody,
    });
    this.loaded = false;
    await this.fetch();
    this.loaded = true;
  }

  async deleteItem(endpoint: string, uuid: string) {
    await client.delete({
      url: `/api/v1/${endpoint}/${uuid}`,
    });
    this.loaded = false;
    await this.fetch();
    this.loaded = true;
  }

  async createConnectedUser(body: any) {
    await apiPostPatientsPatientIdUser({
      path: { patient_id: this.patientId },
      body,
    });
    this.loaded = false;
    await this.fetch();
    this.loaded = true;
  }

  async togglePatientState() {
    if (this.data.patient_state === PatientState.normal) {
      var newState = PatientState.high_risk;
    } else {
      var newState = PatientState.normal;
    }
    await apiPutPatientsPatientId({
      path: { patient_id: this.patientId },
      body: { patient_state: newState },
    });
    window.location.reload();
  }

  async generateHealthProReports(maxReports: number, fromDate: string) {
    const reports = await apiGetPatientsPatientIdHealthPro({
      path: { patient_id: this.patientId },
      query: {
        max_reports: maxReports,
        from_date: fromDate === null ? null : fromDate,
      },
    });
    var reportsData = reports.data;
    reportsData.from_date = dateTimeOrNull(reportsData.from_date, false);
    reportsData.from_date =
      reportsData.from_date === null ? "--" : reportsData.from_date;
    return reportsData;
  }

  numDRIDataPoints() {
    return this.driData.data.length;
  }

  async fetchAlerts() {
    if (!this.alerts) {
      const alerts = await apiGetPatientsPatientIdAlerts({
        path: { patient_id: this.patientId },
      });
      this.alerts = new Alerts(
        alerts.data.alerts,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.alerts.init();
    }

    return this.alerts;
  }

  async fetchDri() {
    if (!this.driData) {
      const allComputed = await apiGetPatientsPatientIdComputed({
        path: { patient_id: this.patientId },
        query: { all_versions: true },
      });
      this.driData = new Computed(
        allComputed.data.computed_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.driData.filterDRI();
      this.driData.init();
    }

    return this.driData;
  }

  async fetchVitals() {
    if (!this.vitals) {
      const vitals = await apiGetHfVitalsPatientId({
        path: { patient_id: this.patientId },
      });
      this.vitals = new MergedMedData(
        vitals.data.medical_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      // merge with SpO2 and HR from derived and HR from computed
      const derived = await apiGetPatientsPatientIdDerived({
        path: { patient_id: this.patientId },
      });
      const SpO2 = new DerivedData(
        derived.data.derived_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      SpO2.filterSpO2();
      SpO2.init();
      const HRDerived = new DerivedData(
        derived.data.derived_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      HRDerived.filterHR();
      HRDerived.init();

      const computed = await apiGetPatientsPatientIdComputed({
        path: { patient_id: this.patientId },
        query: { all_versions: false },
      });
      const HRComputed = new Computed(
        computed.data.computed_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      HRComputed.filterHR();
      HRComputed.init();

      const varMapping = {
        "SpO2: mean": "SpO2",
        "HR: mean": "heart rate",
        "heart rate": "heart rate",
      };
      this.vitals.mergeWithPPGDerivedAndComputed(
        [SpO2, HRDerived],
        [HRComputed],
        "measurement_type",
        varMapping,
      );
      this.vitals.init();
    }

    return this.vitals;
  }

  async fetchSymptoms() {
    if (!this.symptoms) {
      const symptoms = await apiGetHfSymptomsPatientId({
        path: { patient_id: this.patientId },
      });
      this.symptoms = new Symptoms(
        symptoms.data.symptoms,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.symptoms.init();
    }

    return this.symptoms;
  }

  getEvents() {
    const events = reactive(
      new Events(this.data.events, this.api.userLevel, this.api.userUUID),
    );
    events.init();
    return events;
  }

  async fetchThresholds() {
    if (!this.thresholds) {
      const defaultThresholds = await apiGetThresholdsDefault();
      this.thresholds = new Thresholds(
        this.data.thresholds,
        defaultThresholds.data.thresholds,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.thresholds.init();
    }

    return this.thresholds;
  }

  async fetchMedications() {
    if (!this.medications) {
      const medications = await apiGetPatientsPatientIdMedications({
        path: { patient_id: this.patientId },
      });
      this.medications = new Medications(
        medications.data.medications,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.medications.init();
    }

    return this.medications;
  }

  async fetchComorbidities() {
    if (!this.diags || !this.diagOptions) {
      const diags = await apiGetHfComorbiditiesPatientId({
        path: { patient_id: this.patientId },
      });
      this.diags = new Diagnoses(
        diags.data.diagnoses,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.diags.init();

      const response = await apiGetHfComorbidities();
      this.diagOptions = response.data.comorbidities;
    }

    return { data: this.diags, diagOptions: this.diagOptions };
  }

  async fetchLabs() {
    if (!this.labs) {
      const labs = await apiGetHfLabDataPatientId({
        path: { patient_id: this.patientId },
      });
      this.labs = new MedData(
        labs.data.medical_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.labs.init();
    }

    return this.labs;
  }

  async fetchExams() {
    if (!this.exams) {
      const exams = await apiGetHfExamsPatientId({
        path: { patient_id: this.patientId },
      });
      this.exams = new MedData(
        exams.data.medical_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.exams.init();
    }

    return this.exams;
  }

  async fetchBasicMedData() {
    if (!this.basicMed) {
      const basicMed = await apiGetHfBasicMedDataPatientId({
        path: { patient_id: this.patientId },
      });
      this.basicMed = new MedData(
        basicMed.data.medical_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.basicMed.init();
    }

    return this.basicMed;
  }

  async fetchPpgData() {
    if (!this.ppgs) {
      const ppgs = await apiGetPatientsPatientIdPpgs({
        path: { patient_id: this.patientId },
      });
      this.ppgs = new PPGs(
        ppgs.data.ppg_data,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.ppgs.init();
    }

    return this.ppgs;
  }

  private async fetchSchedules() {
    const schedules = await apiGetSchedules({
      query: { patient_ids: [this.patientId], schedule_type: "ppg" },
    });
    this.schedules = new Schedules(
      schedules.data.schedules,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.schedules.init();
  }

  private async fetchHeartCore() {
    if (this.api.userLevel < 3) {
      return;
    }

    this.heartCore = this.data.heart_core;
    this.heartCore.last_ppg = dateTimeOrNull(this.heartCore.last_ppg, true);
    this.heartCore.reportStart = dateTimeOrNull(
      this.heartCore.report_start_date,
      false,
    );
    this.heartCore.dris = this.heartCore.last_5_dris.map(
      ([datetime, floatVal]) => [
        dateTimeOrNull(datetime),
        parseFloat(floatVal.toFixed(1)),
      ],
    );
  }

  async fetchMedicalReports() {
    if (!this.reports) {
      const res = await apiGetMedicalReports({
        query: { patient_ids: [this.patientId] },
      });
      this.reports = new MedicalReports(
        res.data.medical_reports,
        this.api.userLevel,
        this.api.userUUID,
      );
      this.reports.init();
    }

    return this.reports;
  }

  async redirectToLabelStudio(ppgUuid: string) {
    const response = await apiGetLabelstudioTaskProjectIdPpgUuid({
      path: { project_id: labelStudioProjectId, ppg_uuid: ppgUuid },
    });
    window.open(response.data.url, "_blank");
  }
}
