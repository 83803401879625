import { reactive } from "@vue/reactivity";
import { DataModel } from "../../common/DataModel";
import { ComputedPlotModel } from "../../common/plotting";
import { Plot } from "../common/Plot";

export function ComputedPlot(props: {
  dataModel: DataModel;
  plotTypeOptions: string[];
}) {
  const model = reactive(
    new ComputedPlotModel(
      props.dataModel,
      props.plotTypeOptions,
      "plot-canvas",
    ),
  );

  model.initPlot();

  return <Plot model={model} />;
}
