import { Accessor, createSignal, onCleanup, onMount } from "solid-js";

export function useField(element: Accessor<HTMLFieldElement>) {
  const [showErrors, setShowErrors] = createSignal(false);

  const onSubmit = () => {
    setShowErrors(true);
  };

  onMount(() => {
    element()?.form?.addEventListener("submit", onSubmit);
  });

  onCleanup(() => {
    element()?.form?.removeEventListener("submit", onSubmit);
  });

  return { showErrors };
}

type HTMLFieldElement =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement;

/** It's more convenient to work with a Map */
export function valuesToMap<T extends string | number>(
  values: T[] | Record<string, string>,
) {
  const res = new Map<T, string>();

  if (Array.isArray(values)) {
    for (const item of values) {
      res.set(item, String(item));
    }
  } else {
    const allKeysAreNumbers = Object.keys(values).every(
      (v) => !isNaN(v as any),
    );

    for (const key of Object.keys(values)) {
      res.set((allKeysAreNumbers ? parseInt(key) : key) as T, values[key]);
    }
  }

  return res;
}
