import { Show } from "solid-js";
import { DataModel } from "../../DataModel";
import { Button } from "../Button";

export function Legend(props: { model: DataModel }) {
  return (
    <div class="legend">
      <Show
        when={
          props.model.filteringAttrs.length +
            props.model.filteringListAttrs.length >
          0
        }
      >
        <p>
          <strong>Filtering: </strong>
          <span>
            Holding "shift" key will select <em>only</em> given item.
          </span>
          <br />
          <span>Doing this again will reset filter and select all items.</span>
        </p>
      </Show>

      <Show when={props.model.defaultFilter != null}>
        <p>
          <strong>Default filter: </strong>
          <br />
          {Object.keys(props.model.defaultFilter).map((key) => (
            <>
              <strong>{props.model.fieldMapper[key] ?? key}:</strong>{" "}
              {props.model.defaultFilter[key].join(", ")}
              <br />
            </>
          ))}
        </p>
      </Show>

      <Show when={props.model.sortBy.length > 1}>
        <p>
          <strong>Sort order:</strong> {props.model.getSortNames()}{" "}
          <Show when={props.model.canSwitchSortOrder}>
            <Button
              onClick={() => {
                props.model.switchSortOrder([1, 0]);
              }}
            >
              Switch
            </Button>
          </Show>
        </p>
        <p>&#9660;: descending; &#9650;: ascending; &#9711;: do not sort</p>
        <p>
          <Button
            onClick={() => {
              props.model.resetSort();
            }}
          >
            Reset sort
          </Button>
        </p>
      </Show>
    </div>
  );
}
