import {
  FullPhysicianResponse,
  PatientBaseSchema,
  PhysicianClinicResponse,
} from "../../api";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Physicians extends DataModel {
  constructor(
    data: FullPhysicianResponse[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.sortBy = ["surname"];
    this.sortByNames = ["Name"];
    this.initSort = [true];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm"];
    this.editable = [
      "surname",
      "given_name",
      "titles",
      "realm",
      "email",
      "clinics",
      "patients",
      "connected_user",
      "phone",
    ];

    this.rowsPerPage = 10;

    // access
    this.access = {
      editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.visible },
      historyMinLevel: null,
    };
  }

  init() {
    this.data = this.data.map((item: FullPhysicianResponse) => {
      const numClinics = item.clinics.length;
      const clinics = item.clinics
        .map((obj) => `${obj["name"]} (${obj["ambulance"]})`)
        .join(" \\ ");
      const numPatients = item.patients.length;
      const username = item.connected_user?.username ?? null;
      return {
        ...item,
        ["numClinics"]: numClinics,
        ["clinicsStr"]: clinics,
        ["numPatients"]: numPatients,
        ["username"]: username,
      };
    });
    super.init();
  }

  getEditedField(index: number) {
    const field = super.getEditedField(index);
    field["patients"] = field["patients"].map(
      (p: PatientBaseSchema) => p.patient_id,
    );
    field["clinics"] = field["clinics"].map(
      (c: PhysicianClinicResponse) => c.uuid,
    );
    field["connected_user_uuid"] = field["connected_user"]?.uuid;
    return field;
  }
}
