import { DataModel } from "../../../DataModel";
import { Button } from "../../Button";
import styles from "../../Table.module.css";

export function CellDeleteButton(props: {
  model: DataModel;
  index: number;
  onConfirm: () => void;
}) {
  return (
    <Button
      disabled={!props.model.canDeleteItem(props.index)}
      onClick={() => props.onConfirm()}
      data-tooltip={
        props.model.canDeleteItem(props.index)
          ? undefined
          : "You cannot pass. Go back to the Shadow!"
      }
      class={styles.rowButton}
    >
      Delete
    </Button>
  );
}
