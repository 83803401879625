import { createMemo } from "solid-js";
import { App } from "../App";
import {
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../common/enumToText";
import { Button } from "../common/uiKit/Button";
import { DataTable } from "../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../common/uiKit/tables/headers/HeaderSort";
import { WaitingRoomConsole } from "./WaitingRoom";

export function HighRiskWaitingRoom(props: {
  waitingRoom: WaitingRoomConsole;
  app: App;
}) {
  const model = createMemo(() => props.waitingRoom.highRisk);

  return (
    <div id="modal-content">
      <h3>High-risk patients</h3>

      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Patient ID",
            header2: <HeaderSort attr="patient_id" model={model()} />,
            cell: (item) => {
              return (
                <strong>
                  <a class="patient-link" href={`/patient/${item.patient_id}`}>
                    {item.patient_id}
                  </a>
                </strong>
              );
            },
          },
          {
            header1: "Added",
            header2: <HeaderSort model={model()} attr="created_at" />,
            cell: (item) => item.created_at,
          },
          {
            header1: "Date of birth",
            cell: (item) => item.date_of_birth,
          },
          {
            header1: "Realm",
            header2: (
              <HeaderSelectFilter
                name="Realm"
                attr="realm"
                model={model()}
                values={seerlinqRealms}
              />
            ),
            cell: (item) => seerlinqRealms[item.realm],
          },
          {
            header1: "Seerlinq studies",
            header2: (
              <HeaderSelectFilter
                name="Studies"
                attr="patient_study"
                model={model()}
                values={seerlinqStudies}
              />
            ),
            cell: (item) => seerlinqStudies[item.patient_study],
          },
          {
            header1: "Status",
            header2: (
              <HeaderSelectFilter
                name="Status"
                attr="patient_status"
                model={model()}
                values={patientStatuses}
              />
            ),
            cell: (item) => patientStatuses[item.patient_status],
          },
          {
            header1: "Mark as not high-risk",
            cell: (item) => (
              <Button
                onClick={async () => {
                  await props.waitingRoom.patientNotHighRisk(item.patient_id);
                }}
              >
                Not high-risk
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}
