import { createSignal, For, Show } from "solid-js";
import { PatientHealthProReportsResponse } from "../../api";
import { Button } from "../../common/uiKit/Button";
import { Patient } from "../Patient";
import { PatientSubpage } from "../common/PatientSubpage";

export function HealthPro(props: { patient: Patient }) {
  const [maxReports, setMaxReports] = createSignal(10);
  const [fromDate, setFromDate] = createSignal(null);
  const [reports, setReports] =
    createSignal<PatientHealthProReportsResponse>(null);

  return (
    <PatientSubpage title="HealthPro reports">
      <div>
        <label>
          Maximum number of reports:
          <input
            style="width: 40px"
            type="number"
            value={maxReports()}
            onChange={(event) => {
              setMaxReports(parseInt(event.target.value));
            }}
          />
        </label>{" "}
        <label>
          From date:
          <input
            type="date"
            value={fromDate()}
            onChange={(event) => {
              setFromDate(event.target.value);
            }}
          />
        </label>{" "}
        <Button
          onClick={async () => {
            const reports = await props.patient.generateHealthProReports(
              maxReports(),
              fromDate(),
            );
            setReports(reports);
          }}
        >
          Generate reports
        </Button>
      </div>
      <Show when={reports() != null}>
        <div>
          <h4>Reports:</h4>
          <div>
            Found <strong>{reports().num_reports}</strong> reports.
            <br />
            (Asked for {reports().max_reports} reports from{" "}
            {reports().from_date})
          </div>
          <div>
            <table class="reports-table">
              <tbody>
                <For each={reports().reports}>
                  {(report, reportIndex) => (
                    <tr>
                      <td>
                        <strong>{reportIndex() + 1}.</strong>
                        report:
                      </td>
                      <td innerHTML={report}></td>
                    </tr>
                  )}
                </For>
              </tbody>
            </table>
          </div>
        </div>
      </Show>
    </PatientSubpage>
  );
}
