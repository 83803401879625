import { JSX, Show } from "solid-js";
import { App } from "../App";
import { Spinner } from "../common/uiKit/Spinner";
import { Tab } from "../common/uiKit/tabs/Tab";
import { Tabs } from "../common/uiKit/tabs/Tabs";

export function WaitingRoomPage(props: { app: App; children?: JSX.Element }) {
  if (!props.app.waitingRoom) {
    props.app.initWaitingRoom();
  }

  return (
    <Show
      when={props.app.waitingRoom?.initialized}
      fallback={
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Spinner />
        </div>
      }
    >
      <h2>Waiting room</h2>

      <div class="modal-container">
        <Tabs>
          <Tab href="medical">Medical alerts</Tab>

          <Tab href="non-medical">Non-medical alerts</Tab>

          <Show when={props.app.seerlinqApi.userLevel >= 3}>
            <Tab href="high-risk">High-risk patients</Tab>
          </Show>
        </Tabs>

        <div class="modal-content active">{props.children}</div>
      </div>
    </Show>
  );
}
