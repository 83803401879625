import Chart, { ChartOptions } from "chart.js/auto";
import "chartjs-adapter-moment";
import moment from "moment";
import { parseDatetimeToLocal } from "./utils/utils";

type PlotKey = Record<string, string[]>;

export class SimplePlotModel {
  data: any;
  valueKey = "";
  filterKey = "";
  dateTimeKey = "";
  chartId: string;
  hueKey: PlotKey = null;
  styleKey: PlotKey = null;
  plotType: string = null;
  plotStart: string = null;
  plotEnd: string = null;
  plotTimeSeries: any[] = null;
  chart: any = null;
  showLegend = false;

  defaultColor = "rgba(187, 22, 163, 1)";
  defaultStyle = "circle";
  extraFilter = {};

  minDataPoints = 2;
  plotTypeOptions: string[] = [];
  defaultPlotTypeOptions: string[];

  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    this.data = dataModel;
    this.chartId = chartId;
    this.defaultPlotTypeOptions = plotTypeOptions;
  }

  initPlot() {
    this.plotTypeOptions = [];
    for (let plotOption of this.defaultPlotTypeOptions) {
      const filter = {};
      filter[this.filterKey] = [plotOption];
      for (const key in this.extraFilter) {
        filter[key] = this.extraFilter[key];
      }
      const filteredData = this.data.sortAndFilterCustom(
        [this.dateTimeKey],
        [false],
        filter,
      );
      if (filteredData.length >= this.minDataPoints) {
        this.plotTypeOptions.push(plotOption);
      }
    }
  }

  resetPlot() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = null;
  }

  preparePlot() {
    this.resetPlot();
    const filter = {};
    filter[this.filterKey] = [this.plotType];
    for (const key in this.extraFilter) {
      filter[key] = this.extraFilter[key];
    }

    const filteredData = this.data.sortAndFilterCustom(
      [this.dateTimeKey],
      [false],
      filter,
    );
    this.plotStart = parseDatetimeToLocal(
      new Date(
        Math.min(
          ...filteredData.map((item) => new Date(item[this.dateTimeKey])),
        ),
      ),
    );
    this.plotEnd = parseDatetimeToLocal(
      new Date(
        Math.max(
          ...filteredData.map((item) => new Date(item[this.dateTimeKey])),
        ),
      ),
    );
    this.plotTimeSeries = filteredData;
    this.updatePlot(this.plotStart, this.plotEnd);
  }

  yLabel(plotType: string) {
    return plotType;
  }

  getChartScales(): ChartOptions<"line">["scales"] {
    return {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            day: "MMM D",
          },
        },
        adapters: {
          date: {
            library: moment,
          },
        },
        title: {
          display: true,
          text: "date",
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: this.yLabel(this.plotType),
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
    };
  }

  getDatesTimeSeries(minDate: string, maxDate: string) {
    const filtPlotEnd = new Date(maxDate);
    filtPlotEnd.setSeconds(filtPlotEnd.getSeconds() + 1);
    return this.plotTimeSeries.filter(
      (item) =>
        new Date(item[this.dateTimeKey]) >= new Date(minDate) &&
        new Date(item[this.dateTimeKey]) <= filtPlotEnd,
    );
  }

  chartData(
    plotData: object[],
    color: string,
    style: string,
    label: string = null,
  ) {
    return {
      label: label,
      data: plotData.map((item) => ({
        x: item[this.dateTimeKey],
        y: item[this.valueKey],
      })),
      borderColor: color,
      backgroundColor: color,
      pointStyle: style,
      borderWidth: 0.5,
      fill: false,
      pointRadius: 5,
      pointHoverRadius: 7,
    };
  }

  getDatasets(timeSeries: object[]) {
    return [this.chartData(timeSeries, this.defaultColor, this.defaultStyle)];
  }

  updatePlot(minDate: string, maxDate: string) {
    this.resetPlot();

    const timeSeries = this.getDatesTimeSeries(minDate, maxDate);
    const canvas = document.getElementById(this.chartId) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const datasets = this.getDatasets(timeSeries);
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          legend: {
            display: this.showLegend,
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: this.getChartScales(),
      },
    });
  }
}

class SingleGroupedPlotModel extends SimplePlotModel {
  // palettes
  colorPalette = [
    "rgba(187, 22, 163, 1)",
    "rgba(5, 195, 222, 1)",
    "rgba(37, 40, 42, 1)",
    "rgba(0, 146, 203, 1)",
    "rgba(112, 115, 114, 1)",
  ];
  stylePalette = ["circle", "triangle", "cross", "rect"];

  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
    this.showLegend = true;
  }

  singleGroup(timeSeries: object[], key) {
    return timeSeries.reduce((acc, item) => {
      if (!acc[item[key]]) {
        acc[item[key]] = [];
      }
      acc[item[key]].push(item);
      return acc;
    }, {});
  }

  getDatasets(timeSeries: object[]) {
    if (this.hueKey && this.hueKey[this.plotType]) {
      const groupedData = this.singleGroup(
        timeSeries,
        this.hueKey[this.plotType],
      );
      return Object.keys(groupedData).map((key, index) => {
        const groupData = groupedData[key];
        const color = this.colorPalette[index % this.colorPalette.length];
        return this.chartData(groupData, color, this.defaultStyle, key);
      });
    } else if (this.styleKey && this.styleKey[this.plotType]) {
      const groupedData = this.singleGroup(
        timeSeries,
        this.styleKey[this.plotType],
      );
      return Object.keys(groupedData).map((key, index) => {
        const groupData = groupedData[key];
        const style = this.stylePalette[index % this.stylePalette.length];
        return this.chartData(groupData, this.defaultColor, style, key);
      });
    }
  }
}

class DoubleGroupedPlotModel extends SingleGroupedPlotModel {
  colorMapping: Record<string, string> = {};
  styleMapping: Record<string, string> = {};

  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
  }

  doubleGroup(timeSeries: object[], key1, key2) {
    return timeSeries.reduce((acc, item) => {
      const key = `${item[key1]}_${item[key2]}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }

  getDatasets(timeSeries: object[]) {
    const groupedData = this.doubleGroup(
      timeSeries,
      this.hueKey[this.plotType],
      this.styleKey[this.plotType],
    );
    return Object.keys(groupedData).map((key, index) => {
      const groupData = groupedData[key];
      const [hue, style] = key.split("_");
      return this.chartData(
        groupData,
        this.colorMapping[hue],
        this.styleMapping[style],
        `${hue} - ${style}`,
      );
    });
  }
}

export class ComputedPlotModel extends DoubleGroupedPlotModel {
  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";
    this.hueKey = {
      "respiratory rate": ["ppg_conditions"],
      "heart rate": ["ppg_conditions"],
      "diastolic reserve index": ["ppg_conditions"],
    };
    this.styleKey = {
      "respiratory rate": ["tags"],
      "heart rate": ["tags"],
      "diastolic reserve index": ["tags"],
    };

    this.colorMapping = {
      "Condition 1": "rgba(187, 22, 163, 1)",
      "Condition 2": "rgba(5, 195, 222, 1)",
      "Condition 3": "rgba(37, 40, 42, 1)",
      "Condition 1,Condition 2": "rgba(187, 22, 163, 1)",
    };
    this.styleMapping = {
      ir: "circle",
      "ir,beats": "circle",
      "ir,beats,XB123EF1FM1": "circle",
      "ir,EMD": "triangle",
    };
  }
}

export class DerivedPlotModel extends SimplePlotModel {
  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";

    this.extraFilter = { rolling: ["full"] };
  }
}

export class MedicalPlotModel extends SimplePlotModel {
  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "measurement_value";
    this.filterKey = "measurement_type";
    this.dateTimeKey = "measurement_datetime";
  }
}

export class SymptomPlotModel extends SimplePlotModel {
  constructor(dataModel: any, plotTypeOptions: string[], chartId: string) {
    super(dataModel, plotTypeOptions, chartId);
    this.valueKey = "symptom_value";
    this.filterKey = "symptom_name";
    this.dateTimeKey = "symptom_date";
  }
}
