import { Show } from "solid-js";
import { SelectField } from "../../fields/SelectField";

export function CellSelectField<T extends string | number>(props: {
  model: Record<string, any>;
  attr: string;
  /** Either an enum or just an array of possible values. */
  values: Record<T, string> | T[];
  listTitle?: string;
  onChange?: (newValue: T) => void;
  disabled?: boolean;
  required?: boolean;
}) {
  return (
    <Show when={!props.disabled} fallback={props.model[props.attr]}>
      <SelectField
        attr={props.attr}
        model={props.model}
        values={props.values}
        listTitle={props.listTitle}
        onChange={props.onChange}
        required={props.required}
      />
    </Show>
  );
}
