import { apiPostMonitoringTeams } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";

export class AddMonitoringTeam extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      display_name: null,
      realms: [],
      members: [],
      members_alert_kinds: [],
      admin_uuid: null,
    };
  }

  async post() {
    const response = await apiPostMonitoringTeams({ body: this.addingList });
    if (response.data != null) {
      window.navigate("/admin/teams");
      location.reload();
    }
  }
}
