import { createSignal, Show } from "solid-js";
import { AuditTables } from "../../api";
import { Button } from "../../common/uiKit/Button";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { CellJSON } from "../../common/uiKit/tables/cells/CellJSON";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Tags } from "../../common/uiKit/Tags";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { Logs } from "./Logs";

export function LogsTable(props: { patient: Patient }) {
  const [dataType, setDataType] = createSignal<AuditTables>(null);
  const [dataModel, setDataModel] = createSignal<Logs>();

  return (
    <PatientSubpage title="Logs">
      <div>
        <label>
          Data type:{" "}
          <SelectField<string>
            value={dataType()}
            onChange={(newValue) => {
              setDataType(newValue as AuditTables);
            }}
            values={auditTablesMapping}
          />
        </label>{" "}
        <Button
          onClick={() => {
            const model = props.patient.loadLogs(dataType());
            setDataModel(model);
          }}
        >
          Load
        </Button>
      </div>

      <Show when={dataModel() != null}>
        <br />
        <br />

        <DataTable
          model={dataModel()}
          columns={[
            {
              header1: "Table",
              cell: (item) => item.audit_table,
            },
            {
              header1: "Timestamp",
              header2: (
                <HeaderSort model={dataModel()} attr="audit_item_timestamp" />
              ),
              cell: (item) => item.audit_item_timestamp,
            },
            {
              header1: "Type",
              header2: (
                <HeaderSelectFilter
                  model={dataModel()}
                  attr="audit_item_type"
                  name="Type"
                />
              ),
              cell: (item) => item.audit_item_type,
            },
            {
              header1: "Action",
              cell: (item) => <CellJSON value={item.audit_item_action} />,
            },
            {
              header1: "Tags",
              header2: (
                <HeaderSelectFilter
                  model={dataModel()}
                  attr="tags"
                  name="Tags"
                />
              ),
              cell: (item) => <Tags tags={item.tags} />,
            },
          ]}
          verticalAlign="top"
        />
      </Show>
    </PatientSubpage>
  );
}

const auditTablesMapping = {
  [AuditTables.users]: "Users",
  [AuditTables.patients]: "Patients",
  [AuditTables.diagnoses]: "Diagnoses",
  [AuditTables.medicaldata]: "Medical data",
  [AuditTables.medications]: "Medications",
  [AuditTables.symptoms]: "Symptoms",
  [AuditTables.ppg]: "PPG",
  [AuditTables.ppg_derived]: "PPG derived",
  [AuditTables.sq_computed]: "SQ computed",
  [AuditTables.patient_schedule]: "Patient schedule",
  [AuditTables.simple_messaging]: "Simple messaging",
  [AuditTables.patient_alerts]: "Patient alerts",
  [AuditTables.patient_thresholds]: "Patient thresholds",
  [AuditTables.patient_events]: "Patient events",
  [AuditTables.medical_reports]: "Medical reports",
};
