import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { floatRounding } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddThresholdsTable } from "./AddThresholdTable";

export function ThresholdsTable(props: {
  patient: Patient;
  api: ApiConnector;
}) {
  const [model] = createResource(() => props.patient.fetchThresholds());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Thresholds" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add thresholds
      </Button>

      <Show when={adding()}>
        <AddThresholdsTable patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Variable table",
            header2: (
              <HeaderSelectFilter
                attr="threshold_variable_table"
                model={model()}
                name="Table"
                values={model().threshTables}
              />
            ),
            cell: (item) => model().threshTables[item.threshold_variable_table],
          },
          { header1: "Variable", cell: (item) => item.threshold_variable },
          {
            header1: "Trigger",
            header2: (
              <HeaderSelectFilter
                attr="threshold_trigger"
                model={model()}
                name="Trigger"
                values={model().threshTriggers}
              />
            ),
            cell: (item) => model().threshTriggers[item.threshold_trigger],
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="threshold_type"
                model={model()}
                name="Type"
              />
            ),
            cell: (item) => item.threshold_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellText
                attr="threshold_value"
                index={index}
                model={model()}
                displayValue={floatRounding(item.threshold_value, 0)}
                width={50}
              />
            ),
          },
          { header1: "How", cell: (item) => model().settingsString(item) },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="threshold_comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <Show when={!item.isDefault}>
                  <CellEditButtons
                    model={model()}
                    index={index}
                    onConfirm={(editedField) => {
                      props.patient.updateItem(
                        "thresholds",
                        item.uuid,
                        editedField,
                      );
                    }}
                  />
                </Show>
                <Show when={item.isDefault}>&nbsp;(cannot edit)</Show>
              </>
            ),
          },
        ]}
      />
    </PatientSubpage>
  );
}
