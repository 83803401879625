import { createMemo, JSX, Show } from "solid-js";
import { Button } from "../Button";
import { FormModel } from "./FormModel";

export function FormRowEditable(props: {
  form: FormModel;
  label: string;
  attr: string;
  model: Record<string, any>;
  viewMode: JSX.Element;
  editMode: JSX.Element;
  editable?: boolean;
  onChange?: (editedField: Record<string, any>) => void;
}) {
  const editing = createMemo(() => props.form.editedId === props.attr);

  return (
    <tr>
      <td class="left-column">{props.label}:</td>

      <td class="right-column column-max-width">
        <Show when={!editing()}>{props.viewMode}</Show>
        <Show when={editing()}>{props.editMode}</Show>
      </td>

      <td class="edit-column">
        <Show when={!editing() && props.editable !== false}>
          <Button
            onClick={() => {
              props.form.startEditing(props.attr, {
                [props.attr]: props.model[props.attr],
              });
            }}
          >
            Edit
          </Button>
        </Show>

        <Show when={editing()}>
          <Button
            onClick={() => {
              props.onChange?.(props.form.editedField);
              props.form.stopEditing();
            }}
          >
            Update
          </Button>{" "}
          <Button
            onClick={() => {
              props.form.stopEditing();
            }}
          >
            X
          </Button>
        </Show>
      </td>
    </tr>
  );
}
