import { createMemo, createSignal, For } from "solid-js";
import styles from "./SelectField.module.css";
import { useField, valuesToMap } from "./utils";

interface Props<T extends string | number> {
  model?: Record<string, any>;
  attr?: string;
  value?: T;
  values: Record<T, string> | T[];
  listTitle?: string;
  onChange?: (newValue: T) => void;
  required?: boolean;
}

export function SelectField<T extends string | number>(props: Props<T>) {
  const [element, setElement] = createSignal<HTMLInputElement>();
  const { showErrors } = useField(element);

  const value = createMemo(() =>
    props.value !== undefined ? props.value : props.model?.[props.attr],
  );

  const valuesMap = createMemo(() => valuesToMap(props.values));
  const isNumber = createMemo(
    () => typeof valuesMap().keys().next().value === "number",
  );

  return (
    <select
      value={value() ?? ""}
      onChange={(event) => {
        const value = (
          isNumber() ? parseInt(event.target.value) : event.target.value
        ) as T;

        if (props.model) {
          props.model[props.attr] = value;
        }

        props.onChange?.(value);
      }}
      required={props.required}
      ref={setElement}
      classList={{ [styles.root]: true, [styles.showErrors]: showErrors() }}
    >
      {props.listTitle && (
        <option value="" disabled>
          {props.listTitle}
        </option>
      )}

      <For each={[...valuesMap().entries()]}>
        {([value, displayValue]) => (
          <option value={value}>{displayValue}</option>
        )}
      </For>
    </select>
  );
}
