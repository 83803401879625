import { reactive } from "@vue/reactivity";
import {
  ppgConditions,
  ppgMeasModes,
  ppgQuality,
} from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { DateField } from "../../common/uiKit/fields/DateField";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { Patient } from "../Patient";
import { AddPPG } from "./AddPPG";

export function AddPPGForm(props: { patient: Patient }) {
  const model = reactive(new AddPPG(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <div>
      <hr />
      <div>
        <h3>{model.desc}</h3>(
        <span style="color: #bb16a3">
          <strong>*</strong>
        </span>{" "}
        = mandatory)
        <form>
          <table class="invisible-table invisible-table-data-add">
            <tbody>
              <tr>
                <td class="left-column">Patient HF study ID:</td>
                <td class="right-column">{props.patient.patientId}</td>
              </tr>
              <tr>
                <td class="left-column">
                  Measured at{" "}
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <DateField
                    attr="measurement_datetime"
                    model={model.addingList}
                    type="datetime-local"
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement condition
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <SelectField
                    attr="measurement_condition"
                    model={model.addingList}
                    values={ppgConditions}
                    listTitle="Select condition"
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement device
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <TextField
                    attr="measurement_device"
                    model={model.addingList}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement origin
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <TextField
                    attr="measurement_origin"
                    model={model.addingList}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Measurement mode:</td>
                <td class="right-column">
                  <SelectField
                    attr="measurement_mode"
                    model={model.addingList}
                    values={ppgMeasModes}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Quality flag:</td>
                <td class="right-column">
                  <SelectField
                    attr="quality_flag"
                    model={model.addingList}
                    values={ppgQuality}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Comment:</td>
                <td class="right-column">
                  <TextField attr="comment" model={model.addingList} />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  PPG file (.csv)
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <input
                    type="file"
                    onChange={(event) => {
                      model.uploadFile(event);
                    }}
                    accept="text/csv"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <Button
            onClick={() => {
              model.post();
            }}
          >
            Post
          </Button>
        </form>
      </div>
      <hr />
    </div>
  );
}
