import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Clinics extends DataModel {
  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.sortBy = ["name"];
    this.sortByNames = ["Name"];
    this.initSort = [false];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm", "country"];
    this.filteringIntAttrs = ["numPhysicians"];
    this.editable = [
      "name",
      "ambulance",
      "address",
      "country",
      "realm",
      "phone",
      "email",
      "physicians",
    ];

    this.rowsPerPage = 10;

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.visible },
      historyMinLevel: null,
    };
  }

  init() {
    this.data = this.data.map((item) => {
      const numPhysicians = item.physicians.length;
      return { ...item, ["numPhysicians"]: numPhysicians };
    });
    super.init();
  }

  getEditedField(index: number) {
    const field = super.getEditedField(index);
    field["physicians"] = field["physicians"].map((p) => p.uuid);
    return field;
  }
}
