import { debounce } from "lodash";
import { DataModel } from "../../../DataModel";

export function HeaderTextFilter<T extends object>(props: {
  model: DataModel<T>;
  placeholder?: string;
}) {
  const reloadDebounced = debounce(() => {
    props.model.reload();
  }, 100);

  return (
    <input
      type="text"
      placeholder={props.placeholder}
      value={props.model.queryFilter}
      onInput={(event) => {
        props.model.queryFilter = event.target.value;
        reloadDebounced();
      }}
      style="width: 75px"
    />
  );
}
