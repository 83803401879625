import { eventTypes } from "../../common/constants";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Events extends DataModel {
  // constants
  eventTypes = eventTypes;

  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.sortBy = ["event_date", "event_timestamp"];
    this.sortByNames = ["Date", "Timestamp"];
    this.dateTimeFields = ["event_timestamp"];
    this.dateFields = ["event_date"];
    this.stringFields = ["event_description", "event_comment"];
    this.editable = ["event_description", "event_comment"];
    this.initSort = [true, null];
    this.filteringAttrs = ["event_type"];
    this.canSwitchSortOrder = true;

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: null,
    };
  }
}
