import {
  createEffect,
  createSignal,
  createUniqueId,
  For,
  Show,
} from "solid-js";
import { DataModel } from "../../../DataModel";

export function HeaderSelectFilter<
  T extends object,
  V extends string | number,
>(props: {
  name: string;
  model: DataModel<T>;
  attr: string;
  values?: Record<V, string>;
}) {
  const [open, setOpen] = createSignal(false);
  let dropdown: HTMLDivElement | undefined;

  const onDocumentClick = (event: MouseEvent) => {
    // Check whether we clicked outside
    if (dropdown && !dropdown.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  };

  createEffect(() => {
    if (open()) {
      document.addEventListener("click", onDocumentClick);
    } else {
      document.removeEventListener("click", onDocumentClick);
    }
  });

  return (
    <div class="dropdown">
      <button
        class="dropdown-toggle"
        onClick={(event) => {
          if (!open()) {
            setOpen(true);
            event.stopImmediatePropagation();
          }
        }}
      >
        Filter by {props.name}
      </button>

      <Show when={open()}>
        <div ref={dropdown} class="dropdown-menu">
          <For each={props.model.uniques[props.attr]}>
            {(value) => {
              const id = createUniqueId();
              const list = () => {
                const list = props.model.filter[props.attr];
                return Array.isArray(list) ? list : undefined;
              };
              const checked = () => list().includes(value);

              return (
                <label for={id} style={{ "user-select": "none" }}>
                  <input
                    id={id}
                    type="checkbox"
                    checked={checked()}
                    disabled={props.model.uniques[props.attr].length < 2}
                    onClick={(event) => {
                      if (event.shiftKey) {
                        if (list().length === 1 && list()[0] === value) {
                          list().splice(
                            0,
                            list().length,
                            ...props.model.uniques[props.attr],
                          );
                        } else {
                          list().splice(0, list().length, value);
                        }
                      } else {
                        if (checked()) {
                          const index = list().indexOf(value);
                          if (index !== -1) {
                            list().splice(index, 1);
                          }
                        } else {
                          if (!list().includes(value)) {
                            list().push(value);
                          }
                        }
                      }

                      props.model.reload();
                    }}
                  />
                  <span>
                    {String((props.values ? props.values[value] : value) ?? "")}
                  </span>
                </label>
              );
            }}
          </For>
        </div>
      </Show>
    </div>
  );
}
