import { JSX } from "solid-js";

export function Form(props: { children?: JSX.Element; onSubmit?: () => void }) {
  return (
    <form
      novalidate // Prevents native validation bubbles from popping up
      onSubmit={(event) => {
        event.preventDefault();

        if (event.currentTarget.checkValidity()) {
          props.onSubmit?.();
        }
      }}
    >
      {props.children}
    </form>
  );
}
