import clsx from "clsx";
import { Button } from "../../common/uiKit/Button";
import { AdminConsole } from "../AdminConsole";

export function TemporaryPasswordDialog(props: { admin: AdminConsole }) {
  return (
    <div
      class={clsx("quicklook-share", props.admin.tempPasswordPopup && "active")}
    >
      <p>Temporary password for patient:</p>
      <p class="password-style">{props.admin.tempPasswordText}</p>
      <p>
        (Password is valid for <strong>2 days</strong>, in the meantime, patient
        has to change it.)
      </p>
      <p>
        <strong>
          Save this password somewhere, as it cannot be shown again.
        </strong>
      </p>
      <Button
        onClick={async () => {
          await props.admin.closeTemporaryPasswordPopup();
        }}
      >
        Close
      </Button>
    </div>
  );
}
