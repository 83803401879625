import { Toast, toaster } from "@kobalte/core";
import { Portal } from "solid-js/web";
import { Spacer } from "./Spacer";
import styles from "./Toasts.module.css";

export function Toasts() {
  return (
    <Portal>
      <Toast.Region>
        <Toast.List class={styles.list} />
      </Toast.Region>
    </Portal>
  );
}

export function showToast(params: { message: string }) {
  toaster.show((props) => (
    <Toast.Toast toastId={props.toastId} class={styles.toast}>
      <Toast.Title>{params.message}</Toast.Title>

      <Spacer grow />

      <Toast.CloseButton class={styles.closeBtn}>X</Toast.CloseButton>
    </Toast.Toast>
  ));
}
