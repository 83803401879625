import { createMemo, For } from "solid-js";
import { valuesToMap } from "./utils";

interface Props<T extends string | number> {
  model?: Record<string, any>;
  attr?: string;
  value?: T[];
  values: Record<T, string> | T[];
  onChange?: (newValues: T[]) => void;
  disabled?: boolean;
}

export function SelectMultipleField<T extends string | number>(
  props: Props<T>,
) {
  const value = createMemo(() =>
    props.value !== undefined ? props.value : props.model?.[props.attr],
  );

  const valuesMap = createMemo(() => valuesToMap(props.values));
  const isNumber = createMemo(
    () => typeof valuesMap().keys().next().value === "number",
  );

  return (
    <select
      onChange={(event) => {
        const rawValues = Array.from(event.target.selectedOptions).map(
          (opt) => opt.value,
        );
        const value = (
          isNumber() ? rawValues.map((val) => parseInt(val)) : rawValues
        ) as T[];

        if (props.model) {
          props.model[props.attr] = value;
        }

        props.onChange?.(value);
      }}
      multiple
      disabled={props.disabled}
    >
      <For each={[...valuesMap().entries()]}>
        {([optValue, displayValue]) => (
          <option value={optValue} selected={value()?.includes(optValue)}>
            {displayValue}
          </option>
        )}
      </For>
    </select>
  );
}
