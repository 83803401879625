export function Spacer(props: {
  width?: number;
  height?: number;
  grow?: boolean;
}) {
  return (
    <div
      style={{
        width: maybePx(props.width),
        height: maybePx(props.height),
        "flex-grow": props.grow ? "1" : undefined,
      }}
    />
  );
}

function maybePx(num: number): string {
  return num != null ? `${num}px` : (num as null | undefined);
}
