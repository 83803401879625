import { reactive } from "@vue/reactivity";
import { AddPatient } from "./addPatient/AddPatient";
import { AdminConsole } from "./admin/AdminConsole";
import { ApiConnector } from "./api/ApiConnector";
import { clvVersion } from "./common/config";
import { Patient } from "./patient/Patient";
import { Patients } from "./patients/Patients";
import { WaitingRoomConsole } from "./waitingRoom/WaitingRoom";

export class App {
  clvVersion = clvVersion ?? "-";
  darkMode = localStorage.getItem("darkMode") === "true";

  // basics
  loading = true;
  patients: Patients;

  // patient loading and tabs
  patient: Patient = null;

  // admin
  adminShit: AdminConsole = null;
  waitingRoom: WaitingRoomConsole = null;

  constructor(public seerlinqApi: ApiConnector) {}

  // basic loaders
  async initIndex() {
    this.setTheme();
    await this.seerlinqApi.checkLoggedIn();
    this.loading = false;
  }

  async getPatients() {
    await this.initIndex();

    // init with HeartCore
    this.patients = new Patients(this.seerlinqApi, true);

    await this.patients.init();
  }

  initPatient(patientId: number) {
    if (!this.patient || this.patient.patientId !== patientId) {
      this.patient = new Patient(patientId, this.seerlinqApi);
    }

    return this.patient;
  }

  async initAdmin() {
    await this.seerlinqApi.checkLoggedIn();
    if (this.seerlinqApi.userLevel >= 3) {
      this.adminShit = new AdminConsole(this.seerlinqApi);
      await this.adminShit.init();
    }
  }

  async initWaitingRoom() {
    await this.seerlinqApi.checkLoggedIn();
    if (this.seerlinqApi.canViewWaitingRoom) {
      this.waitingRoom = new WaitingRoomConsole(this.seerlinqApi);
      await this.waitingRoom.init();
    }
  }

  async initAddPatient() {
    await this.seerlinqApi.checkLoggedIn();
    const model = reactive(new AddPatient(this.seerlinqApi, null));
    await model.initEmpty();
    return model;
  }

  // dark mode
  setTheme() {
    var darkTheme = document.getElementById("dark-mode-css") as HTMLLinkElement;
    if (this.darkMode) {
      darkTheme.disabled = false;
    } else {
      darkTheme.disabled = true;
    }
  }

  async toggleDarkMode() {
    this.darkMode = !this.darkMode;
    localStorage.setItem("darkMode", String(this.darkMode));
    this.setTheme();
  }
}
