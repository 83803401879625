import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { medicalOptions } from "../../common/constants";
import { Button } from "../../common/uiKit/Button";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { floatRounding, getObjectFromArray } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddExamsTable } from "./AddExamsTable";
import { AddRHCTable } from "./AddRHCTable";

export function ExamsTable(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchExams());
  const [addingExams, setAddingExams] = createSignal(false);
  const [addingEcho, setAddingEcho] = createSignal(false);
  const [addingRHC, setAddingRHC] = createSignal(false);

  return (
    <PatientSubpage title="Exams" loading={model.loading}>
      <Button
        onClick={() => {
          setAddingExams(!addingExams());
          setAddingEcho(false);
          setAddingRHC(false);
        }}
      >
        Add other (ECG / final outcome) exams data
      </Button>
      <Button
        onClick={() => {
          setAddingExams(false);
          setAddingEcho(!addingEcho());
          setAddingRHC(false);
        }}
      >
        Add ECHO exams data
      </Button>
      <Button
        onClick={() => {
          setAddingExams(false);
          setAddingEcho(false);
          setAddingRHC(!addingRHC());
        }}
      >
        Add RHC exams data
      </Button>

      <Show when={addingExams()}>
        <AddExamsTable patient={props.patient} variant="exams" />
      </Show>

      <Show when={addingEcho()}>
        <AddExamsTable patient={props.patient} variant="echo" />
      </Show>

      <Show when={addingRHC()}>
        <AddRHCTable patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: (
              <HeaderSort
                model={model()}
                attr="measurement_datetime"
                name="Measured"
              />
            ),
            cell: (item, index) => (
              <CellDate
                attr="measurement_datetime"
                index={index}
                model={model()}
                type="datetime-local"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="measurement_type" name="Type" />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <>
                <Show when={!(item.measurement_type in medicalOptions)}>
                  <CellText
                    attr="measurement_value"
                    index={index}
                    model={model()}
                    displayValue={floatRounding(item.measurement_value, 1)}
                    width={50}
                  />
                </Show>
                <Show when={item.measurement_type in medicalOptions}>
                  <CellEnum
                    attr="measurement_value"
                    index={index}
                    model={model()}
                    values={getObjectFromArray(
                      medicalOptions[item.measurement_type],
                    )}
                  />
                </Show>
              </>
            ),
          },
          { header1: "Unit", cell: (item) => item.measurement_unit },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <CellEditButtons
                    model={model()}
                    index={index}
                    onConfirm={(editedField) => {
                      props.patient.updateItem(
                        "medicaldata",
                        item.uuid,
                        editedField,
                        model().dateTimeFields,
                      );
                    }}
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <CellDeleteButton
                    index={index}
                    model={model()}
                    onConfirm={() =>
                      props.patient.deleteItem("medicaldata", item.uuid)
                    }
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </PatientSubpage>
  );
}
