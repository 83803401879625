import { apiPostUsers } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";

export class AddUser extends DataAdd {
  // constants
  allRoles = [
    "patient-ppg-app",
    "physician-ppg-app",
    "study-ppg-app",
    "patient",
    "physician",
    "study-physician",
    "seerlinq-user",
  ];

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      username: null,
      email: null,
      password: null,
      role: null,
      preferred_language: "sk",
      connected_patient_id: null,
      managed_patient_ids: [],
    };
  }

  async post() {
    const response = await apiPostUsers({ body: this.addingList });

    if (response.data != null) {
      window.navigate("/admin/users");
      location.reload();
    }
  }
}
