import { RadioField } from "../../fields/RadioField";

export function CellRadioField<T extends string | number>(props: {
  attr: string;
  model: Record<string, any>;
  values: T[] | Record<string, string>;
}) {
  return (
    <RadioField values={props.values} attr={props.attr} model={props.model} />
  );
}
