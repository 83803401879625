import { DataAdd } from "../../../DataAdd";
import { Button } from "../../Button";
import styles from "../../Table.module.css";

export function CellRemoveButton(props: { model: DataAdd; index: number }) {
  return (
    <div style={{ display: "flex", "justify-content": "center" }}>
      <Button
        style={{ "font-size": "1.2em" }}
        onClick={() => {
          props.model.removeItem(props.index);
        }}
        class={styles.rowButton}
      >
        &times;
      </Button>
    </div>
  );
}
