import { DataModel } from "../../DataModel";
import { Button } from "../Button";

export function PaginationControl<T extends object>(props: {
  model: DataModel<T>;
}) {
  return (
    <div class="pagination-control">
      <Button
        onClick={() => props.model.prevPage()}
        disabled={props.model.currentPage === 1}
      >
        {"\u25C0"}
      </Button>

      <span>
        Page {props.model.currentPage} of {props.model.totalPages}
      </span>

      <Button
        onClick={() => props.model.nextPage()}
        disabled={props.model.currentPage === props.model.totalPages}
      >
        {"\u25B6"}
      </Button>
    </div>
  );
}
