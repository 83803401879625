import { AccessRules, TableAccess } from "./roles";
import { dateTimeOrNull, parseDatetimeToLocal } from "./utils/utils";

export class DataModel<T extends object = any> {
  paginatedData: any[] = [];
  rawPaginatedData: T[] = [];
  hasHistory: boolean = false;
  showingHistory: boolean = false;

  // sorting
  sortBy: string[] = [];
  initSort: boolean[] = [];
  sortByNames: string[] = [];
  canSwitchSortOrder: boolean = false;
  sortDesc: boolean[];

  // filtering
  filteringAttrs: string[] = [];
  filteringListAttrs: string[] = [];
  filteringIntAttrs: string[] = [];
  defaultFilter: Record<string, any[]> = null;
  fieldMapper: Record<string, string> = {};
  uniques: Record<string, any[]> = {};
  filter: Record<string, any[] | boolean> = {};

  queryFilter: string = "";
  queryKey: string = null;

  // field types
  stringFields: string[] = [];
  dateFields: string[] = [];
  dateTimeFields: string[] = [];

  // editing
  editable: string[] = [];
  editingId: number;
  editedField: any;
  currentlyEditable: string[];

  // pagination
  rowsPerPage: number = 25;
  rowsPerPageOptions: number[] = [10, 25, 50, 100];
  currentPage: number = 1;
  totalPages: number = null;

  // access
  canShowHistory: boolean = false;
  access: TableAccess = {
    editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
    deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
    historyMinLevel: 3,
  };

  constructor(
    public data: T[],
    public userLevel: number,
    public userUUID: string,
  ) {}

  init() {
    this.canShowHistory = this.access.historyMinLevel <= this.userLevel;
    if (this.hasHistory && this.canShowHistory) {
      this.initHistory();
    }
    this.sortDesc = this.initSort.map((item) => item);

    // init filtering if necessary
    this.uniques = this.filteringAttrs.reduce((obj, attr) => {
      const props = this.getUniqueProperties(this.data, attr);
      obj[attr] = props;
      return obj;
    }, {});
    for (const attr of this.filteringListAttrs) {
      const uniqueItems = new Set();
      this.data.forEach((item) => {
        if (Array.isArray(item[attr])) {
          item[attr].forEach((element) => {
            uniqueItems.add(element);
          });
        }
      });
      this.uniques[attr] = Array.from(uniqueItems);
    }

    // Init filter, unless there's already something in there
    if (Object.keys(this.filter).length === 0) {
      this.filter = Object.fromEntries(
        Object.entries(this.uniques)
          .filter(([key, value]) => Array.isArray(value) && value.length > 0)
          .map(([key, value]) => [key, [...value]]),
      );
      for (const attr of this.filteringIntAttrs) {
        this.filter[attr] = false;
      }
      if (this.defaultFilter != null) {
        for (const key in this.defaultFilter) {
          this.filter[key] = this.defaultFilter[key];
        }
      }
    }

    this.computeData();
  }

  historyStr(item: any) {
    return `${dateTimeOrNull(item.timestamp, true)} by ${item.username}`;
  }

  initHistory() {
    this.data = this.data.map((item) => {
      const lastUpdate =
        "update_history" in item &&
        Array.isArray(item.update_history) &&
        item.update_history.length > 0
          ? this.historyStr(item.update_history[0])
          : null;

      return {
        ...item,
        ["lastUpdate"]: lastUpdate,
      };
    });
  }

  getUniqueProperties(list: T[], name: string) {
    return [...new Set(list.map((item) => item[name]))];
  }

  changeRowsPerPage(value: number) {
    this.rowsPerPage = value;
    this.currentPage = 1;
    this.computeData();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.setCurrentPage(this.currentPage + 1);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.setCurrentPage(this.currentPage - 1);
    }
  }

  reload() {
    this.currentPage = 1;
    this.computeData();
  }

  protected computeData() {
    const sortedFiltered = this.sortFilterData();
    this.rawPaginatedData = this.sliceForCurrentPage(sortedFiltered);
    this.paginatedData = this.formatDataList(this.rawPaginatedData);
    this.setTotalPages(
      Math.max(Math.ceil(sortedFiltered.length / this.rowsPerPage), 1),
    );
  }

  protected setCurrentPage(page: number) {
    this.currentPage = page;
    this.computeData();
  }

  protected setTotalPages(pages: number) {
    this.totalPages = pages;
  }

  switchSortOrder(newOrder: number[]) {
    if (this.canSwitchSortOrder) {
      if (newOrder.length !== this.sortBy.length) {
        throw new Error(
          "The length of newOrder must match the length of sortBy",
        );
      }
      this.sortBy = newOrder.map((index) => this.sortBy[index]);
      this.sortByNames = newOrder.map((index) => this.sortByNames[index]);
      this.initSort = newOrder.map((index) => this.initSort[index]);
      this.sortDesc = newOrder.map((index) => this.sortDesc[index]);

      this.reload();
    }
  }

  getSortNames() {
    return this.sortByNames.join(", ");
  }

  rotateSortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      this.sortDesc[id] = true;
    } else if (this.sortDesc[id] === true) {
      this.sortDesc[id] = false;
    } else {
      if (this.sortBy.length === 1) {
        this.sortDesc[id] = true;
      } else {
        this.sortDesc[id] = null;
      }
    }
    this.reload();
  }

  resetSort() {
    this.sortDesc = this.initSort;
    this.reload();
  }

  sortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      return "&#9711;";
    } else if (this.sortDesc[id] === false) {
      return "&#9650;";
    } else {
      return "&#9660;";
    }
  }

  sortAndFilterCustom(sortBy: string[], sortDesc: boolean[], filter: object) {
    if (sortBy.length !== sortDesc.length) {
      throw new Error("The length of sortDir must match the length of sortBy");
    }
    const fieldSorter =
      (fields: string[], sortDesc: boolean[]) => (a: any, b: any) => {
        return fields
          .map((field: string, index: number) => {
            let dir = sortDesc[index] === null ? 0 : sortDesc[index] ? -1 : 1;

            if (this.dateFields.includes(field)) {
              const dateA = new Date(a[field]);
              const dateB = new Date(b[field]);
              return dateA > dateB ? dir : dateA < dateB ? -dir : 0;
            } else if (this.stringFields.includes(field)) {
              return (
                a[field].localeCompare(b[field], undefined, {
                  sensitivity: "base",
                }) * dir
              );
            } else {
              return a[field] > b[field] ? dir : a[field] < b[field] ? -dir : 0;
            }
          })
          .reduce((prev, next) => (prev ? prev : next), 0);
      };

    const sorted = this.data.sort(fieldSorter(sortBy, sortDesc));

    if (this.filteringAttrs.length > 0 || filter) {
      var filtered = sorted.filter((item) => {
        return Object.keys(filter).every((key) => {
          if (Array.isArray(item[key]) && item[key].length > 0) {
            return item[key].some((element) => filter[key].includes(element));
          } else if (
            (Array.isArray(item[key]) && item[key].length === 0) ||
            item[key] == null
          ) {
            return true;
          } else {
            if (Array.isArray(filter[key])) {
              return filter[key].includes(item[key]);
            } else if (typeof filter[key] === "boolean") {
              return !(item[key] === 0 && filter[key]);
            }
          }
        });
      });
    } else {
      var filtered = sorted.map((item) => item);
    }

    filtered = filterByQuery(filtered, this.queryKey, this.queryFilter);

    return filtered;
  }

  formatDataList(data: T[]) {
    return data.map((item) => {
      const newItem = { ...item };
      this.dateFields.forEach((field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = dateTimeOrNull(value, false);
        }
      });
      this.dateTimeFields.forEach((field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = dateTimeOrNull(value, true);
        }
      });
      return newItem;
    });
  }

  sortFilterData() {
    return this.sortAndFilterCustom(this.sortBy, this.sortDesc, this.filter);
  }

  protected sliceForCurrentPage<T>(data: T[]): T[] {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = start + this.rowsPerPage;
    return data.slice(start, end);
  }

  startEditing(editId: number, fields?: string[]) {
    this.editingId = editId;
    this.editedField = this.getEditedField(editId);
    this.currentlyEditable = fields;
  }

  stopEditing() {
    this.editingId = undefined;
    this.editedField = undefined;
    this.currentlyEditable = undefined;
  }

  getEditedField(index: number) {
    const member = this.rawPaginatedData[index];
    var editedField = Object.keys(member)
      .filter(
        (key) =>
          (this.editable.length === 0 || this.editable.includes(key)) &&
          (!this.currentlyEditable || this.currentlyEditable.includes(key)),
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    for (const key of this.dateTimeFields) {
      if (editedField.hasOwnProperty(key)) {
        const value = editedField[key];
        editedField[key] = value ? parseDatetimeToLocal(value) : null;
      }
    }
    return editedField;
  }

  canEdit(fieldName: string, member: object) {
    return (
      !this.currentlyEditable || this.currentlyEditable.includes(fieldName)
    );
  }

  canDeleteItem(index?: number) {
    switch (this.userLevel) {
      case 4:
        return true;
      case 3:
      case 2:
        const member = this.rawPaginatedData[index];
        const itemOwner = member?.["user_uuid"];
        switch (this.access.deleteRules[this.userLevel]) {
          case AccessRules.none:
            return false;
          case AccessRules.owner:
            return itemOwner === this.userUUID;

          case AccessRules.visible:
            return true;
        }
      default:
        return false;
    }
  }

  canEditItem(index?: number) {
    switch (this.userLevel) {
      case 4:
        return true;
      case 3:
      case 2:
        const member = this.rawPaginatedData[index];
        const itemOwner = member?.["user_uuid"];
        switch (this.access.editRules[this.userLevel]) {
          case AccessRules.none:
            return false;
          case AccessRules.owner:
            return itemOwner === this.userUUID;
          case AccessRules.visible:
            return true;
        }
      default:
        return false;
    }
  }

  toggleHistory() {
    if (this.hasHistory && this.canShowHistory) {
      this.showingHistory = !this.showingHistory;
    }
  }
}

export function filterByQuery<T>(data: T[], queryKey: string, query: string) {
  if (!queryKey || query === "") {
    return data;
  }

  return data.filter((item) => {
    const valueToCheck = String(item[queryKey]).toLowerCase();
    return valueToCheck.includes(query.toLowerCase());
  });
}
