import { reactive } from "@vue/reactivity";
import {
  apiGetClinics,
  apiGetMonitoringTeams,
  apiGetPhysicians,
  apiGetUsersResetUuid,
  apiGetUsersTemporaryPassUuid,
  apiPutClinicsUuid,
  apiPutMonitoringTeamsUuid,
  apiPutPhysiciansUuid,
  apiPutUsersUuid,
} from "../api";
import { ApiConnector } from "../api/ApiConnector";
import { Patients } from "../patients/Patients";
import { Clinics } from "./clinics/Clinics";
import { Physicians } from "./physicians/Physicians";
import { MonitoringTeams } from "./teams/MonitoringTeams";
import { Users } from "./users/Users";

export class AdminConsole {
  clinics: Clinics;
  physicians: Physicians;
  patients: Patients;
  users: Users;
  monTeams: MonitoringTeams;

  tempPasswordPopup = false;
  tempPasswordText = "";

  initialized = false;

  constructor(public api: ApiConnector) {}

  async init() {
    const clinicsResponse = await apiGetClinics();
    this.clinics = new Clinics(
      clinicsResponse.data.clinics,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.clinics.init();

    const physiciansResponse = await apiGetPhysicians();
    this.physicians = new Physicians(
      physiciansResponse.data.physicians,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.physicians.init();

    const teamsResponse = await apiGetMonitoringTeams();
    this.monTeams = new MonitoringTeams(
      teamsResponse.data.monitoring_teams,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.monTeams.init();

    this.patients = new Patients(this.api, false);
    await this.patients.init();

    this.users = new Users(this.api);
    if (this.api.userLevel === 4) {
      await this.users.init();
    } else {
      await this.users.initPhysiciansOnly();
    }

    this.initialized = true;
  }

  // creating data
  createPatients() {
    const patients = reactive(new Patients(this.api, false));
    void patients.init();
    return patients;
  }

  createClinics() {
    const clinics = reactive(
      new Clinics([], this.api.userLevel, this.api.userUUID),
    );

    void apiGetClinics().then((res) => {
      clinics.data = res.data.clinics;
      clinics.init();
    });

    return clinics;
  }

  createPhysicians() {
    const physicians = reactive(
      new Physicians([], this.api.userLevel, this.api.userUUID),
    );

    void apiGetPhysicians().then((res) => {
      physicians.data = res.data.physicians;
      physicians.init();
    });

    return physicians;
  }

  createUsers() {
    const modal = reactive(new Users(this.api));

    if (this.api.userLevel === 4) {
      void modal.init();
    } else {
      void modal.initPhysiciansOnly();
    }

    return modal;
  }

  // updates
  async updateUser(uuid: string, body: object) {
    const userBody = {
      preferred_language: body["preferred_language"],
      connected_patient_id: body["connected_patient_id"],
      managed_patient_ids: body["managed_patients"],
    };

    await apiPutUsersUuid({
      path: { uuid: uuid },
      body: userBody,
    });
    await this.users.init();
    window.location.reload();
  }

  async resetUser(uuid: string) {
    await apiGetUsersResetUuid({
      path: { uuid: uuid },
      query: { reset_email: true },
    });
    window.location.reload();
  }

  async temporaryPassword(uuid: string) {
    const response = await apiGetUsersTemporaryPassUuid({
      path: { uuid: uuid },
      query: { token_expiry_timedelta: "P2D" },
    });
    this.tempPasswordText = response.data.temporary_password;
    this.tempPasswordPopup = true;
  }

  tempPasswordTooltipText(item) {
    const alreadyText = "User already has a temporary password";
    const genText =
      "Generate temporary password for the patient, that is valid for 2 days.";
    return item.has_temporary_password ? alreadyText : genText;
  }

  async closeTemporaryPasswordPopup() {
    this.tempPasswordPopup = false;
    window.location.reload();
  }

  async updateClinic(uuid: string, body: object) {
    await apiPutClinicsUuid({
      path: { uuid: uuid },
      body: body,
    });
    this.clinics.init();
    window.location.reload();
  }

  async updatePhysician(uuid: string, body: object) {
    await apiPutPhysiciansUuid({
      path: { uuid: uuid },
      body: body,
    });
    this.physicians.init();
    window.location.reload();
  }

  async updateMonitoringTeam(uuid: string, body: object) {
    await apiPutMonitoringTeamsUuid({
      path: { uuid: uuid },
      body: body,
    });
    this.monTeams.init();
    window.location.reload();
  }
}
