import { apiPostPpgSmartcare } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";
import { parseDatetimeToLocal } from "../../common/utils/utils";

export class AddPPG extends DataAdd {
  ppgFile = new FormData();

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.desc = "PPG data from SmartCare device (others not tested)";
    this.endpoint = "";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTime = null;
    this.reloadToTab = "ppg";
  }

  async initEmpty() {
    this.addingList = {
      measurement_datetime: this.defaultDateTime,
      measurement_condition: null,
      measurement_device: "SmartCare BM2000A",
      measurement_origin: "finger",
      measurement_mode: 1,
      quality_flag: 1,
      comment: "",
    };
  }

  uploadFile(event) {
    const file = event.target.files[0];
    this.ppgFile.set("ppg_file", file);
  }

  objectToQueryString(obj) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
      )
      .join("&");
  }

  async post() {
    this.addingList.patient_id = this.patient.patientId;
    for (const key in this.addingList) {
      if (
        this.dateTimeFields.includes(key) &&
        this.addingList.hasOwnProperty(key)
      ) {
        var datetime = this.addingList[key];
        this.addingList[key] = new Date(
          parseDatetimeToLocal(datetime),
        ).toISOString();
      }
    }

    const response = await apiPostPpgSmartcare({
      body: this.ppgFile as any,
      bodySerializer: (body) => body,
      query: this.addingList,
    });
    if (response.data != null) {
      window.navigate(`/patient/${this.patient.patientId}/${this.reloadToTab}`);
      window.location.reload();
    }
  }
}
