import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { Patient } from "../Patient";
import { AddRHC } from "./AddRHC";

export function AddRHCTable(props: { patient: Patient }) {
  const model = reactive(new AddRHC(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      model={model}
      canAddRows={false}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          cell: (item) => (
            <CellDateField
              attr="measurement_datetime"
              model={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          cell: (item) => (
            <label>
              {item.measurement_type.charAt(0).toUpperCase() +
                item.measurement_type.slice(1)}
            </label>
          ),
        },
        {
          header: "Required",
          cell: (item) => (
            <>
              <Show
                when={model.requiredEitherOr.includes(item.measurement_type)}
              >
                <span style="color: #bb16a3">*</span>
              </Show>
              <Show
                when={!model.requiredEitherOr.includes(item.measurement_type)}
              >
                <span
                  style={
                    model.required.includes(item.measurement_type)
                      ? "color: #bb16a3"
                      : "font-size: 0.8em"
                  }
                >
                  {model.required.includes(item.measurement_type)
                    ? "Yes"
                    : "No"}
                </span>
              </Show>
            </>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <CellTextField attr="measurement_value" model={item} width={70} />
          ),
        },
        {
          header: "Unit",
          cell: (item) => <label>{item.measurement_unit}</label>,
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
      ]}
      footer={
        <span>
          <span style="color: #bb16a3">*</span> = either Fick or TD required,
          you can enter both measurements
        </span>
      }
    />
  );
}
