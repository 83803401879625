import { DateField } from "../../fields/DateField";

export function CellDateField(props: {
  model: Record<string, any>;
  attr: string;
  type: "datetime-local" | "date";
  required?: boolean;
}) {
  return (
    <DateField
      attr={props.attr}
      model={props.model}
      type={props.type}
      required={props.required}
    />
  );
}
