import { DataModel } from "../common/DataModel";

export class SimplePatients extends DataModel {
  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.sortBy = ["patient_id", "created_at"];
    this.sortByNames = ["Patient ID", "Added"];
    this.dateTimeFields = ["created_at", "last_ppg"];
    this.dateFields = ["date_of_birth"];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = [
      "patient_state",
      "patient_status",
      "realm",
      "sex",
      "informed_consent",
    ];
    this.filteringListAttrs = ["patient_study"];
    this.editable = ["patient_state"];
    this.queryKey = "patient_id";
  }

  init() {
    this.data = this.data.map((item) => {
      const addedBy = item.user.username;
      return { ...item, ["added_by"]: addedBy };
    });
    super.init();
  }
}
