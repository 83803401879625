import { reactive } from "@vue/reactivity";
import { AlertKind } from "../../api";
import { alertKinds } from "../../common/enumToText";
import { ChoosingModal } from "../../common/uiKit/choosingModal/ChoosingModal";
import { ChoosingModalModel } from "../../common/uiKit/choosingModal/ChoosingModalModel";
import { SelectMultipleField } from "../../common/uiKit/fields/SelectMultipleField";
import { CellSelectRow } from "../../common/uiKit/tables/cells/CellSelectRow";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSelectRows } from "../../common/uiKit/tables/headers/HeaderSelectRows";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Users } from "../users/Users";

export function MembersModal(props: {
  users: Users;
  editedTeam: Record<string, any>;
  onClose: () => void;
  readOnly?: boolean;
}) {
  const modal = reactive(
    new ChoosingModalModel(props.users, {
      mode: "multi",
      filter: ["role", ["study-physician", "physician"]],
      preselected: props.editedTeam["members"],
    }),
  );
  const alertsMap = reactive(new Map<string, AlertKind[]>());

  // Populate the alertsMap
  (props.editedTeam["members"] ?? []).forEach((uuid, i) => {
    const alerts = (props.editedTeam["members_alert_kinds"] ?? [])[i] ?? [];
    alertsMap.set(uuid, alerts);
  });

  return (
    <ChoosingModal
      modal={modal}
      onClose={props.onClose}
      onConfirm={({ selected }) => {
        props.editedTeam["members"] = selected;
        props.editedTeam["members_alert_kinds"] = selected.map(
          (uuid) => alertsMap.get(uuid) ?? [],
        );
      }}
      readOnly={props.readOnly}
      title="Team members"
      table={
        <DataTable
          columns={[
            {
              header1: "Choose",
              header2: props.readOnly ? undefined : (
                <HeaderSelectRows model={modal} idAttr="uuid" />
              ),
              cell: (item) => (
                <CellSelectRow
                  id={item.uuid}
                  model={modal}
                  disabled={props.readOnly}
                />
              ),
            },
            {
              header1: "Username",
              header2: <HeaderSort attr="username" model={modal.modalData} />,
              cell: (item) => <strong>{item.username}</strong>,
            },
            {
              header1: "Role",
              header2: (
                <HeaderSelectFilter
                  attr="role"
                  model={modal.modalData}
                  name="Role"
                />
              ),
              cell: (item) => item.role,
            },
            {
              header1: "# of managed patients",
              cell: (item) => item.managed_patients?.length ?? 0,
            },
            {
              header1: "# of monitored patients",
              cell: (item) => item.monitoring_patients?.length ?? 0,
            },
            {
              header1: "Alerts",
              cell: (item) =>
                modal.isSelected(item.uuid) ? (
                  <SelectMultipleField
                    values={alertKinds}
                    value={alertsMap.get(item.uuid) ?? []}
                    onChange={(newValue) => alertsMap.set(item.uuid, newValue)}
                    disabled={props.readOnly}
                  />
                ) : null,
            },
          ]}
          model={modal.modalData}
          isRowSelected={(item) => modal.isSelected(item.uuid)}
        />
      }
    />
  );
}
