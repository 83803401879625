import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { ppgRelatedFlag } from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Tags } from "../../common/uiKit/Tags";
import { floatRounding } from "../../common/utils/utils";
import { MedicalPlot } from "../common/MedicalPlot";
import { PatientSubpage } from "../common/PatientSubpage";
import { AddMedicalTable } from "../medical/AddMedicalTable";
import { Patient } from "../Patient";

export function VitalsTable(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchVitals());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Vitals" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add vitals
      </Button>

      <Show when={adding()}>
        <AddMedicalTable patient={props.patient} variant="vitals" />
      </Show>

      <MedicalPlot
        dataModel={model()}
        plotTypeOptions={[
          "heart rate",
          "blood pressure diastolic",
          "blood pressure systolic",
          "SpO2",
        ]}
      />

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Measured",
            header2: <HeaderSort model={model()} attr="measurement_datetime" />,
            cell: (item, index) => (
              <CellDate
                model={model()}
                index={index}
                attr="measurement_datetime"
                type="datetime-local"
              />
            ),
          },
          {
            header1: "Source",
            header2: (
              <HeaderSelectFilter
                attr="data_source"
                model={model()}
                name="Source"
              />
            ),
            cell: (item) => item.data_source,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="measurement_type"
                model={model()}
                name="Type"
              />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellText
                attr="measurement_value"
                index={index}
                model={model()}
                displayValue={floatRounding(item.measurement_value, 1)}
                width={50}
              />
            ),
          },
          {
            header1: "Unit",
            cell: (item) => item.measurement_unit,
          },
          {
            header1: "PPG condition",
            header2: (
              <HeaderSelectFilter
                attr="ppg_conditions"
                model={model()}
                name="Condition"
              />
            ),
            cell: (item) => item.ppg_conditions,
          },
          {
            header1: "Quality Flag",
            header2: (
              <HeaderSelectFilter
                attr="seerlinq_measurement_quality_flag"
                model={model()}
                name="Flag"
                values={ppgRelatedFlag}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="seerlinq_measurement_quality_flag"
                index={index}
                model={model()}
                values={ppgRelatedFlag}
              />
            ),
          },
          {
            header1: "Tags",
            header2: (
              <HeaderSelectFilter attr="tags" model={model()} name="Tags" />
            ),
            cell: (item) => <Tags tags={item.tags} />,
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <CellEditButtons
                  model={model()}
                  index={index}
                  onConfirm={(editedField) => {
                    props.patient.updateItem(
                      model().updateEndpoint[item.data_source],
                      item.uuid,
                      editedField,
                      model().dateTimeFields,
                    );
                  }}
                />
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <>
                <Show
                  when={
                    !model().notEditable.includes(item.measurement_type) &&
                    item.data_source == "Patient / Physician"
                  }
                >
                  <CellDeleteButton
                    index={index}
                    model={model()}
                    onConfirm={() =>
                      props.patient.deleteItem("medicaldata", item.uuid)
                    }
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
                <Show when={item.data_source !== "Patient / Physician"}>
                  &nbsp;(cannot delete)
                </Show>
              </>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </PatientSubpage>
  );
}
