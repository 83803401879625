import { Component, createSignal, JSX, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import { Button } from "../Button";

export function ChooseField(props: {
  value: JSX.Element;
  modal: Component<{ onClose: () => void }>;
}) {
  const [modalOpen, setModalOpen] = createSignal(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <span>{props.value}</span>
      <br />
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Choose
      </Button>

      <Show when={modalOpen()}>
        <Dynamic component={props.modal} onClose={closeModal} />
      </Show>
    </>
  );
}
