import clsx from "clsx";
import { JSX, mergeProps } from "solid-js";
import styles from "./Button.module.css";

interface Props extends JSX.ButtonHTMLAttributes<HTMLButtonElement> {}

export function Button(props: Props) {
  const defaults = { type: "button" };
  const finalProps = mergeProps(defaults, props);

  return (
    <button {...finalProps} class={clsx(styles.button, finalProps.class)}>
      {props.children}
    </button>
  );
}
