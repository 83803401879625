import { reactive } from "@vue/reactivity";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { AddLabs } from "../labs/AddLabs";
import { Patient } from "../Patient";
import { AddVitals } from "../vitals/AddVitals";
import { AddBasics } from "./AddBasics";

export function AddMedicalTable(props: {
  patient: Patient;
  variant: "vitals" | "basics" | "labs";
}) {
  const model = reactive(
    props.variant === "vitals"
      ? new AddVitals(props.patient.api, props.patient)
      : props.variant === "basics"
        ? new AddBasics(props.patient.api, props.patient)
        : new AddLabs(props.patient.api, props.patient),
  );

  model.initEmpty();

  return (
    <AddTable
      model={model}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          mandatory: true,
          cell: (item) => (
            <CellDateField
              model={item}
              attr="measurement_datetime"
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              model={item}
              attr="measurement_type"
              values={model.variables}
              onChange={(newValue) => {
                item.measurement_unit = model.getUnits(newValue, true);
              }}
            />
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => (
            <CellTextField model={item} attr="measurement_value" />
          ),
        },
        {
          header: "Unit",
          cell: (item) => (
            <CellSelectField
              model={item}
              attr="measurement_unit"
              values={model.getUnits(item.measurement_type)}
              disabled={model.getUnits(item.measurement_type).length <= 1}
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField model={item} attr="comment" multiline="autosize" />
          ),
        },
        {
          header: "Remove data row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
    />
  );
}
