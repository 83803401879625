import { A } from "@solidjs/router";
import { JSX } from "solid-js";

export function Tab(props: { href?: string; children?: JSX.Element }) {
  return (
    <A href={props.href} class="modal-tab" activeClass="active">
      {props.children}
    </A>
  );
}
