import {
  apiGetAlertsMarkSeenUuid,
  apiGetAlertsWaitingRoom,
  apiGetUsersMonitoringUsers,
  apiPutAlertsUuid,
  apiPutPatientsPatientId,
  PatientState,
} from "../api";
import { ApiConnector } from "../api/ApiConnector";
import { Alerts } from "../patient/alerts/Alerts";
import { SimplePatients } from "./SimplePatients";

export class WaitingRoomConsole {
  maxAgeDays: number = 60;
  medical: Alerts = null;
  nonMedical: Alerts = null;
  highRisk: SimplePatients = null;
  possibleOwners: Record<string, string> = {};

  initialized = false;

  constructor(public api: ApiConnector) {}

  async init() {
    await this.api.checkLoggedIn();
    const waitingRoom = await apiGetAlertsWaitingRoom({
      query: { alert_age_max: `P${this.maxAgeDays}D`, admin_listing: "all" },
    });
    this.medical = new Alerts(
      waitingRoom.data.medical_alerts,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.medical.init();

    this.nonMedical = new Alerts(
      waitingRoom.data.non_medical_alerts,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.nonMedical.init();

    this.highRisk = new SimplePatients(
      waitingRoom.data.high_risk_patients,
      this.api.userLevel,
      this.api.userUUID,
    );
    this.highRisk.init();

    const monitoringUsers = await apiGetUsersMonitoringUsers();
    this.possibleOwners = monitoringUsers.data.users.reduce((acc, obj) => {
      acc[obj.uuid] = obj.username;
      return acc;
    }, {});

    this.initialized = true;
  }

  async markSeen(uuid: string) {
    await apiGetAlertsMarkSeenUuid({ path: { uuid: uuid } });
    window.location.reload();
  }

  async changeOwner(uuid: string, body: object) {
    const cleanBody = {
      alert_owner_uuid: body["ownerUuid"],
      append_tags: ["owner changed"],
    };

    await apiPutAlertsUuid({
      path: { uuid: uuid },
      body: cleanBody,
    });

    window.location.reload();
  }

  async patientNotHighRisk(patientId: number) {
    const body = { patient_state: PatientState.normal };
    await apiPutPatientsPatientId({
      path: { patient_id: patientId },
      body: body,
    });

    window.location.reload();
  }
}

export type WaitingRoomTabId = "medical" | "non-medical" | "high-risk";
