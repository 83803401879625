import { Show } from "solid-js";
import { DataModel } from "../../DataModel";
import { Button } from "../Button";

export function RowsSelector(props: { model: DataModel }) {
  return (
    <div class="pagination-rows">
      <span>
        <label>Rows per page:</label>{" "}
        <select
          value={props.model.rowsPerPage}
          onChange={(event) => {
            props.model.changeRowsPerPage(parseInt(event.target.value));
          }}
        >
          {props.model.rowsPerPageOptions.map((opt) => (
            <option>{opt}</option>
          ))}
        </select>
      </span>

      <Show when={props.model.hasHistory && props.model.canShowHistory}>
        <span class="history">
          <Button
            onClick={() => {
              props.model.toggleHistory();
            }}
          >
            Toggle history
          </Button>
        </span>
      </Show>
    </div>
  );
}
