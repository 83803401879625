import { createSignal, Show } from "solid-js";
import { Spacer } from "../../Spacer";
import styles from "./CellJSON.module.css";

export function CellJSON(props: { value: Record<string, unknown> }) {
  const [expanded, setExpanded] = createSignal(false);

  return (
    <div class={styles.root}>
      <Show when={!expanded()}>
        <div class={styles.plainJson}>{JSON.stringify(props.value)}</div>

        <Spacer grow />
      </Show>

      <Show when={expanded()}>
        <table class={styles.table}>
          <tbody>
            {Object.entries(props.value).map(([key, value]) => (
              <tr>
                <td>{key}</td>
                <td>
                  {typeof value === "string" ? value : JSON.stringify(value)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Show>

      <button
        type="button"
        onClick={() => {
          setExpanded(!expanded());
        }}
        class={styles.button}
      >
        {expanded() ? "\u25B2" : "\u25BC"}
      </button>
    </div>
  );
}
