import { reactive } from "@vue/reactivity";
import { createMemo, createSignal, Show } from "solid-js";
import { seerlinqRealms } from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { ChooseField } from "../../common/uiKit/fields/ChooseField";
import { SelectMultipleField } from "../../common/uiKit/fields/SelectMultipleField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { FormRow } from "../../common/uiKit/form/FormRow";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnumMultiple } from "../../common/uiKit/tables/cells/CellEnumMultiple";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { maybeAsArray } from "../../common/utils/utils";
import { AdminConsole } from "../AdminConsole";
import { UsersModal } from "../common/UsersModal";
import { AddMonitoringTeam } from "./AddMonitoringTeam";
import { CellMembers } from "./CellMembers";
import { MembersModal } from "./MembersModal";

export function TeamsAdmin(props: { admin: AdminConsole }) {
  const model = createMemo(() => props.admin.monTeams);
  const [dataAdd, setDataAdd] = createSignal<AddMonitoringTeam>();

  return (
    <div id="modal-content">
      <h2>Monitoring teams</h2>
      <span>
        We have <strong>{props.admin.monTeams.data.length}</strong> monitoring
        teams in the database.
      </span>

      <br />
      <br />

      <Button
        onClick={() => {
          if (dataAdd()) {
            setDataAdd(null);
          } else {
            const model = reactive(
              new AddMonitoringTeam(props.admin.api, null),
            );
            model.initEmpty();
            setDataAdd(model);
          }
        }}
      >
        {dataAdd() ? "Cancel" : "Create new monitoring team"}
      </Button>

      <br />
      <br />

      <Show when={!dataAdd()}>
        <DataTable
          columns={[
            {
              header1: "Name",
              header2: <HeaderSort attr="display_name" model={model()} />,
              cell: (item, index) => (
                <CellText
                  attr="display_name"
                  index={index}
                  model={model()}
                  width={200}
                />
              ),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realms"
                  model={model()}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item, index) => (
                <CellEnumMultiple
                  attr="realms"
                  values={seerlinqRealms}
                  index={index}
                  model={model()}
                />
              ),
            },
            { header1: "Admin", cell: (item) => item.admin.username },
            {
              header1: "Members",
              cell: (item, index) => (
                <CellMembers
                  admin={props.admin}
                  index={index}
                  item={item}
                  model={model()}
                />
              ),
            },
            {
              header1: "# of patients",
              cell: (item) => item.totalPatients,
            },
            {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updateMonitoringTeam(item.uuid, editedField);
                  }}
                />
              ),
            },
          ]}
          model={model()}
        />
      </Show>

      <Show when={dataAdd()}>
        <div>
          <h3>Add new monitoring team</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Name"
                data={
                  <TextField attr="display_name" model={dataAdd().addingList} />
                }
                mandatory
              />

              <FormRow
                label="Monitoring realm"
                data={
                  <SelectMultipleField
                    attr="realms"
                    model={dataAdd().addingList}
                    values={seerlinqRealms}
                  />
                }
                mandatory
              />

              <FormRow
                label="Team admin"
                data={
                  <ChooseField
                    value={
                      dataAdd().addingList["admin_uuid"]
                        ? props.admin.users.userMapping[
                            dataAdd().addingList["admin_uuid"]
                          ]
                        : "None"
                    }
                    modal={({ onClose }) => (
                      <UsersModal
                        title="Choose admin for a new monitoring team"
                        preselected={maybeAsArray(
                          dataAdd().addingList["admin_uuid"],
                        )}
                        filter={[
                          "role",
                          ["study-physician", "seerlinq-user", "admin"],
                        ]}
                        mode="single"
                        model={props.admin.createUsers()}
                        onClose={onClose}
                        onConfirm={({ selected }) => {
                          dataAdd().addingList["admin_uuid"] = selected[0];
                        }}
                      />
                    )}
                  />
                }
                mandatory
              />

              <FormRow
                label="Members"
                data={
                  <ChooseField
                    value={`${dataAdd().addingList["members"]?.length ?? 0} chosen`}
                    modal={({ onClose }) => (
                      <MembersModal
                        editedTeam={dataAdd().addingList}
                        users={props.admin.createUsers()}
                        onClose={onClose}
                      />
                    )}
                  />
                }
              />
            </table>

            <br />
            <br />

            <Button
              onClick={() => {
                dataAdd().post();
              }}
            >
              Add monitoring team
            </Button>
          </form>
        </div>
      </Show>
    </div>
  );
}
