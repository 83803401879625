import { createSignal } from "solid-js";
import styles from "./DateField.module.css";
import { useField } from "./utils";

export function DateField(props: {
  model: Record<string, any>;
  attr: string;
  type: "datetime-local" | "date";
  required?: boolean;
}) {
  const [element, setElement] = createSignal<HTMLInputElement>();
  const { showErrors } = useField(element);

  return (
    <input
      type={props.type}
      step="1"
      value={props.model?.[props.attr]}
      onChange={(event) => {
        props.model[props.attr] = event.target.value;
      }}
      required={props.required}
      classList={{ [styles.root]: true, [styles.showErrors]: showErrors() }}
      ref={setElement}
    />
  );
}
