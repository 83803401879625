import { apiPostMedicalReports } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";
import { parseDatetimeToLocal } from "../../common/utils/utils";

export class AddReport extends DataAdd {
  private file = new FormData();

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.desc = "Report";
    this.dateTimeFields = ["report_date"];
    this.reloadToTab = "reports";
  }

  async initEmpty() {
    this.addingList = {
      report_date: null,
      report_type: null,
      comment: "",
    };
  }

  uploadFile(file: File) {
    this.file.set("file", file);
  }

  async post() {
    this.addingList.patient_id = this.patient.patientId;
    for (const key in this.addingList) {
      if (
        this.dateTimeFields.includes(key) &&
        this.addingList.hasOwnProperty(key)
      ) {
        var datetime = this.addingList[key];
        this.addingList[key] = new Date(
          parseDatetimeToLocal(datetime),
        ).toISOString();
      }
    }

    const response = await apiPostMedicalReports({
      body: this.file as any,
      bodySerializer: (body) => body,
      query: this.addingList,
    });

    if (response.data != null) {
      window.navigate(`/patient/${this.patient.patientId}/${this.reloadToTab}`);
      window.location.reload();
    }
  }
}
