import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddMedication } from "./AddMedication";

export function AddMedicationsTable(props: { patient: Patient }) {
  const model = reactive(new AddMedication(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      setAllDates="date"
      columns={[
        {
          header: "Group",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="medication_group"
              model={item}
              values={Object.keys(model.medicationOptions)}
              listTitle="Select group"
              onChange={() => {
                item.medication_name = null;
              }}
            />
          ),
        },
        {
          header: "Name",
          mandatory: true,
          cell: (item) => (
            <>
              <CellSelectField
                attr="medication_name"
                model={item}
                values={{
                  ...arrayToObject(
                    model.medicationOptions[item.medication_group] ?? [],
                  ),
                  ...(model.allowFreeText.includes(item.medication_group)
                    ? { [model.freeTextPlaceHolder]: "Other..." }
                    : {}),
                }}
                listTitle="Select name"
              />
              <Show when={item.medication_name == model.freeTextPlaceHolder}>
                <CellTextField attr="freeTextValue" model={item} width={75} />
              </Show>
            </>
          ),
        },
        {
          header: "Started",
          cell: (item) => (
            <CellDateField attr="medication_started" model={item} type="date" />
          ),
        },
        {
          header: "Ended",
          cell: (item) => (
            <CellDateField attr="medication_ended" model={item} type="date" />
          ),
        },
        {
          header: "Dose",
          mandatory: true,
          cell: (item) => (
            <CellTextField
              attr="medication_dose"
              model={item}
              width={50}
              disabled={model.disabledDoseUnit(item)}
            />
          ),
        },
        {
          header: "Unit",
          mandatory: true,
          cell: (item) => (
            <CellTextField
              attr="medication_unit"
              model={item}
              width={50}
              disabled={model.disabledDoseUnit(item)}
            />
          ),
        },
        {
          header: "Dosage",
          mandatory: true,
          cell: (item) => (
            <>
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[0]}
                onChange={(event) => {
                  item.medication_dosage[0] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[1]}
                onChange={(event) => {
                  item.medication_dosage[1] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[2]}
                onChange={(event) => {
                  item.medication_dosage[2] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[3]}
                onChange={(event) => {
                  item.medication_dosage[3] = event.target.value;
                }}
                style={{ width: "30px" }}
              />
            </>
          ),
        },
        {
          header: "Frequency",
          cell: (item) => (
            <CellTextField
              attr="medication_frequency_regular_repeat_every"
              model={item}
              type="number"
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
      model={model}
    />
  );
}

function arrayToObject(arr: string[]): Record<string, string> {
  const res: Record<string, string> = {};

  for (const item of arr) {
    res[item] = item;
  }

  return res;
}
