import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { CellCheckbox } from "../../common/uiKit/tables/cells/CellCheckbox";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Patient } from "../Patient";
import { PatientSubpage } from "../common/PatientSubpage";
import { AddSymptomsTable } from "./AddSymptomsTable";
import { SymptomPlot } from "./SymptomPlot";

export function SymptomsTable(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchSymptoms());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Symptoms" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add symptoms
      </Button>

      <Show when={adding()}>
        <AddSymptomsTable patient={props.patient} />
      </Show>

      <SymptomPlot
        dataModel={model()}
        plotTypeOptions={["shortness of breath", "fatigue score"]}
      />

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        legendExtra={<span>(Dates are in UTC)</span>}
        columns={[
          {
            header1: (
              <HeaderSort model={model()} attr="symptom_date" name="Started" />
            ),
            cell: (item, index) => (
              <CellDate
                attr="symptom_date"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="symptom_name" name="Symptom" />
            ),
            cell: (item) => item.symptom_name,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellText
                attr="symptom_value"
                index={index}
                model={model()}
                type="number"
              />
            ),
          },
          {
            header1: "Change in last 6 months",
            cell: (item, index) => (
              <CellCheckbox
                attr="symptom_change_in_last_six_m"
                index={index}
                model={model()}
              />
            ),
          },
          {
            header1: "Change date",
            cell: (item, index) => (
              <CellDate
                attr="symptom_change_date"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Value before",
            cell: (item, index) => (
              <CellText
                attr="symptom_value_before"
                index={index}
                model={model()}
                type="number"
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                multiline="autosize"
                width={80}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("symptoms", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <CellDeleteButton
                index={index}
                model={model()}
                onConfirm={() =>
                  props.patient.deleteItem("symptoms", item.uuid)
                }
              />
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </PatientSubpage>
  );
}
