import { reactive } from "@vue/reactivity";
import { createResource, createSignal, For, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import {
  ppgConditions,
  ppgMeasModes,
  ppgQuality,
} from "../../common/enumToText";
import { PPGQuickLook } from "../../common/quickLookAnalyses";
import { Button } from "../../common/uiKit/Button";
import { Container } from "../../common/uiKit/Container";
import { Spinner } from "../../common/uiKit/Spinner";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { floatRounding } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddPPGForm } from "./AddPPGForm";
import { PPGShareDialog } from "./PPGShareDialog";

export function PPGTable(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchPpgData());
  const [adding, setAdding] = createSignal(false);
  const ppgQuickLook = reactive(new PPGQuickLook(props.api));

  return (
    <PatientSubpage title="PPG data" loading={model.loading}>
      <PPGShareDialog ppgQuickLook={ppgQuickLook} />

      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add PPG data
      </Button>

      <Show when={adding()}>
        <AddPPGForm patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Measured",
            header2: <HeaderSort attr="measurement_datetime" model={model()} />,
            cell: (item) => item.measurement_datetime,
          },
          {
            header1: "Condition",
            header2: (
              <HeaderSelectFilter
                attr="measurement_condition"
                model={model()}
                name="Condition"
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="measurement_condition"
                index={index}
                model={model()}
                values={ppgConditions}
              />
            ),
          },
          {
            header1: "Device",
            header2: (
              <HeaderSelectFilter
                attr="measurement_device"
                model={model()}
                name="Device"
              />
            ),
            cell: (item) => item.measurement_device,
          },
          {
            header1: "Origin",
            header2: (
              <HeaderSelectFilter
                attr="measurement_origin"
                model={model()}
                name="Origin"
              />
            ),
            cell: (item) => item.measurement_origin,
          },
          {
            header1: "Length",
            cell: (item) => floatRounding(item.length, 1),
          },
          {
            header1: "Mode",
            header2: (
              <HeaderSelectFilter
                attr="measurement_mode"
                model={model()}
                name="Mode"
                values={ppgMeasModes}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="measurement_mode"
                index={index}
                model={model()}
                values={ppgMeasModes}
              />
            ),
          },
          {
            header1: "Quality",
            header2: (
              <HeaderSelectFilter
                attr="quality_flag"
                model={model()}
                name="Quality"
                values={ppgQuality}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="quality_flag"
                index={index}
                model={model()}
                values={ppgQuality}
              />
            ),
          },
          {
            header1: "SQI",
            cell: (item) => floatRounding(item.computed_sqi, 1),
          },
          props.api.isProdEnv && {
            header1: "LabelStudio",
            cell: (item) => (
              <Button
                onClick={() => {
                  props.patient.redirectToLabelStudio(item.uuid);
                }}
              >
                Redirect
              </Button>
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("ppg", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: (
              <>
                <em>QuickLook</em> analysis
              </>
            ),
            header2: (
              <>
                <Button onClick={() => model().selectAllQuickLook()}>
                  All
                </Button>
                <Button onClick={() => model().clearQuickLook()}>None</Button>
              </>
            ),
            cell: (item) => (
              <input
                type="checkbox"
                checked={model().toQuickLook.includes(item.uuid)}
                onChange={() => {
                  model().toggleQuickLook(item.uuid);
                  ppgQuickLook.reset();
                }}
              />
            ),
          },
        ]}
        rowsExtra={
          <>
            <Show when={props.api.userLevel >= 3}>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  <em>QuickLook</em> Analysis:
                </td>
                <td>
                  <select
                    value={ppgQuickLook.analysisType ?? ""}
                    onChange={(event) => {
                      ppgQuickLook.analysisType = event.target.value;
                      ppgQuickLook.reset();
                    }}
                  >
                    <option value="" disabled selected>
                      Select type...
                    </option>
                    <For each={Object.entries(ppgQuickLook.quickLookTypes)}>
                      {([key, option]) => (
                        <option value={key}>{option as string}</option>
                      )}
                    </For>
                  </select>
                </td>
                <td>
                  <Button
                    disabled={
                      ppgQuickLook.processing ||
                      model().toQuickLook.length === 0 ||
                      !ppgQuickLook.analysisType ||
                      !props.patient.consentOk()
                    }
                    onClick={() => {
                      if (ppgQuickLook.resultUrl) {
                        window.open(ppgQuickLook.resultUrl, "_blank");
                      } else {
                        ppgQuickLook.submit(model().toQuickLook);
                      }
                    }}
                    class="quicklook-button"
                  >
                    <Show
                      when={ppgQuickLook.processing}
                      fallback={ppgQuickLook.buttonText}
                    >
                      <div style="margin: 0 auto; display: flex; justify-content: center">
                        <Spinner size={20} />
                      </div>
                    </Show>
                  </Button>
                </td>
              </tr>
            </Show>

            <Show when={ppgQuickLook.resultUrl}>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td style="text-align: center">
                  <Button
                    onClick={() => {
                      ppgQuickLook.shareQuickLook();
                    }}
                  >
                    Share analysis...
                  </Button>
                </td>
              </tr>
            </Show>
          </>
        }
      />

      <Container column alignItems="center">
        <p>Note for PPG quality flags:</p>
        <table class="info-table">
          <tbody>
            <tr>
              <td>
                <strong>Data error:</strong>
              </td>
              <td>
                Completely bad signal, should not be used under any
                circumstances; this flag is marked automatically when computed{" "}
                <strong>SQI is &lt;= 3.0</strong>;<br />
                <span class="text-red">
                  manual changes to the PPG flag to <em>data error</em> will
                  cause all related data points (Seerlinq-computed data and
                  PPG-derived) to change their flag to <em>Wrong</em>
                </span>
                ;<br />
                PPG data with <em>data error</em> flag are{" "}
                <strong>not used in any automatic algorithms</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>OK:</strong>
              </td>
              <td>Signal is OK to use for analyses</td>
            </tr>
            <tr>
              <td>
                <strong>Low signal quality:</strong>
              </td>
              <td>
                Signal is not perfect, some weirdness happening, need manual
                check / preprocessing; this flag is marked automatically when
                computed <strong>SQI is between 3.0 and 5.0</strong>;<br />
                <span class="text-red">
                  manual changes to the PPG flag to <em>low signal quality</em>{" "}
                  will cause all related data points (Seerlinq-computed data and
                  PPG-derived) to change their flag to <em>Low PPG Quality</em>
                </span>
                ;<br />
                PPG data with <em>low signal quality</em> flag are used in
                automatic algorithms,{" "}
                <strong>
                  but the resulting Seerlinq-computed data instance (like DRI)
                  automatically inherits the <em>Low PPG Quality</em> flag.
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Artefacts:</strong>
              </td>
              <td>
                Signal contains a lot of artefacts, it is not OK to use as is
              </td>
            </tr>
            <tr>
              <td>
                <strong>Short:</strong>
              </td>
              <td>
                Signal is short, maybe recording by accident, will not be used
              </td>
            </tr>
            <tr>
              <td>
                <strong>Extra:</strong>
              </td>
              <td>
                Additional circumstance that may be important, data will be
                treated with care, please put additional info to the comment
                (e.g., gel nails, or pacemaker)
              </td>
            </tr>
            <tr>
              <td>
                <strong>Testing:</strong>
              </td>
              <td>
                Signal is used for testing, will not be used or included in any
                analysis
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </PatientSubpage>
  );
}
