import clsx from "clsx";
import { createMemo, For, JSX, Show } from "solid-js";
import { APIDataModel } from "../../APIDataModel";
import { DataModel } from "../../DataModel";
import { Container } from "../Container";
import styles from "../Table.module.css";
import { Legend } from "./Legend";
import { PaginationControl } from "./PaginationControl";
import { RowsSelector } from "./RowsSelector";

export interface Column<T> {
  header1: JSX.Element;
  header2?: JSX.Element;
  header3?: JSX.Element;
  cell: (item: T, index: number) => JSX.Element;
}

export function DataTable<T extends object>(props: {
  model: DataModel<T>;
  columns: Array<Column<any> | false | null | undefined>;
  legendExtra?: JSX.Element;
  rowsExtra?: JSX.Element;
  isRowSelected?: (item: any) => boolean;
  sticky?: boolean;
  class?: string;
  verticalAlign?: "top";
}) {
  const columns = createMemo(() =>
    props.columns.filter((col): col is Column<T> => !!col),
  );
  const hasAnyHeader2 = createMemo(() =>
    columns().some((col) => !!col.header2),
  );
  const hasAnyHeader3 = createMemo(() =>
    columns().some((col) => !!col.header3),
  );
  const loading = createMemo(
    () => props.model instanceof APIDataModel && props.model.loading,
  );

  return (
    <Container column alignItems="center">
      <Legend model={props.model} />

      {props.legendExtra}

      <RowsSelector model={props.model} />

      <div class={styles.tableContainer}>
        <table
          class={clsx(
            styles.table,
            props.sticky && styles.stickyTable,
            props.verticalAlign === "top" && styles.verticalAlignTop,
          )}
        >
          <thead>
            {/* header level 1 */}
            <tr>
              <For each={columns()}>{(col) => <th>{col.header1}</th>}</For>
            </tr>

            {/* header level 2 */}
            <Show when={hasAnyHeader2()}>
              <tr>
                <For each={columns()}>{(col) => <td>{col.header2}</td>}</For>
              </tr>
            </Show>

            {/* header level 3 */}
            <Show when={hasAnyHeader3()}>
              <tr>
                <For each={columns()}>{(col) => <td>{col.header3}</td>}</For>
              </tr>
            </Show>
          </thead>

          <tbody class={clsx(loading() && styles.loading)} inert={loading()}>
            {/* rows */}
            <For each={props.model.paginatedData}>
              {(item, index) => (
                <tr
                  class={clsx(props.isRowSelected?.(item) && styles.selected)}
                >
                  <For each={columns()}>
                    {(col) => <td>{col.cell(item, index())}</td>}
                  </For>
                </tr>
              )}
            </For>

            {props.rowsExtra}
          </tbody>
        </table>
      </div>

      <PaginationControl model={props.model} />
    </Container>
  );
}
