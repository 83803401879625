import { JSX, Show } from "solid-js";
import { App } from "../App";
import { Spinner } from "../common/uiKit/Spinner";
import { Tab } from "../common/uiKit/tabs/Tab";
import { Tabs } from "../common/uiKit/tabs/Tabs";

export function AdminPage(props: { app: App; children?: JSX.Element }) {
  if (!props.app.adminShit) {
    props.app.initAdmin();
  }

  return (
    <Show
      when={props.app.adminShit?.initialized}
      fallback={
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Spinner />
        </div>
      }
    >
      <h2>CLV management</h2>

      <div class="modal-container">
        <Tabs>
          <Tab href="clinics">Clinics</Tab>

          <Tab href="physicians">Physicians</Tab>

          <Tab href="teams">Monitoring teams</Tab>

          <Show when={props.app.seerlinqApi.userLevel === 4}>
            <Tab href="users">Users</Tab>
          </Show>

          <Show when={props.app.seerlinqApi.userLevel === 4}>
            <Tab href="limiters">Access limiters</Tab>
          </Show>
        </Tabs>

        <div class="modal-content active">{props.children}</div>
      </div>
    </Show>
  );
}
