import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class MedicalReports extends DataModel {
  constructor(data: any[], userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.sortBy = ["report_date"];
    this.sortByNames = ["Date"];
    this.dateFields = ["report_date"];
    this.filteringAttrs = ["report_type"];
    this.initSort = [true];
    this.editable = ["report_date", "report_type", "comment"];

    // access
    this.access = {
      editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: null,
    };
  }
}
