import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Logs extends DataModel {
  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.sortBy = ["audit_item_timestamp"];
    this.sortByNames = ["Timestamp"];
    this.dateTimeFields = ["audit_item_timestamp"];
    this.filteringAttrs = ["audit_item_type"];
    this.filteringListAttrs = ["tags"];
    this.initSort = [true];

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.none },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.none },
      historyMinLevel: null,
    };
  }
}
