import { createResource } from "solid-js";
import { ppgRelatedFlag } from "../../common/enumToText";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Tags } from "../../common/uiKit/Tags";
import { floatRounding } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { ComputedPlot } from "./ComputedPlot";

export function DRITable(props: { patient: Patient }) {
  const [model] = createResource(() => props.patient.fetchDri());

  return (
    <PatientSubpage title="DRIs" loading={model.loading}>
      <ComputedPlot
        dataModel={model()}
        plotTypeOptions={["diastolic reserve index"]}
      />

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Measured",
            header2: <HeaderSort attr="measurement_datetime" model={model()} />,
            cell: (item) => item.measurement_datetime,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="measurement_type"
                model={model()}
                name="Type"
              />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item) => floatRounding(item.measurement_value, 1),
          },
          {
            header1: "Unit",
            cell: (item) => item.measurement_unit,
          },
          {
            header1: "Seerlinq algorithm",
            header2: (
              <>
                <HeaderSelectFilter
                  attr="seerlinq_algorithm"
                  model={model()}
                  name="Algorithm"
                />
                <HeaderSelectFilter
                  attr="seerlinq_algorithm_version"
                  model={model()}
                  name="Version"
                />
              </>
            ),
            cell: (item) =>
              item.seerlinq_algorithm + " " + item.seerlinq_algorithm_version,
          },
          {
            header1: "Quality flag",
            header2: (
              <HeaderSelectFilter
                attr="seerlinq_measurement_quality_flag"
                model={model()}
                name="Flag"
                values={ppgRelatedFlag}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="seerlinq_measurement_quality_flag"
                index={index}
                model={model()}
                values={ppgRelatedFlag}
              />
            ),
          },
          {
            header1: "Tags",
            header2: (
              <HeaderSelectFilter attr="tags" model={model()} name="Tags" />
            ),
            cell: (item) => <Tags tags={item.tags} />,
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                multiline="autosize"
                width={80}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                model={model()}
                index={index}
                onConfirm={(editedField) => {
                  props.patient.updateItem("computed", item.uuid, editedField);
                }}
              />
            ),
          },
        ]}
      />
    </PatientSubpage>
  );
}
