import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { CellDateField } from "../cellFields/CellDateField";

export function CellDate(props: {
  model: DataModel;
  index: number;
  attr: string;
  type: "datetime-local" | "date";
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <>
      <Show when={!editing()}>{item()[props.attr]}</Show>

      <Show when={editing()}>
        <CellDateField
          model={props.model.editedField}
          attr={props.attr}
          type={props.type}
        />
      </Show>
    </>
  );
}
