import { Component, createMemo, createSignal, JSX, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import { DataModel } from "../../../DataModel";
import { Button } from "../../Button";

export function CellChoose(props: {
  model: DataModel;
  index: number;
  valueView: JSX.Element;
  valueEdit: JSX.Element;
  disabled?: boolean;
  modal: Component<{ onClose: () => void }>;
}) {
  const editing = createMemo(() => props.model.editingId === props.index);
  const [modalOpen, setModalOpen] = createSignal(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Show when={editing()}>
        <span>{props.valueEdit}</span>
        <br />
        <Show when={!props.disabled}>
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Choose
          </Button>
        </Show>
      </Show>

      <Show when={!editing()}>{props.valueView}</Show>

      <Show when={modalOpen()}>
        <Dynamic component={props.modal} onClose={closeModal} />
      </Show>
    </>
  );
}
