import { createSignal, Show } from "solid-js";
import { ListAccessLimiterResponse } from "../../api";
import { SimpleTable } from "../../common/uiKit/tables/SimpleTable";
import { AdminConsole } from "../AdminConsole";

export function LimitersAdmin(props: { admin: AdminConsole }) {
  const [limiters, setLimiters] = createSignal<ListAccessLimiterResponse>();

  const fetchTeams = async () => {
    const teams = await props.admin.api.getLimiters();
    setLimiters(teams);
  };

  fetchTeams();

  return (
    <Show when={limiters()}>
      <div id="modal-content">
        <h2>Access limiters</h2>
        <span>
          We have <strong>{limiters().results}</strong> access limiter(s) in the
          database.
        </span>
        <br />
        <SimpleTable
          rows={limiters().limiters}
          columns={[
            {
              header1: "Limiting table",
              cell: (item) => item.limiter_table,
            },
            {
              header1: "Limiter type",
              cell: (item) => item.limiter_type,
            },
            {
              header1: "Limit particular role",
              cell: (item) => item.limit_role,
            },
            {
              header1: "Limit particular username",
              cell: (item) => item.limit_username,
            },
            {
              header1: "Limit whole table?",
              cell: (item) => item.limit_whole_table,
            },
            {
              header1: "Allowed items",
              cell: (item) =>
                item.allowed_items ? item.allowed_items.join(", ") : null,
            },
            {
              header1: "Forbidden items",
              cell: (item) =>
                item.forbidden_items ? item.forbidden_items.join(", ") : null,
            },
          ]}
        />
        <br />
        <div>
          <span>Note on limiters:</span>
          <br />
          <p id="long-text">
            List of access limiters to limit access to various realms of
            Seerlinq-DB. These limiters apply for users of{" "}
            <strong>Level 2</strong>, and exists in three flavours:
            <em>level</em> (limit all users with Level 2), <em>role</em> (limit
            user of particular role), and <em>user</em> (limit specific user).
            They can work on any data table (diagnoses, medical data,
            medications, symptoms) and each limiter implements two lists:
            allowed and forbidden items. When chaining limiters (e.g.,
            particular user is limited by level, role, and user limiters at the
            same time), the priority goes user → role → level. If, e.g., user
            limiter allows one item, but role limiter forbids it, it will be
            returned.
          </p>
          <p>
            You can only list limiters here, for editing, deleting or adding,
            contact <strong>Admin</strong>
          </p>
        </div>
      </div>
    </Show>
  );
}
