import { createEffect, createSignal, Show } from "solid-js";
import styles from "./CellTextStatic.module.css";

export function CellTextStatic(props: { value: string; multiline?: boolean }) {
  const [expanded, setExpanded] = createSignal(false);
  const [overflows, setOverflows] = createSignal(false);
  let textElement: HTMLSpanElement;

  createEffect(() => {
    if (!props.multiline) {
      return;
    }

    setOverflows(
      !expanded() &&
        textElement &&
        textElement.clientWidth < textElement.scrollWidth,
    );

    props.value; // This just makes sure the effect tracks value changes
  });

  return (
    <div class={styles.root}>
      <div class={styles.textContainer}>
        <span
          classList={{
            [styles.multiline]: !!props.multiline,
            [styles.collapsed]: !!props.multiline && !expanded(),
          }}
          ref={textElement}
        >
          {props.value}
        </span>
      </div>

      <Show when={props.multiline && (overflows() || expanded())}>
        <div>
          <button
            type="button"
            onClick={() => {
              setExpanded(!expanded());
            }}
            class={styles.button}
          >
            {expanded() ? "\u25B2" : "\u25BC"}
          </button>
        </div>
      </Show>
    </div>
  );
}
