import { reactive } from "@vue/reactivity";
import { deviceHandovers, languages, tokenExpiries } from "../common/constants";
import { seerlinqStudies } from "../common/enumToText";
import { Button } from "../common/uiKit/Button";
import { RadioField } from "../common/uiKit/fields/RadioField";
import { SelectField } from "../common/uiKit/fields/SelectField";
import { TextField } from "../common/uiKit/fields/TextField";
import { FormRow } from "../common/uiKit/form/FormRow";
import { Patient } from "./Patient";

export function CreateUserForm(props: { patient: Patient }) {
  const data = reactive({
    token_expiry_timedelta: "P1D",
    preferred_language: "sk",
    new_patient_study: [0],
  });

  return (
    <div>
      <table class="invisible-table invisible-table-data-add">
        <FormRow
          label="Email (also used as username)"
          data={<TextField attr="email" model={data} />}
        />

        <FormRow
          label="Device handover (changes welcome email)"
          data={
            <RadioField
              attr="welcome_email_type"
              model={data}
              values={deviceHandovers}
            />
          }
        />

        <FormRow
          label="Patient study"
          data={
            <SelectField
              value={data["new_patient_study"][0]}
              onChange={(newValue) => {
                data["new_patient_study"] = [newValue];
              }}
              values={seerlinqStudies}
            />
          }
        />

        <FormRow
          label="Preferred language"
          data={
            <SelectField
              attr="preferred_language"
              model={data}
              values={languages}
            />
          }
        />

        <FormRow
          label="Finish registration link expiration"
          data={
            <SelectField
              attr="token_expiry_timedelta"
              model={data}
              values={tokenExpiries}
            />
          }
        />
      </table>

      <br />

      <Button
        onClick={() => {
          props.patient.createConnectedUser(data);
        }}
      >
        Create user
      </Button>
    </div>
  );
}
