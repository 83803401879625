import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { Button } from "../../Button";
import { CellTextField } from "../cellFields/CellTextField";
import styles from "./CellText.module.css";
import { CellTextStatic } from "./CellTextStatic";

export function CellText<T extends object>(props: {
  model: DataModel<T>;
  index: number;
  attr: string;
  onChange?: (editedField: object) => void;
  displayValue?: any;
  clickToEdit?: boolean;
  width?: number;
  type?: "text" | "number";
  disabled?: boolean;
  multiline?: boolean | "autosize";
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()) &&
      !props.disabled,
  );

  return (
    <div
      onClick={() => {
        if (props.clickToEdit) {
          props.model.startEditing(props.index);
        }
      }}
      class={styles.root}
      style={{ cursor: props.clickToEdit ? "pointer" : undefined }}
    >
      <Show when={!editing()}>
        <CellTextStatic
          value={
            props.displayValue !== undefined
              ? props.displayValue
              : item()[props.attr]
          }
          multiline={!!props.multiline}
        />
      </Show>

      <Show when={editing()}>
        <CellTextField
          attr={props.attr}
          model={props.model.editedField}
          type={props.type}
          width={props.width}
          multiline={props.multiline}
        />

        <Show when={props.clickToEdit}>
          {" "}
          <Button
            onClick={(event) => {
              event.stopImmediatePropagation();
              props.onChange?.(props.model.editedField);
              props.model.stopEditing();
            }}
          >
            {"\u2620"}
          </Button>{" "}
          <Button
            onClick={(event) => {
              event.stopImmediatePropagation();
              props.model.stopEditing();
            }}
          >
            X
          </Button>
        </Show>
      </Show>
    </div>
  );
}
