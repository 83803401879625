import {
  MonitoringTeamMemberResponse,
  MonitoringTeamResponse,
} from "../../api";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class MonitoringTeams extends DataModel {
  constructor(
    data: MonitoringTeamResponse[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.sortBy = ["display_name"];
    this.sortByNames = ["Name"];
    this.initSort = [false];
    this.canSwitchSortOrder = false;
    this.editable = ["display_name", "members", "realms"];
    this.filteringListAttrs = ["realms"];
    this.rowsPerPage = 10;

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.none },
      historyMinLevel: null,
    };
  }

  init() {
    this.data = this.data.map((item: MonitoringTeamResponse) => {
      const numMembers = item.members.length;
      const totalPatients = item.members.reduce(
        (acc, obj) => acc + obj.monitoring_patients.length,
        0,
      );
      return {
        ...item,
        ["numMembers"]: numMembers,
        ["totalPatients"]: totalPatients,
      };
    });
    super.init();
  }

  getEditedField(index: number) {
    const field = super.getEditedField(index);
    const members = field["members"];
    field["members"] = members.map((m: MonitoringTeamMemberResponse) => m.uuid);
    field["members_alert_kinds"] = members.map(
      (m: MonitoringTeamMemberResponse) => m.monitoring_alert_kinds,
    );
    return field;
  }
}
