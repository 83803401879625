import clsx from "clsx";
import { createMemo, For, JSX, Show } from "solid-js";
import styles from "../Table.module.css";

export interface Column<T> {
  header1: JSX.Element;
  header2?: JSX.Element;
  cell: (item: T, index: number) => JSX.Element;
}

export function SimpleTable<T>(props: {
  rows: T[];
  columns: Array<Column<T> | false | null | undefined>;
}) {
  const columns = createMemo(() =>
    props.columns.filter((col): col is Column<T> => !!col),
  );
  const hasAnyHeader2 = createMemo(() =>
    columns().some((col) => !!col.header2),
  );

  return (
    <div class={styles.tableContainer}>
      <table class={clsx(styles.table, styles.longTable)}>
        <thead>
          {/* header level 1 */}
          <tr>
            <For each={columns()}>{(col) => <th>{col.header1}</th>}</For>
          </tr>

          {/* header level 2 */}
          <Show when={hasAnyHeader2}>
            <tr>
              <For each={columns()}>{(col) => <th>{col.header2}</th>}</For>
            </tr>
          </Show>
        </thead>

        <tbody>
          {/* rows */}
          <For each={props.rows}>
            {(item, index) => (
              <tr>
                <For each={columns()}>
                  {(col) => <td>{col.cell(item, index())}</td>}
                </For>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </div>
  );
}
