import { createMemo } from "solid-js";
import { App } from "../App";
import { alertTypes } from "../common/enumToText";
import { Button } from "../common/uiKit/Button";
import { CellEditButtons } from "../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../common/uiKit/tables/cells/CellEnum";
import { DataTable } from "../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../common/uiKit/tables/headers/HeaderSort";
import { floatRounding } from "../common/utils/utils";
import { WaitingRoomConsole } from "./WaitingRoom";

export function MedicalWaitingRoom(props: {
  waitingRoom: WaitingRoomConsole;
  app: App;
}) {
  const model = createMemo(() => props.waitingRoom.medical);

  return (
    <div id="modal-content">
      <h3>Medical alerts</h3>

      <div>
        <label>
          Max alert age:{" "}
          <input
            type="number"
            style="width: 40px;"
            value={props.waitingRoom.maxAgeDays}
            onChange={async (event) => {
              props.waitingRoom.maxAgeDays = parseInt(event.target.value);
              await props.waitingRoom.init();
            }}
          ></input>{" "}
          days
        </label>
      </div>

      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Patient ID",
            header2: <HeaderSort attr="patient_id" model={model()} />,
            cell: (item) => {
              return (
                <strong>
                  <a
                    class="patient-link"
                    href={`/patient/${item.patient_id}/alerts`}
                  >
                    {item.patient_id}
                  </a>
                </strong>
              );
            },
          },
          {
            header1: "Timestamp",
            header2: <HeaderSort attr="alert_timestamp" model={model()} />,
            cell: (item) => item.alert_timestamp,
          },
          {
            header1: "Variable",
            header2: (
              <HeaderSelectFilter
                attr="alert_variable"
                model={model()}
                name="Variable"
              />
            ),
            cell: (item) => item.alert_variable,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="alert_type"
                model={model()}
                name="Type"
                values={alertTypes}
              />
            ),
            cell: (item) => alertTypes[item.alert_type],
          },
          {
            header1: "Value",
            cell: (item) => floatRounding(item.alert_value, 1),
          },
          {
            header1: "Reason",
            cell: (item) => item.alert_reason,
          },
          {
            header1: "Owner",
            header2: (
              <HeaderSelectFilter
                attr="ownerUuid"
                model={model()}
                name="Owner"
                values={props.waitingRoom.possibleOwners}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="ownerUuid"
                values={props.waitingRoom.possibleOwners}
                index={index}
                model={model()}
              />
            ),
          },
          {
            header1: "Mark as seen",
            cell: (item) => (
              <Button
                onClick={() => {
                  props.waitingRoom.markSeen(item.uuid);
                }}
              >
                Seen
              </Button>
            ),
          },
          {
            header1: "Change owner",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.waitingRoom.changeOwner(item.uuid, editedField);
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
