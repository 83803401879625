import { client } from "../api";
import { ApiConnector } from "../api/ApiConnector";
import { dateTimeISOString, parseDatetimeToLocal } from "./utils/utils";

export class DataAdd {
  variables = [];
  initFields = [];
  addingList: any = [];
  desc = "";
  dateTimeFields = [];
  bodyField = "";
  endpoint = "";
  defaultDateTime = "";
  defaultDateTimeName = "";
  defaultDateTimeFields = [];
  customDateTime = "";
  reloadToTab = "";
  requiredFields = [];

  diagOptions = {};
  requiredEitherOr = [];
  required = [];

  units = {
    // vitals
    "heart rate": ["bpm"],
    "blood pressure systolic": ["mmHg"],
    "blood pressure diastolic": ["mmHg"],
    SpO2: ["%"],
    // medical
    weight: ["kg"],
    "dry weight": ["kg"],
    temperature: ["C"],
    "respiratory rate": ["bpm"],
    // lab
    "NT-proBNP": ["pg/ml", "pmol/l"],
    BNP: ["pg/ml", "pmol/l"],
    creatinine: ["umol/l", "mg/dl"],
    urea: ["mmol/l"],
    hemoglobin: ["g/l", "g/dl"],
    // ECHO
    LVEF: ["%"],
    E: ["cm/s"],
    "TR velocity": ["m/s"],
    "TR gradient": ["mmHg"],
    LAVi: ["ml/m2"],
    "stroke volume on echo": ["ml"],
    RVSP: ["mmHg"],
    // RHC
    "RHC right atrial pressure mean": ["mmHg"],
    "RHC ventricular pressure systolic": ["mmHg"],
    "RHC ventricular pressure diastolic": ["mmHg"],
    "RHC pulmonary capillary wedge pressure": ["mmHg"],
    "RHC pulmonary artery pressure systolic": ["mmHg"],
    "RHC pulmonary artery pressure diastolic": ["mmHg"],
    "RHC pulmonary artery pressure mean": ["mmHg"],
    "RHC stroke volume - Fick": ["ml"],
    "RHC stroke volume - TD": ["ml"],
    "RHC stroke volume index": ["ml/m2"],
    "RHC cardiac output - Fick": ["l/min"],
    "RHC cardiac output - TD": ["l/min"],
    "RHC cardiac index - Fick": ["l/min/m2"],
    "RHC cardiac index - TD": ["l/min/m2"],
    "RHC pulmonary vascular resistance - Fick": ["WU"],
    "RHC pulmonary vascular resistance - TD": ["WU"],
    "RHC pulmonary vascular resistance index": ["WU/m2"],
    "RHC total pulmonary resistance": ["WU"],
    "RHC pulmonary arterial compliance": ["ml/mmHg"],
    "RHC transpulmonary gradient": ["mmHg"],
    "RHC diastolic transpulmonary gradient": ["mmHg"],
    "RHC mixed venous oxygen saturation": ["%"],
    "RHC arterial oxygen saturation": ["%"],
    "RHC systemic blood pressure systolic": ["mmHg"],
    "RHC systemic blood pressure diastolic": ["mmHg"],
  };

  constructor(
    public api: ApiConnector,
    public patient: any,
  ) {}

  async initEmpty() {}

  getUnits(variable: string, first: boolean = false) {
    if (!this.units.hasOwnProperty(variable)) {
      if (first) {
        return null;
      } else {
        return [];
      }
    }
    const units = this.units[variable];
    if (first) {
      return units[0];
    }
    return units;
  }

  removeItem(index: number) {
    this.addingList.splice(index, 1);
  }

  addItem() {
    this.addingList.push({});
  }

  setAllDatetimes(datetime, date: boolean = false) {
    if (!date) {
      var datetime: any = parseDatetimeToLocal(datetime);
    }
    for (const field of this.addingList) {
      for (const key of this.defaultDateTimeFields) {
        field[key] = datetime;
      }
    }
    if (datetime === this.defaultDateTime) {
      this.customDateTime = "";
    } else {
      this.customDateTime = datetime;
    }
  }

  customSanitize(field: object) {
    return field;
  }

  async postBulk() {
    // filter by required
    const filteredData =
      this.requiredFields.length === 0
        ? this.addingList
        : this.addingList.filter((item) =>
            this.requiredFields.every(
              (field) => item.hasOwnProperty(field) && item[field] !== null,
            ),
          );
    for (let i = 0; i < filteredData.length; i++) {
      filteredData[i].patient_id = this.patient.patientId;
      filteredData[i] = this.customSanitize(filteredData[i]);
      for (const key in filteredData[i]) {
        if (
          this.dateTimeFields.includes(key) &&
          filteredData[i].hasOwnProperty(key)
        ) {
          var datetime = filteredData[i][key];
          filteredData[i][key] = dateTimeISOString(datetime);
        }
      }
    }

    let body = {};
    body["number_of_datapoints"] = filteredData.length;
    body[this.bodyField] = filteredData;

    const response = await client.post({
      url: `/api/${this.api.apiVersion}/${this.endpoint}`,
      body,
    });

    if (response.data != null) {
      window.navigate(`/patient/${this.patient.patientId}/${this.reloadToTab}`);
      window.location.reload();
    }
  }
}
