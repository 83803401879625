import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { TagsField } from "../../fields/TagsField";
import { Tags } from "../../Tags";

export function CellTags(props: {
  attr: string;
  model: DataModel;
  index: number;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <>
      <Show when={!editing()}>
        <Tags tags={item()[props.attr]} />
      </Show>

      <Show when={editing()}>
        <TagsField attr={props.attr} model={props.model.editedField} />
      </Show>
    </>
  );
}
