import { reactive } from "@vue/reactivity";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddEvents } from "./AddEvents";

export function AddEventsTable(props: { patient: Patient }) {
  const model = reactive(new AddEvents(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      model={model}
      setAllDates="date"
      columns={[
        {
          header: "Event date",
          mandatory: true,
          cell: (item) => (
            <CellDateField
              attr="event_date"
              model={item}
              type="date"
              required
            />
          ),
        },
        {
          header: "Event type",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="event_type"
              model={item}
              values={model.eventTypes}
              listTitle="Select type"
              required
            />
          ),
        },
        {
          header: "Description",
          mandatory: true,
          cell: (item) => (
            <CellTextField
              attr="event_description"
              model={item}
              multiline="autosize"
              required
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField
              attr="event_comment"
              model={item}
              multiline="autosize"
            />
          ),
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
    />
  );
}
