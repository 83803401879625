import { Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { Button } from "../../Button";
import styles from "../../Table.module.css";

export function CellEditButtons(props: {
  model: DataModel;
  index: number;
  onConfirm: (editedField: any) => void;
  onCleanup?: () => void;
}) {
  return (
    <Show
      when={props.model.editingId === props.index}
      fallback={
        <Button
          disabled={!props.model.canEditItem(props.index)}
          data-tooltip={
            props.model.canEditItem(props.index)
              ? undefined
              : "You cannot pass. Go back to the Shadow!"
          }
          onClick={() => {
            props.model.startEditing(props.index);
          }}
          class={styles.rowButton}
        >
          Edit
        </Button>
      }
    >
      <Button
        onClick={() => {
          props.onConfirm(props.model.editedField);
          props.onCleanup?.();
          props.model.stopEditing();
        }}
        class={styles.rowButton}
      >
        Update
      </Button>{" "}
      <Button
        onClick={() => {
          props.model.stopEditing();
          props.onCleanup?.();
        }}
        class={styles.rowButton}
      >
        X
      </Button>
    </Show>
  );
}
