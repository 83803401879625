import { MedicalDataResponse } from "../../api";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class MedData extends DataModel {
  // constants
  notEditable = [
    "ePVS",
    "CKD-EPI",
    "CHA2DS2-VA",
    "BMI",
    "BSA",
    "congestion score",
  ];

  constructor(
    data: MedicalDataResponse[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.hasHistory = true;
    this.sortBy = ["measurement_datetime", "measurement_type"];
    this.sortByNames = ["Measured", "Type"];
    this.dateTimeFields = ["measurement_datetime", "created_at"];
    this.stringFields = ["measurement_type"];
    this.editable = ["measurement_datetime", "measurement_value", "comment"];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;

    // access
    this.access = {
      editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: 3,
    };
  }
}
