import { ReportType, UploadStatus } from "../../api";

export const reportTypes: Record<ReportType, string> = {
  [ReportType.medical_record]: "Medical record",
  [ReportType.hospitalization_record]: "Hospitalization record",
  [ReportType.ecg]: "ECG",
  [ReportType.labs]: "Labs",
  [ReportType.single_exam]: "Single exam",
};

export const uploadStatuses: Record<UploadStatus, string> = {
  [UploadStatus.pending]: "Pending",
  [UploadStatus.success]: "Success",
  [UploadStatus.failed]: "Failed",
  [UploadStatus.quarantined]: "Quarantined",
  [UploadStatus.expired]: "Expired",
};
