import { createMemo } from "solid-js";
import { TextField } from "../../fields/TextField";

export function CellTextField(props: {
  model: Record<string, any>;
  attr: string;
  type?: "text" | "number";
  width?: number;
  disabled?: boolean;
  multiline?: boolean | "autosize";
  required?: boolean;
}) {
  const width = createMemo(() => {
    if (props.width != null) {
      return props.width;
    } else if (props.type === "number") {
      return 50;
    } else {
      return undefined;
    }
  });

  return (
    <TextField
      type={props.type}
      model={props.model}
      attr={props.attr}
      width={width()}
      disabled={props.disabled}
      multiline={props.multiline}
      required={props.required}
    />
  );
}
