import { apiPostPhysicians } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";

export class AddPhysician extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      surname: null,
      given_name: null,
      titles: null,
      realm: null,
      email: null,
      phone: [],
      connected_user_uuid: null,
      clinics_uuids: [],
      patient_ids: [],
    };
  }

  async post(phones: string[] = []) {
    this.addingList["phone"] = phones;

    const response = await apiPostPhysicians({ body: this.addingList });

    if (response.data != null) {
      window.navigate("/admin/physicians");
      location.reload();
    }
  }
}
