import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellSelectField } from "../../common/uiKit/tables/cellFields/CellSelectField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { CellRemoveButton } from "../../common/uiKit/tables/cells/CellRemoveButton";
import { Patient } from "../Patient";
import { AddThresholds } from "./AddThresholds";

export function AddThresholdsTable(props: { patient: Patient }) {
  const model = reactive(new AddThresholds(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      model={model}
      infoExtra={
        <>
          <div class="text-red">
            Only one threshold per patient, per variable, per trigger, per type,
            and per threshold settings can exist. When you want to update a
            specific threshold, do it in the table below.
          </div>
          <div>
            <strong>Value-based thresholds</strong>: Alerts are triggered when
            the variable is above/below (based on the threshold type) at least
            <em>X</em> times out of <em>Y</em>.<br />
            <strong>Change-based thresholds</strong>: Alerts are triggered when
            the variable increased/decreased (based on change type) over{" "}
            <em>X</em> day(s) (while taking into an account <em>N</em> maximum
            measurements). When defining threshold for <strong>decrease</strong>
            , the value must be negative; if you insert positive number, it is
            automatically treated as negative.
          </div>
          <br />
        </>
      }
      columns={[
        {
          header: "Table:",
          cell: (item) =>
            model.threshTables[
              model.availableThresholds[item.threshold_variable]
            ],
        },
        {
          header: "Variable",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="threshold_variable"
              model={item}
              values={Object.keys(model.availableThresholds)}
              listTitle="Select variable"
              required
            />
          ),
        },
        {
          header: "Type",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="threshold_type"
              model={item}
              values={model.threshTypes}
              listTitle="Select type"
              disabled={item.threshold_trigger === "change_based"}
              required
            />
          ),
        },
        {
          header: "Trigger",
          mandatory: true,
          cell: (item) => (
            <CellSelectField
              attr="threshold_trigger"
              model={item}
              values={model.threshTriggers}
              listTitle="Select trigger"
              onChange={() => {
                model.initSettings(item);
              }}
              required
            />
          ),
        },
        {
          header: "Settings",
          mandatory: true,
          cell: (item) => (
            <>
              <Show when={!item.threshold_settings}>--</Show>
              <Show
                when={
                  item.threshold_settings &&
                  item.threshold_settings.threshold_trigger === "value_based"
                }
              >
                <TextField
                  width={25}
                  type="number"
                  value={item.threshold_settings.at_least}
                  onChange={(newValue) => {
                    item.threshold_settings.at_least = newValue;
                  }}
                  required
                />
                <span> out of </span>
                <TextField
                  width={25}
                  type="number"
                  value={item.threshold_settings.out_of}
                  onChange={(newValue) => {
                    item.threshold_settings.out_of = newValue;
                  }}
                  required
                />
              </Show>
              <Show
                when={
                  item.threshold_settings &&
                  item.threshold_settings.threshold_trigger === "change_based"
                }
              >
                <SelectField
                  value={item.threshold_settings.change_type}
                  onChange={(newValue) => {
                    item.threshold_settings.change_type = newValue;
                    item.threshold_type = model.changeToThreshType[newValue];
                  }}
                  values={model.changeTypes}
                  listTitle="Select change"
                  required
                />
                <span> over </span>
                <TextField
                  width={25}
                  type="number"
                  value={item.threshold_settings.look_back_days}
                  onChange={(newValue) => {
                    item.threshold_settings.look_back_days = newValue;
                  }}
                  required
                />
                <span> day(s) (max </span>
                <TextField
                  width={25}
                  type="number"
                  value={item.threshold_settings.maximum_measurements}
                  onChange={(newValue) => {
                    item.threshold_settings.maximum_measurements = newValue;
                  }}
                  required
                />
                <span> measurements)</span>
              </Show>
            </>
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => (
            <CellTextField
              attr="threshold_value"
              model={item}
              width={50}
              required
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField
              attr="threshold_comment"
              model={item}
              multiline="autosize"
            />
          ),
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={model} />
          ),
        },
      ]}
    />
  );
}
