import { createResource, createSignal, Show } from "solid-js";
import { UploadStatus } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddReportForm } from "./AddReportForm";
import { reportTypes, uploadStatuses } from "./enums";

export function ReportsPage(props: { patient: Patient; api: ApiConnector }) {
  const [model] = createResource(() => props.patient.fetchMedicalReports());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Reports" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add report
      </Button>

      <Show when={adding()}>
        <AddReportForm patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Date",
            header2: <HeaderSort attr="report_date" model={model()} />,
            cell: (item, index) => (
              <CellDate
                attr="report_date"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="report_type"
                model={model()}
                name="Type"
                values={reportTypes}
              />
            ),
            cell: (item, index) => (
              <CellEnum
                attr="report_type"
                index={index}
                model={model()}
                values={reportTypes}
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Status",
            cell: (item) => (
              <span
                style={{
                  color:
                    item.upload_status === UploadStatus.success
                      ? "green"
                      : item.upload_status === UploadStatus.pending
                        ? undefined
                        : "red",
                }}
              >
                {uploadStatuses[item.upload_status]}
              </span>
            ),
          },
          {
            header1: "Filename",
            cell: (item) => item.original_name,
          },
          {
            header1: "Download",
            cell: (item) => (
              <Show when={item.upload_status === UploadStatus.success}>
                <a
                  class="button"
                  href={`${props.api.versionedRoute()}/medical-reports/download/${item.uuid}`}
                  target="_blank"
                >
                  💾 Download
                </a>
              </Show>
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem(
                    "medical-reports",
                    item.uuid,
                    editedField,
                  );
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <CellDeleteButton
                index={index}
                model={model()}
                onConfirm={() =>
                  props.patient.deleteItem("medical-reports", item.uuid)
                }
              />
            ),
          },
        ]}
      />
    </PatientSubpage>
  );
}
