import { createSignal, createUniqueId, Show } from "solid-js";
import styles from "./FileUploadDropZone.module.css";
import { Spacer } from "./Spacer";

export function FileUploadDropZone(props: {
  onSelect?: (file: File) => void;
  accept?: string[];
}) {
  const inputId = createUniqueId();
  const [selectedFile, setSelectedFile] = createSignal<File>();
  const [draggedOn, setDraggedOn] = createSignal(false);

  const selectFile = (file: File) => {
    setSelectedFile(file);
    props.onSelect?.(file);
  };

  return (
    <div
      classList={{ [styles.root]: true, [styles.draggedOn]: draggedOn() }}
      onDrop={(event) => {
        event.preventDefault();
        setDraggedOn(false);

        const files: File[] = [];

        if (event.dataTransfer.items) {
          for (const item of event.dataTransfer.items) {
            if (item.kind === "file") {
              files.push(item.getAsFile());
            }
          }
        } else {
          files.push(...event.dataTransfer.files);
        }

        if (files.length === 0) {
          return;
        }

        if (files.length > 1) {
          alert("You can only upload one file at a time");
          return;
        }

        const file = files[0];

        if (
          props.accept &&
          props.accept.every(
            (ext) => !file.name.toLowerCase().endsWith(ext.toLowerCase()),
          )
        ) {
          alert(
            `Invalid file type. Only the following types are accepted: ${props.accept.join(", ")}`,
          );
          return;
        }

        // TODO: check file type

        selectFile(file);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setDraggedOn(true);
      }}
      onDragLeave={(event) => {
        if (
          !(event.relatedTarget instanceof HTMLElement) ||
          !event.currentTarget.contains(event.relatedTarget)
        ) {
          setDraggedOn(false);
        }
      }}
    >
      <Show when={!selectedFile()}>
        <div>You can drag and drop a file here</div>
      </Show>

      <Show when={selectedFile()}>
        <div>
          Selected: <strong>{selectedFile().name}</strong>
        </div>
      </Show>

      <Spacer height={12} />

      <label for={inputId} class={styles.label}>
        Select
      </label>

      <input
        id={inputId}
        class={styles.input}
        type="file"
        onChange={(event) => {
          if (event.target.files.length > 0) {
            selectFile(event.target.files[0]);
          }
        }}
        accept={props.accept ? props.accept.join(",") : undefined}
      />
    </div>
  );
}
