import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { SelectMultipleField } from "../../fields/SelectMultipleField";

export function CellEnumMultiple<
  T extends object,
  V extends string | number,
>(props: {
  model: DataModel<T>;
  attr: string;
  index: number;
  /** Either an enum or just an array of possible values. */
  values: Record<V, string> | V[];
  onChange?: (editedField: object) => void;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const value = createMemo(() => item()[props.attr]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <Show
      when={editing()}
      fallback={
        <div style={{ "max-width": "300px" }}>
          {value().map((val) => (
            <span class="tag">
              {Array.isArray(props.values) ? val : props.values[val]}
            </span>
          ))}
        </div>
      }
    >
      <SelectMultipleField
        attr={props.attr}
        model={props.model.editedField}
        values={props.values}
      />
    </Show>
  );
}
