import { apiPostClinics } from "../../api";
import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";

export class AddClinic extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      name: null,
      ambulance: null,
      address: null,
      country: null,
      realm: null,
      phone: null,
      email: null,
      physicians_uuids: [],
    };
  }

  async post() {
    const response = await apiPostClinics({ body: this.addingList });
    if (response.data != null) {
      window.navigate("/admin/clinics");
      location.reload();
    }
  }
}
