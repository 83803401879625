import { DefaultThreshold } from "../../api";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Thresholds extends DataModel {
  defaultThresholds: any;
  // constants
  threshTables = {
    medicaldata: "Medical data (Manual)",
    symptoms: "Symptoms (Manual)",
    ppg_derived: "PPG-derived (from Oximeter)",
    sq_computed: "SQ-computed (our algorithm)",
  };
  threshTriggers = { value_based: "Value-based", change_based: "Change-based" };
  defaultTables = {
    "diastolic reserve index (median)": "sq_computed",
    "SpO2: mean": "ppg_derived",
    "HR: mean": "ppg_derived",
    "shortness of breath": "symptoms",
    "fatigue score": "symptoms",
  };

  constructor(
    data,
    defaultThresholds: DefaultThreshold[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.defaultThresholds = defaultThresholds;
    this.stringFields = ["threshold_variable"];
    this.filteringAttrs = [
      "threshold_type",
      "threshold_trigger",
      "threshold_variable_table",
    ];
    this.editable = ["threshold_value", "threshold_comment"];

    // access
    this.access = {
      editRules: { 2: AccessRules.none, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: null,
    };
  }

  uniqueKey(item: any) {
    return `${item["threshold_variable"]}|${item["threshold_type"]}|${item["threshold_trigger"]}|${JSON.stringify(item["threshold_settings"])}`;
  }

  init() {
    this.defaultThresholds = this.defaultThresholds.map((item) => {
      return {
        ...item,
        ["isDefault"]: true,
        ["threshold_comment"]: "default threshold",
        ["threshold_variable_table"]:
          this.defaultTables[item.threshold_variable] || "medicaldata",
      };
    });
    this.data = this.data.map((item) => {
      return { ...item, ["isDefault"]: false };
    });
    const existingThreshs = new Set(
      this.data.map((item) => this.uniqueKey(item)),
    );
    const defaultsFilt = this.defaultThresholds.filter(
      (item) => !existingThreshs.has(this.uniqueKey(item)),
    );
    this.data = this.data.concat(defaultsFilt);
    super.init();
  }

  sortAndFilterCustom(
    sortBy: string[],
    sortDesc: boolean[],
    filter: object,
  ): any[] {
    const sorted = this.sortThresholds(this.data);

    if (this.filteringAttrs.length > 0 || filter) {
      var filtered = sorted.filter((item) => {
        return Object.keys(filter).every((key) => {
          if (Array.isArray(item[key])) {
            return item[key].some((element) => filter[key].includes(element));
          } else {
            if (Array.isArray(filter[key])) {
              return filter[key].includes(item[key]);
            } else if (typeof filter[key] === "boolean") {
              return !(item[key] === 0 && filter[key]);
            }
          }
        });
      });
    } else {
      var filtered = sorted.map((item) => item);
    }

    return filtered;
  }

  sortThresholds(thresholds: any[]) {
    const tableOrder = [
      "medicaldata",
      "symptoms",
      "sq_computed",
      "ppg_derived",
    ];
    const triggerOrder = ["value_based", "change_based"];
    const typeOrder = ["high", "low"];

    return thresholds.sort((a, b) => {
      // 0. patient-specific first
      if (a.isDefault !== b.isDefault) {
        return a.isDefault - b.isDefault;
      }
      // 1. order by table
      const tableA = tableOrder.indexOf(a.threshold_variable_table);
      const tableB = tableOrder.indexOf(b.threshold_variable_table);
      if (tableA !== tableB) return tableA - tableB;
      // 2. order by name
      if (a.threshold_variable !== b.threshold_variable) {
        return a.threshold_variable.localeCompare(b.threshold_variable);
      }
      // 3. order by trigger
      const orderA = triggerOrder.indexOf(a.threshold_trigger);
      const orderB = triggerOrder.indexOf(b.threshold_trigger);
      if (orderA !== orderB) return orderA - orderB;
      // 4. order by type
      const typeA = typeOrder.indexOf(a.threshold_type);
      const typeB = typeOrder.indexOf(b.threshold_type);
      if (typeA !== typeB) return typeA - typeB;

      // 5. order by value
      if (
        a.threshold_type === typeOrder[0] &&
        b.threshold_type === typeOrder[0]
      ) {
        if (a.threshold_value !== b.threshold_value)
          return b.threshold_value - a.threshold_value;
      }
      if (
        a.threshold_type === typeOrder[1] &&
        b.threshold_type === typeOrder[1]
      ) {
        if (a.threshold_value !== b.threshold_value)
          return a.threshold_value - b.threshold_value;
      }
      // 6. order by settings
      if (
        a.threshold_trigger === triggerOrder[0] &&
        b.threshold_trigger === triggerOrder[0]
      ) {
        return a.threshold_settings.at_least - b.threshold_settings.at_least;
      }
      if (
        a.threshold_trigger === triggerOrder[1] &&
        b.threshold_trigger === triggerOrder[1]
      ) {
        return (
          a.threshold_settings.look_back_days -
          b.threshold_settings.look_back_days
        );
      }
    });
  }

  settingsString(thresh: any) {
    const settings = thresh.threshold_settings;
    if (thresh.threshold_trigger === "value_based") {
      return `${settings.at_least} / ${settings.out_of}`;
    } else {
      var agg = settings.aggregation;
      agg = agg === "min" ? "max" : agg;
      return `${agg} ${settings.change_type} over ${settings.look_back_days} day(s)`;
    }
  }
}
