import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../DataModel";
import { Button } from "../../Button";
import { CellSelectField } from "../cellFields/CellSelectField";

export function CellEnum<T extends object, V extends string | number>(props: {
  model: DataModel<T>;
  attr: string;
  index: number;
  /** Either an enum or just an array of possible values. */
  values: Record<V, string> | V[];
  clickToEdit?: boolean;
  onChange?: (editedField: object) => void;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const value = createMemo(() => item()[props.attr]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <>
      <Show when={!editing()}>
        <span
          onClick={() => {
            if (props.clickToEdit) {
              props.model.startEditing(props.index);
            }
          }}
          style={{ cursor: props.clickToEdit ? "pointer" : undefined }}
        >
          {Array.isArray(props.values) ? value() : props.values[value()]}
        </span>
      </Show>

      <Show when={editing()}>
        <CellSelectField
          model={props.model.editedField}
          attr={props.attr}
          values={props.values}
        />

        <Show when={props.clickToEdit}>
          {" "}
          <Button
            onClick={() => {
              props.onChange?.(props.model.editedField);
              props.model.stopEditing();
            }}
          >
            {"\u2620"}
          </Button>{" "}
          <Button onClick={() => props.model.stopEditing()}>X</Button>
        </Show>
      </Show>
    </>
  );
}
