import { JSX, Show } from "solid-js";
import { Button } from "../Button";
import { Spinner } from "../Spinner";

export function QuickLookButton(props: {
  children?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  processing?: boolean;
}) {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      class="heartcore-button-small"
    >
      <Show when={props.processing}>
        <Spinner size={20} />
      </Show>
      <Show when={!props.processing}>{props.children}</Show>
    </Button>
  );
}
