import { JSX, onMount, Show } from "solid-js";
import { ApiConnector } from "../api/ApiConnector";
import { App } from "../App";
import { Spinner } from "../common/uiKit/Spinner";
import { Tab } from "../common/uiKit/tabs/Tab";
import { Tabs } from "../common/uiKit/tabs/Tabs";
import { createStatic } from "../common/utils/initSolid";
import { PatientDetails } from "./PatientDetails";

export function PatientPage(props: {
  id: number;
  api: ApiConnector;
  app: App;
  children?: JSX.Element;
}) {
  const patient = createStatic(props.app.initPatient(props.id));

  onMount(async () => {
    await patient().init();
  });

  return (
    <div>
      <h2>Patient #{props.id}</h2>

      <Show when={!patient().loaded}>
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Spinner />
        </div>
      </Show>

      <Show when={patient().loaded}>
        <PatientDetails
          patient={patient()}
          api={patient().api}
          app={props.app}
        />

        <div class="modal-container">
          <Tabs>
            <Show when={props.app.seerlinqApi.userLevel >= 3}>
              <Tab href="alerts">Alerts</Tab>
            </Show>

            <Tab href="dri">DRI</Tab>

            <Tab href="vitals">Vitals</Tab>

            <Tab href="symptoms">Symptoms</Tab>

            <Show when={props.app.seerlinqApi.userLevel >= 3}>
              <Tab href="events">Events</Tab>
            </Show>

            <Show when={props.app.seerlinqApi.userLevel >= 3}>
              <Tab href="thresholds">Thresholds</Tab>
            </Show>

            <Tab href="medications">Medications</Tab>

            <Tab href="diags">Comorbidities</Tab>

            <Tab href="labs">Labs</Tab>

            <Tab href="exams">Exams</Tab>

            <Tab href="basics">Basic medical data</Tab>

            <Show when={props.app.seerlinqApi.userLevel >= 3}>
              <Tab href="ppg">PPG data</Tab>
            </Show>

            <Tab href="any">Add any data</Tab>

            <Show when={props.app.seerlinqApi.userLevel >= 3}>
              <Tab href="healthpro">HealthPro reporting</Tab>
            </Show>

            <Tab href="reports">Medical reports</Tab>
            <Show when={props.app.seerlinqApi.userLevel === 4}>
              <Tab href="logs">Logs</Tab>
            </Show>
          </Tabs>

          <div class="modal-content active">{props.children}</div>
        </div>
      </Show>
    </div>
  );
}
