import clsx from "clsx";
import { debounce } from "lodash";
import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../api/ApiConnector";
import { App } from "../App";
import {
  patientStates,
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../common/enumToText";
import { Spinner } from "../common/uiKit/Spinner";
import { CellEnum } from "../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../common/uiKit/tables/cells/CellText";
import { DataTable } from "../common/uiKit/tables/DataTable";
import { QuickLookButton } from "../common/uiKit/tables/QuickLookButton";
import { consentOk, isPatientDto } from "../common/utils/dataUtils";

export function PatientsTable(props: { api: ApiConnector; app: App }) {
  props.app.getPatients();

  const model = createMemo(() => props.app.patients);

  const idFilterChangedDebounced = debounce((value: string) => {
    model().queryFilter = value;
    model().refetch();
  }, 100);

  const initializing = createMemo(() => !model() || !model().initialized);

  return (
    <>
      <Show when={initializing()}>
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Spinner />
        </div>
      </Show>

      <Show when={!initializing()}>
        <h2>Patients</h2>
        <span>
          We have <strong>{model().totalItems}</strong> patient(s) in the
          database.
        </span>

        <br />
        <br />

        <DataTable
          model={model()}
          columns={[
            {
              header1: "Patient HF study ID",
              header2: (
                <input
                  type="text"
                  placeholder="ID filter..."
                  value={model().queryFilter}
                  onInput={(event) => {
                    idFilterChangedDebounced(event.target.value);
                  }}
                  style="width: 75px"
                />
              ),
              cell: (item) => {
                const disabled = !consentOk(item) && props.api.userLevel !== 4;

                return (
                  <strong>
                    <a
                      class={clsx("patient-link", disabled && "disabled-link")}
                      href={!disabled ? `/patient/${item.patient_id}` : "#"}
                    >
                      {item.patient_id}
                    </a>
                  </strong>
                );
              },
            },
            props.api.userLevel >= 3 && {
              header1: "Risk flag",
              header2: "(Click to change)",
              cell: (item, index) => (
                <CellEnum
                  model={model()}
                  attr="patient_state"
                  index={index}
                  values={patientStates}
                  onChange={(editedField) => {
                    model().updatePatient(item.patient_id, editedField);
                  }}
                  clickToEdit
                />
              ),
            },
            {
              header1: "Added",
              cell: (item) => item.created_at,
            },
            {
              header1: "Date of birth",
              cell: (item) => item.date_of_birth,
            },
            {
              header1: "Realm",
              cell: (item) => seerlinqRealms[item.realm],
            },
            {
              header1: "Seerlinq studies",
              cell: (item) => seerlinqStudies[item.patient_study],
            },
            {
              header1: "Status",
              cell: (item) => patientStatuses[item.patient_status],
            },
            props.api.userLevel >= 3 && {
              header1: "Monitoring note",
              header2: "(Click to change)",
              cell: (item, index) => (
                <CellText
                  model={model()}
                  attr="monitoring_note"
                  index={index}
                  onChange={(editedField) => {
                    model().updatePatient(item.patient_id, editedField);
                  }}
                  clickToEdit
                  multiline="autosize"
                />
              ),
            },
            props.api.userLevel >= 3 && {
              header1: "Last PPG",
              cell: (item) => item.last_ppg,
            },
            props.api.userLevel >= 3 && {
              header1: "HeartCore \u2665",
              cell: (item) => (
                <Show when={isPatientDto(item)}>
                  <QuickLookButton
                    disabled={item.patHeartCore.processing}
                    processing={item.patHeartCore.processing}
                    onClick={() => {
                      if (item.patHeartCore.resultUrl) {
                        window.open(item.patHeartCore.resultUrl, "_blank");
                      } else {
                        item.patHeartCore.submit([item.patient_id]);
                      }
                    }}
                  >
                    <Show
                      when={item.patHeartCore.resultUrl}
                      fallback="DRI HeartCore"
                    >
                      View HeartCore
                    </Show>
                  </QuickLookButton>
                </Show>
              ),
            },
          ]}
        />
      </Show>
    </>
  );
}
