import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Symptoms extends DataModel {
  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.hasHistory = true;
    this.sortBy = ["symptom_date", "symptom_name"];
    this.sortByNames = ["Started", "Symptom"];
    this.dateFields = ["symptom_date", "symptom_change_date"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["symptom_name"];
    this.editable = [
      "symptom_date",
      "symptom_name",
      "symptom_value",
      "symptom_change_in_last_six_m",
      "symptom_change_date",
      "symptom_value_before",
      "comment",
    ];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;

    // access
    this.access = {
      editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: 3,
    };
  }
}
