import { createSignal, Show } from "solid-js";
import styles from "../../common/uiKit/Table.module.css";
import { CellChoose } from "../../common/uiKit/tables/cells/CellChoose";
import { AdminConsole } from "../AdminConsole";
import { MembersModal } from "./MembersModal";
import { MonitoringTeams } from "./MonitoringTeams";

export function CellMembers(props: {
  item: any;
  index: number;
  model: MonitoringTeams;
  admin: AdminConsole;
}) {
  const [dialogOpen, setDialogOpen] = createSignal(false);

  return (
    <>
      <CellChoose
        index={props.index}
        model={props.model}
        valueEdit={`${props.model.editedField?.["members"]?.length ?? 0} chosen`}
        valueView={
          <button
            type="button"
            class={styles.rowButton}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {props.item.numMembers} members
          </button>
        }
        modal={({ onClose }) => (
          <MembersModal
            editedTeam={props.model.editedField}
            users={props.admin.createUsers()}
            onClose={onClose}
          />
        )}
      />

      <Show when={dialogOpen()}>
        <MembersModal
          editedTeam={props.model.getEditedField(props.index)}
          users={props.admin.createUsers()}
          onClose={() => {
            setDialogOpen(false);
          }}
          readOnly
        />
      </Show>
    </>
  );
}
