import { toRaw } from "@vue/reactivity";

export function parseDatetimeToLocal(datetime: Date) {
  datetime = new Date(datetime);
  const localDatetime = new Date(
    datetime.getTime() - datetime.getTimezoneOffset() * 60000,
  );
  return localDatetime.toISOString().slice(0, 19).replace("T", " ");
}

export function dateTimeISOString(datetimeStr?: string) {
  var datetime = datetimeStr == null ? new Date() : new Date(datetimeStr);
  return datetime.toISOString();
}

export function dateTimeOrNull(
  dateString: string,
  isDateTime: boolean = true,
  locale: string = "sk-SK",
) {
  if (dateString === null || dateString === undefined || dateString === "") {
    return null;
  }
  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    return Intl.DateTimeFormat(
      locale,
      isDateTime
        ? {
            dateStyle: "short",
            timeStyle: "medium",
          }
        : {},
    ).format(date);
  } else {
    return null;
  }
}

export function floatRounding(value: number | string, decimals: number = 1) {
  if (typeof value === "number") {
    return value.toFixed(decimals);
  }
  return value;
}

export function getObjectFromArray(options: string[] | number[]) {
  return options.reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});
}

/** Useful for inspecting reactive objects (otherwise we just see Proxy on the console). */
export function toRawDeep<T>(obj: T): T {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(toRawDeep) as T;
  }

  const rawObj = toRaw(obj) as T;
  const newObj = {} as T;

  for (const key in rawObj) {
    if (rawObj.hasOwnProperty(key)) {
      newObj[key] = toRawDeep(rawObj[key]);
    }
  }

  return newObj;
}

/** Returns the value as an array. If the value is null/undefined, it returns null/undefined instead. */
export function maybeAsArray<T>(val: T): T extends null | undefined ? T : T[] {
  return (val == null ? val : [val]) as any;
}
