import { createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddEventsTable } from "./AddEventsTable";

export function EventsTable(props: { patient: Patient; api: ApiConnector }) {
  const model = props.patient.getEvents();
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Events" loading={!model}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add events
      </Button>

      <Show when={adding()}>
        <AddEventsTable patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model}
        columns={[
          {
            header1: "Date",
            header2: <HeaderSort model={model} attr="event_date" />,
            cell: (item) => item.event_date,
          },
          {
            header1: "Timestamp",
            header2: <HeaderSort model={model} attr="event_timestamp" />,
            cell: (item) => item.event_timestamp,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="event_type"
                model={model}
                name="Type"
                values={model.eventTypes}
              />
            ),
            cell: (item) => model.eventTypes[item.event_type],
          },
          {
            header1: "Description",
            cell: (item, index) => (
              <CellText
                attr="event_description"
                index={index}
                model={model}
                width={120}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="event_comment"
                index={index}
                model={model}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                model={model}
                index={index}
                onConfirm={(editedField) => {
                  props.patient.updateItem("events", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <CellDeleteButton
                index={index}
                model={model}
                onConfirm={() => props.patient.deleteItem("events", item.uuid)}
              />
            ),
          },
        ]}
      />
    </PatientSubpage>
  );
}
