import { createMemo, JSX, Show } from "solid-js";
import { Button } from "../Button";
import { Container } from "../Container";
import { Dialog } from "../Dialog";
import styles from "./ChoosingModal.module.css";
import { ChoosingModalModel } from "./ChoosingModalModel";

export function ChoosingModal(props: {
  modal: ChoosingModalModel;
  title: JSX.Element;
  onConfirm: (params: { selected: any[] }) => void;
  onClose: () => void;
  table: JSX.Element;
  readOnly?: boolean;
}) {
  const modal = createMemo(() => props.modal);

  const closeModal = () => {
    props.onClose();
  };

  return (
    <Dialog
      open={!!modal()}
      class={styles.dialog}
      onOpenChange={(open) => {
        if (!open) {
          closeModal();
        }
      }}
    >
      <Button class={styles.closeButton} onClick={closeModal}>
        X
      </Button>

      <Container column alignItems="center">
        <h3>{props.title}</h3>

        {props.table}

        <Show when={!props.readOnly}>
          <br />

          <Button
            disabled={
              modal().selectionMode === "single" &&
              modal().selected.length === 0
            }
            onClick={() => {
              props.onConfirm({ selected: modal().selected });
              closeModal();
            }}
          >
            Confirm
          </Button>
        </Show>
      </Container>
    </Dialog>
  );
}
