import {
  apiGetPatients,
  apiGetPatientsMinimal,
  apiPutPatientsPatientId,
  MinimalPatientResponse,
  PatientBaseSchema,
} from "../api";
import { ApiConnector, PatientDto } from "../api/ApiConnector";
import { APIDataModel, PageFetchResult } from "../common/APIDataModel";
import { filterByQuery } from "../common/DataModel";
import { SeerlinqDRIHeartCore } from "../common/quickLookAnalyses";
import {
  canApprovePaperConsent,
  canRevokePaperConsent,
  consentOk,
} from "../common/utils/dataUtils";

export class Patients extends APIDataModel<
  PatientDto | MinimalPatientResponse
> {
  private dataMinimal: MinimalPatientResponse[] = [];

  constructor(
    public api: ApiConnector,
    private heartCoreInit: boolean,
  ) {
    super(api);
    this.sortBy = ["created_at"];
    this.sortByNames = ["Added"];
    this.dateTimeFields = ["created_at", "last_ppg"];
    this.dateFields = ["date_of_birth"];
    this.initSort = [true];
    this.editable = ["patient_state", "monitoring_note"];
    this.queryKey = "patient_id";
  }

  async init() {
    await Promise.all([super.init(), this.fetchMinimal()]);
  }

  private async fetchMinimal() {
    const res = await apiGetPatientsMinimal();
    this.dataMinimal = res.data.patients;
  }

  // other stuff
  async updatePatient(patId: number, field: object) {
    await apiPutPatientsPatientId({
      path: { patient_id: patId },
      body: field,
    });
    window.location.reload();
  }

  canApprovePaperConsent(patient: PatientBaseSchema) {
    return canApprovePaperConsent(patient);
  }

  canRevokePaperConsent(patient: PatientBaseSchema) {
    return canRevokePaperConsent(patient);
  }

  consentOk(patient: PatientBaseSchema) {
    return consentOk(patient);
  }

  disabledPatLink(patient: PatientBaseSchema) {
    return !this.consentOk(patient) && this.api.userLevel !== 4;
  }

  protected async fetchPage(
    page: number,
    rowsPerPage: number,
  ): Promise<PageFetchResult<PatientDto | MinimalPatientResponse>> {
    if (this.queryFilter.length > 0) {
      // When using the "minimal" data we just simulate a fetch here (since we already have all the data)
      const filtered = filterByQuery(
        this.dataMinimal,
        this.queryKey,
        this.queryFilter,
      );
      const start = (page - 1) * rowsPerPage;
      const end = start + rowsPerPage;

      return {
        data: filtered.slice(start, end),
        pagination: {
          total_items: this.dataMinimal.length,
          total_pages: Math.ceil(filtered.length / rowsPerPage),
        },
      };
    }

    const res = await apiGetPatients({
      query: {
        load_type: "basic",
        page,
        page_size: rowsPerPage,
        sort_by: ["created_at"],
      },
    });

    return {
      data: this.enrichData(res.data.patients as PatientDto[]),
      pagination: res.data.pagination,
    };
  }

  private enrichData(data: PatientDto[]) {
    data = data.map((item) => {
      const addedBy = item.user.username;
      return { ...item, ["added_by"]: addedBy };
    });

    if (this.api.userLevel >= 3 && this.heartCoreInit) {
      data = data.map((item) => {
        const patHeartCore = new SeerlinqDRIHeartCore(this.api);
        let lastPPG: string = null;

        if ("heart_core" in item) {
          lastPPG = item.heart_core?.last_ppg ?? null;
        }

        return {
          ...item,
          ["last_ppg"]: lastPPG,
          ["patHeartCore"]: patHeartCore,
        };
      });
    }

    return data;
  }
}
