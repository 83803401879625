import { createResource, Show } from "solid-js";
import { PhysiciansModal } from "../admin/common/PhysiciansModal";
import { UsersModal } from "../admin/common/UsersModal";
import { App } from "../App";
import {
  deviceHandovers,
  languages,
  races,
  sexes,
  tokenExpiries,
} from "../common/constants";
import { seerlinqRealms, seerlinqStudies } from "../common/enumToText";
import { Button } from "../common/uiKit/Button";
import { ChooseField } from "../common/uiKit/fields/ChooseField";
import { DateField } from "../common/uiKit/fields/DateField";
import { RadioField } from "../common/uiKit/fields/RadioField";
import { SelectField } from "../common/uiKit/fields/SelectField";
import { SwitchField } from "../common/uiKit/fields/SwitchField";
import { TextField } from "../common/uiKit/fields/TextField";
import { Form } from "../common/uiKit/form/Form";
import { FormRow } from "../common/uiKit/form/FormRow";

export function AddPatientPage(props: { app: App }) {
  const [dataAdd] = createResource(() => props.app.initAddPatient());

  return (
    <Show when={dataAdd()}>
      <h2>Add new patient</h2>(
      <span style="color: #bb16a3">
        <strong>*</strong>
      </span>{" "}
      = mandatory)
      <Form
        onSubmit={() => {
          dataAdd().post();
        }}
      >
        <table class="invisible-table invisible-table-data-add">
          <FormRow
            label="Patient study"
            data={
              <SelectField
                value={dataAdd().addingList["patient_study"][0]}
                onChange={(newValue) => {
                  dataAdd().addingList["patient_study"] = [newValue];
                }}
                values={seerlinqStudies}
                listTitle="Select study"
                required
              />
            }
            mandatory
          />

          <FormRow
            label="Date of Birth"
            data={
              <DateField
                type="date"
                attr="date_of_birth"
                model={dataAdd().addingList}
                required
              />
            }
            mandatory
          />

          <FormRow
            label="Sex"
            data={
              <RadioField
                attr="sex"
                model={dataAdd().addingList}
                values={sexes}
                required
              />
            }
            mandatory
          />

          <FormRow
            label="Race"
            data={
              <SelectField
                attr="race"
                model={dataAdd().addingList}
                values={races}
                required
              />
            }
            mandatory
          />

          <FormRow
            label="Height [cm]"
            data={<TextField attr="height" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Description"
            data={
              <TextField
                attr="description"
                model={dataAdd().addingList}
                multiline="autosize"
                width={300}
              />
            }
          />

          <FormRow
            label="Monitoring realm"
            data={
              <SelectField
                attr="realm"
                model={dataAdd().addingList}
                values={seerlinqRealms}
                listTitle="Select realm"
                required
              />
            }
            mandatory
          />

          <FormRow
            label="Create user (for the app)"
            data={
              <SwitchField
                attr="createUser"
                model={dataAdd()}
                truthy="Yes"
                falsy="No"
              />
            }
            mandatory
          />

          <FormRow
            label="Name"
            data={
              <TextField attr="name" model={dataAdd().addingList} required />
            }
            mandatory
          />

          <FormRow
            label="Email"
            data={
              <TextField attr="email" model={dataAdd().addingList} required />
            }
            mandatory
          />

          <Show when={dataAdd().createUser}>
            <FormRow
              label="Device handover (changes welcome email)"
              data={
                <RadioField
                  attr="welcome_email_type"
                  model={dataAdd().addingList}
                  values={deviceHandovers}
                  required
                />
              }
              mandatory
            />
          </Show>

          <FormRow
            label="Phone"
            data={<TextField attr="phone" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Residence"
            data={<TextField attr="residence" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Health insurance provider"
            data={
              <TextField
                attr="health_insurance"
                model={dataAdd().addingList}
                required
              />
            }
            mandatory
          />

          <FormRow
            label="ID number"
            data={
              <TextField
                attr="id_number"
                model={dataAdd().addingList}
                required
              />
            }
            mandatory
          />

          <Show when={dataAdd().createUser}>
            <FormRow
              label="Preferred language"
              data={
                <SelectField
                  attr="preferred_language"
                  model={dataAdd().addingList}
                  values={languages}
                  required
                />
              }
              mandatory
            />

            <FormRow
              label="Finish registration link expiration"
              data={
                <SelectField
                  attr="token_expiry_timedelta"
                  model={dataAdd().addingList}
                  values={tokenExpiries}
                  required
                />
              }
              mandatory
            />
          </Show>

          <Show when={props.app.seerlinqApi.userLevel >= 3}>
            <FormRow
              label="Add patient to physicians"
              data={
                <ChooseField
                  value={`${dataAdd().addingList["append_to_physicians"]?.length ?? 0} chosen`}
                  modal={({ onClose }) => (
                    <PhysiciansModal
                      title="Choose physicians for a new patient"
                      preselected={dataAdd().addingList["append_to_physicians"]}
                      filter={["realm", [dataAdd().addingList.realm]]}
                      mode="multi"
                      model={dataAdd().createPhysicians()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        dataAdd().addingList["append_to_physicians"] = selected;
                      }}
                    />
                  )}
                />
              }
            />

            <FormRow
              label={
                <>
                  Add patient to users in <strong>physician</strong> role
                </>
              }
              data={
                <ChooseField
                  value={`${dataAdd().addingList["append_to_physician_users"]?.length ?? 0} chosen`}
                  modal={({ onClose }) => (
                    <UsersModal
                      title="Choose managing users for a new patient"
                      preselected={
                        dataAdd().addingList["append_to_physician_users"]
                      }
                      mode="multi"
                      model={dataAdd().createUsers()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        dataAdd().addingList["append_to_physician_users"] =
                          selected;
                      }}
                    />
                  )}
                />
              }
            />
          </Show>
        </table>

        <br />
        <br />

        <Button type="submit">Add patient</Button>
      </Form>
    </Show>
  );
}
