import { reactive } from "@vue/reactivity";
import { Physicians } from "../admin/physicians/Physicians";
import { Users } from "../admin/users/Users";
import {
  apiGetPhysicians,
  apiPostPatients,
  apiPostPatientsPatientAndUser,
} from "../api";
import { ApiConnector } from "../api/ApiConnector";
import { DataAdd } from "../common/DataAdd";

export class AddPatient extends DataAdd {
  createUser = false;
  dataUser = { preferred_language: "sk", token_expiry_timedelta: "P1D" };

  // constants
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      patient_study: [null],
      date_of_birth: null,
      sex: null,
      height: null,
      description: null,
      realm: null,
      race: "Unknown / Not Reported",
      name: null,
      residence: "N/A",
      health_insurance: null,
      id_number: null,
      preferred_language: "sk",
      token_expiry_timedelta: "P1D",
      email: null,
      phone: null,
      append_to_physicians: [],
      append_to_physician_users: [],
    };
  }

  createPhysicians() {
    const physicians = reactive(
      new Physicians([], this.api.userLevel, this.api.userUUID),
    );

    void apiGetPhysicians().then((res) => {
      physicians.data = res.data.physicians;
      physicians.init();
    });

    return physicians;
  }

  createUsers() {
    const users = reactive(new Users(this.api));

    void users.initPhysiciansOnly();

    return users;
  }

  async post() {
    let newPatId: number;

    if (this.createUser) {
      const response = await apiPostPatientsPatientAndUser({
        body: {
          ...this.addingList,
          ...this.dataUser,
        },
      });
      newPatId = response.data.patient.patient_id;
    } else {
      const response = await apiPostPatients({ body: this.addingList });
      newPatId = response.data.patient_id;
    }

    window.navigate(`/patient/${newPatId}`);
  }
}
