import { reactive } from "@vue/reactivity";
import { render } from "solid-js/web";
import { ApiConnector } from "./api/ApiConnector";
import { App } from "./App";
import { AppRoot } from "./AppRoot";
import { apiRoute } from "./common/config";
import { initGlobalErrorHandling } from "./common/errors";
import { initSentry } from "./common/sentry";
import { initSolid } from "./common/utils/initSolid";

function main() {
  initSentry();
  initSolid();
  initGlobalErrorHandling();

  const seerlinqApi = reactive(new ApiConnector(apiRoute));
  seerlinqApi.init();

  const app = reactive(new App(seerlinqApi));
  const rootElement = document.getElementById("root");
  render(() => <AppRoot app={app} />, rootElement);

  app.initIndex();
}

main();
