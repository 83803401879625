import { ApiConnector } from "../../api/ApiConnector";
import { DataAdd } from "../../common/DataAdd";

export class AddMedication extends DataAdd {
  freeTextPlaceHolder = "Specify...";

  // constants
  medicationOptions = {
    "Loop diuretics": ["Furosemide", "Torasemide"],
    "Thiazide diuretics": ["Hydrochlorothiazide"],
    "Other diuretics": ["Indapamide"],
    MRAs: ["Spironolactone", "Eplerenone", "Finerenone"],
    "SGLT2 inhibitors": ["Empagliflozine", "Dapagliflozine"],
    Betablockers: [
      "Bisoprolol",
      "Metoprolol succinate",
      "Nebivolol",
      "Carvedilol",
    ],
    "RAAS inhibitors: ARNI": [
      "Entresto 24mg / 26mg",
      "Entresto 49mg / 51mg",
      "Entresto 97mg / 103mg",
    ],
    "RAAS inhibitors: ACE inhibitors": [
      "Perindopril",
      "Ramipril",
      "Trandolapril",
      "Lisinopril",
      "Chinapril",
    ],
    "RAAS inhibitors: ARB": [
      "Valsartan",
      "Candesartan",
      "Irbesartan",
      "Telmisartan",
      "Losartan",
    ],
    Vasodilators: ["Izosorbid-mononitrate", "Molsidomine", "Vericiguat"],
    "GLP1-RA": ["Semaglutide", "Liraglutide"],
    Other: ["Digoxin", "Ivabradine"],
  };
  allowFreeText = [
    "Other diuretics",
    "Betablockers",
    "RAAS inhibitors: ACE inhibitors",
    "RAAS inhibitors: ARB",
    "Vasodilators",
    "Other",
  ];

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = [
      "medication_dosage",
      "medication_frequency_regular_repeat_every",
    ];
    this.desc = "Medications";
    this.bodyField = "medications";
    this.endpoint = "medications/bulk";
    this.defaultDateTimeName = "Unset (date not required)";
    this.defaultDateTimeFields = ["medication_started"];
    this.reloadToTab = "medications";
  }

  customSanitize(field) {
    if (
      field.medication_name === this.freeTextPlaceHolder &&
      this.allowFreeText.includes(field.medication_group)
    ) {
      field.medication_name = field.freeTextValue;
      delete field.freeTextValue;
    }
    return field;
  }

  async initEmpty() {
    this.defaultDateTime = null;
    var empty = [];
    const temp = {
      medication_group: null,
      medication_name: null,
      medication_frequency_regular_repeat_every: 1,
      medication_dosage: [0, 0, 0, 0],
    };
    empty.push(temp);

    this.addingList = empty;
  }

  addItem() {
    this.addingList.push({
      medication_group: null,
      medication_name: null,
      medication_frequency_regular_repeat_every: 1,
      medication_dosage: [0, 0, 0, 0],
    });
  }

  disabledDoseUnit(field) {
    if (field.medication_name != null) {
      if (field.medication_name.startsWith("Entresto")) {
        return true;
      }
    }
    return false;
  }
}
