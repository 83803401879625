import { Navigate, Route, Router } from "@solidjs/router";
import { AddPatientPage } from "./addPatient/AddPatientPage";
import { AdminPage } from "./admin/AdminPage";
import { ClinicsAdmin } from "./admin/clinics/ClinicsAdmin";
import { LimitersAdmin } from "./admin/limiters/LimitersAdmin";
import { PhysiciansAdmin } from "./admin/physicians/PhysiciansAdmin";
import { TeamsAdmin } from "./admin/teams/TeamsAdmin";
import { UsersAdmin } from "./admin/users/UsersAdmin";
import { App } from "./App";
import { LoginPage } from "./LoginPage";
import { MainLayout } from "./MainLayout";
import { AlertsTable } from "./patient/alerts/AlertsTable";
import { AddAnyDataTable } from "./patient/anyData/AddAnyDataTable";
import { DiagnosesTable } from "./patient/diagnoses/DiagnosesTable";
import { DRITable } from "./patient/dri/DRITable";
import { EventsTable } from "./patient/events/EventsTable";
import { ExamsTable } from "./patient/exams/ExamsTable";
import { HealthPro } from "./patient/healthPro/HealthPro";
import { LabsTable } from "./patient/labs/LabsTable";
import { LogsTable } from "./patient/logs/LogsTable";
import { BasicMedicalTable } from "./patient/medical/BasicMedicalTable";
import { MedicationsTable } from "./patient/medications/MedicationsTable";
import { PatientPage } from "./patient/PatientPage";
import { PPGTable } from "./patient/ppg/PPGTable";
import { ReportsPage } from "./patient/reports/ReportsPage";
import { SymptomsTable } from "./patient/symptoms/SymptomsTable";
import { ThresholdsTable } from "./patient/thresholds/ThresholdsTable";
import { VitalsTable } from "./patient/vitals/VitalsTable";
import { PatientsTable } from "./patients/PatientsTable";
import { RouterRoot } from "./RouterRoot";
import { HighRiskWaitingRoom } from "./waitingRoom/HighRiskWaitingRoom";
import { MedicalWaitingRoom } from "./waitingRoom/MedicalWaitingRoom";
import { NonMedicalWaitingRoom } from "./waitingRoom/NonMedicalWaitingRoom";
import { WaitingRoomPage } from "./waitingRoom/WaitingRoomPage";

export function AppRoot(props: { app: App }) {
  return (
    <Router root={RouterRoot}>
      <Route path="/login" component={() => <LoginPage app={props.app} />} />

      <Route
        path="/"
        component={(p) => <MainLayout app={props.app}>{p.children}</MainLayout>}
      >
        <Route
          path="/"
          component={() => (
            <PatientsTable api={props.app.seerlinqApi} app={props.app} />
          )}
        />

        <Route
          path="/patient/:id"
          component={(p) => (
            <PatientPage
              app={props.app}
              id={parseInt(p.params.id)}
              api={props.app.seerlinqApi}
            >
              {p.children}
            </PatientPage>
          )}
        >
          <Route path="/" component={() => <Navigate href="alerts" />} />

          <Route
            path="/alerts"
            component={() => <AlertsTable patient={props.app.patient} />}
          />

          <Route
            path="/any"
            component={() => <AddAnyDataTable patient={props.app.patient} />}
          />

          <Route
            path="/basics"
            component={() => (
              <BasicMedicalTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/diags"
            component={() => (
              <DiagnosesTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/dri"
            component={() => <DRITable patient={props.app.patient} />}
          />

          <Route
            path="/events"
            component={() => (
              <EventsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/exams"
            component={() => (
              <ExamsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/healthpro"
            component={() => <HealthPro patient={props.app.patient} />}
          />

          <Route
            path="/labs"
            component={() => (
              <LabsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/medications"
            component={() => (
              <MedicationsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/ppg"
            component={() => (
              <PPGTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/symptoms"
            component={() => (
              <SymptomsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/thresholds"
            component={() => (
              <ThresholdsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/vitals"
            component={() => (
              <VitalsTable
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />

          <Route
            path="/logs"
            component={() => <LogsTable patient={props.app.patient} />}
          />

          <Route
            path="/reports"
            component={() => (
              <ReportsPage
                patient={props.app.patient}
                api={props.app.seerlinqApi}
              />
            )}
          />
        </Route>

        <Route
          path="/add-patient"
          component={() => <AddPatientPage app={props.app} />}
        />

        <Route
          path="/waiting-room"
          component={(p) => (
            <WaitingRoomPage app={props.app}>{p.children}</WaitingRoomPage>
          )}
        >
          <Route
            path="/"
            component={() => <Navigate href="/waiting-room/medical" />}
          />

          <Route
            path="/medical"
            component={() => (
              <MedicalWaitingRoom
                waitingRoom={props.app.waitingRoom}
                app={props.app}
              />
            )}
          />

          <Route
            path="/non-medical"
            component={() => (
              <NonMedicalWaitingRoom
                waitingRoom={props.app.waitingRoom}
                app={props.app}
              />
            )}
          />

          <Route
            path="/high-risk"
            component={() => (
              <HighRiskWaitingRoom
                waitingRoom={props.app.waitingRoom}
                app={props.app}
              />
            )}
          />
        </Route>

        <Route
          path="/admin"
          component={(p) => <AdminPage app={props.app}>{p.children}</AdminPage>}
        >
          <Route
            path="/"
            component={() => <Navigate href="/admin/clinics" />}
          />

          <Route
            path="/clinics"
            component={() => <ClinicsAdmin admin={props.app.adminShit} />}
          />

          <Route
            path="/physicians"
            component={() => <PhysiciansAdmin admin={props.app.adminShit} />}
          />

          <Route
            path="/teams"
            component={() => <TeamsAdmin admin={props.app.adminShit} />}
          />

          <Route
            path="/users"
            component={() => <UsersAdmin admin={props.app.adminShit} />}
          />

          <Route
            path="/limiters"
            component={() => <LimitersAdmin admin={props.app.adminShit} />}
          />
        </Route>
      </Route>
    </Router>
  );
}
