import { SeerlinqComputedWithPPGResponse } from "../../api";
import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Computed extends DataModel {
  // constants
  driName = "diastolic reserve index";
  hrName = "heart rate";
  rrName = "respiratory rate";

  constructor(
    data: SeerlinqComputedWithPPGResponse[],
    userLevel: number,
    userUUID: string,
  ) {
    super(data, userLevel, userUUID);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.editable = ["seerlinq_measurement_quality_flag", "comment"];
    this.initSort = [true];
    this.filteringAttrs = [
      "measurement_type",
      "seerlinq_algorithm",
      "seerlinq_algorithm_version",
      "seerlinq_measurement_quality_flag",
    ];
    this.filteringListAttrs = ["tags"];

    // access
    this.access = {
      editRules: { 2: AccessRules.visible, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.none },
      historyMinLevel: null,
    };
  }

  init() {
    this.data = this.data.map((item: SeerlinqComputedWithPPGResponse) => {
      const ppgConditions = item["ppg_uuids"].map((ppg) => {
        return ppg["measurement_condition"];
      });
      return {
        ...item,
        ["ppg_conditions"]: ppgConditions.sort((a, b) => a.localeCompare(b)),
      };
    });
    super.init();
  }

  filterDRI() {
    this.data = this.data.filter((item: SeerlinqComputedWithPPGResponse) => {
      return item.measurement_type === this.driName;
    });
  }

  filterHR() {
    this.data = this.data.filter((item: SeerlinqComputedWithPPGResponse) => {
      return item.measurement_type === this.hrName;
    });
  }

  filterRR() {
    this.data = this.data.filter((item: SeerlinqComputedWithPPGResponse) => {
      return item.measurement_type === this.rrName;
    });
  }
}
