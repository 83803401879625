import CDialog from "@corvu/dialog";
import clsx from "clsx";
import { JSX } from "solid-js";
import styles from "./Dialog.module.css";

export function Dialog(props: {
  children?: JSX.Element;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeOnEscapeKey?: boolean;
  closeOnOutsideClick?: boolean;
  class?: string;
}) {
  return (
    <CDialog
      open={props.open}
      closeOnEscapeKeyDown={props.closeOnEscapeKey}
      closeOnOutsidePointer={props.closeOnOutsideClick}
      onOpenChange={props.onOpenChange}
    >
      <CDialog.Portal>
        <CDialog.Overlay class={styles.overlay} />

        <CDialog.Content class={clsx(styles.dialog, props.class)}>
          {props.children}
        </CDialog.Content>
      </CDialog.Portal>
    </CDialog>
  );
}
