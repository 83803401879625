import { createMemo, createSignal, createUniqueId, For } from "solid-js";
import styles from "./RadioField.module.css";
import { useField, valuesToMap } from "./utils";

export function RadioField<T extends string | number>(props: {
  attr?: string;
  model?: Record<string, any>;
  value?: string;
  onChange?: (newValue: T) => void;
  values: T[] | Record<string, string>;
  required?: boolean;
}) {
  const [element, setElement] = createSignal<HTMLInputElement>();
  const { showErrors } = useField(element);
  const name = createUniqueId();

  const currentValue = createMemo(() =>
    props.value !== undefined
      ? props.value
      : props.model && props.attr
        ? props.model[props.attr]
        : undefined,
  );

  const valuesMap = createMemo(() => valuesToMap(props.values));

  return (
    <For each={[...valuesMap().entries()]}>
      {([value, displayValue], index) => {
        return (
          <label>
            <span>{displayValue}:</span>{" "}
            <input
              type="radio"
              value={value}
              checked={currentValue() === value}
              onChange={(event) => {
                if (!event.target.checked) {
                  return;
                }

                if (props.model) {
                  props.model[props.attr] = value;
                }

                props.onChange?.(value);
              }}
              classList={{
                [styles.radio]: true,
                [styles.showErrors]: showErrors(),
              }}
              ref={index() === 0 ? setElement : undefined}
              name={name} // All radio buttons are treated as one field if they share the same name
              required={props.required}
            />{" "}
          </label>
        );
      }}
    </For>
  );
}
