import {
  apiGetTasksWorkerQuicklookShareTaskId,
  apiGetTasksWorkerResultTaskId,
  apiGetTasksWorkerStatusTaskId,
  apiPostTasksWorkerQuicklookPatient,
  apiPostTasksWorkerQuicklookPpg,
} from "../api";
import { ApiConnector } from "../api/ApiConnector";

class QuickLook {
  analysisType = null;
  processing = false;
  buttonText = "";
  resultUrl = "";
  taskId = null;
  sharedAnalysisUrl = "";
  sharePopupOpen = false;

  quicklookIntervalId = null;
  resetButtonText = "";
  readyButtonText = "";

  quickLookTypes = {};

  constructor(protected api: ApiConnector) {}

  quickLookURL() {
    return `${this.api.versionedRoute()}/tasks/worker/quicklook/${this.taskId}`;
  }

  reset() {
    this.processing = false;
    this.buttonText = this.resetButtonText;
    this.resultUrl = "";
    this.taskId = null;
    this.sharedAnalysisUrl = "";
    this.sharePopupOpen = false;
  }

  async submit(ids: unknown[]) {}

  async getHandledException() {
    const responseException = await apiGetTasksWorkerResultTaskId({
      path: { task_id: this.taskId },
    });
    return (
      `Task with ID ${this.taskId} failed.\n` +
      `${responseException.data.handled_exception.title}:` +
      `${responseException.data.handled_exception.details}`
    );
  }

  checkStatus() {
    this.quicklookIntervalId = setInterval(async () => {
      const response = await apiGetTasksWorkerStatusTaskId({
        path: { task_id: this.taskId },
      });

      if (response.data.state === "success") {
        clearInterval(this.quicklookIntervalId);
        this.taskReady();
      } else if (response.data.state === "failure") {
        const alertText = await this.getHandledException();
        alert(alertText);
        this.reset();
        clearInterval(this.quicklookIntervalId);
      }
    }, 2000);
  }

  taskReady() {
    this.processing = false;
    this.buttonText = this.readyButtonText;
    this.resultUrl = this.quickLookURL();
  }

  async shareQuickLook() {
    const response = await apiGetTasksWorkerQuicklookShareTaskId({
      path: { task_id: this.taskId },
    });

    this.sharedAnalysisUrl = response.data.shareable_link;
    this.sharePopupOpen = true;
  }
}

export class PPGQuickLook extends QuickLook {
  constructor(api: ApiConnector) {
    super(api);
    this.buttonText = "Submit QuickLook";
    this.resetButtonText = "Submit QuickLook";
    this.readyButtonText = "View Analysis";

    this.quickLookTypes = {
      ppg_timeseries_ql: "Basic QuickLook",
      ppg_auc_annotated_ql: "AUC Annotated QuickLook",
    };
  }

  async submit(uuids: string[]) {
    if (uuids.length > 10) {
      alert("More than 10 PPGs selected, currently not supported");
      return;
    }
    this.processing = true;
    this.buttonText = "Processing";

    const response = await apiPostTasksWorkerQuicklookPpg({
      body: {
        task: this.analysisType,
        uuids: uuids,
      },
    });
    this.taskId = response.data.task_id;
    this.checkStatus();
  }
}

class PatientQuickLook extends QuickLook {
  constructor(api: ApiConnector) {
    super(api);
    this.quickLookTypes = {
      hf_dri_timeseries_ql: "Seerlinq HeartCore",
      heartcore_report: "HeartCore Monthly Report",
    };
  }

  async submit(patient_ids: number[]) {
    this.processing = true;
    this.buttonText = "Processing";

    const response = await apiPostTasksWorkerQuicklookPatient({
      body: {
        task: this.analysisType,
        patient_ids: patient_ids,
      },
    });
    this.taskId = response.data.task_id;
    this.checkStatus();
  }
}

export class SeerlinqDRIHeartCore extends PatientQuickLook {
  constructor(api: ApiConnector) {
    super(api);
    this.buttonText = "DRI HeartCore";
    this.analysisType = "hf_dri_timeseries_ql";
    this.resetButtonText = "DRI HeartCore";
    this.readyButtonText = "View HeartCore";
  }
}

export class SeerlinqHCReport extends PatientQuickLook {
  constructor(api: ApiConnector) {
    super(api);
    this.buttonText = "HeartCore Report";
    this.analysisType = "heartcore_report";
    this.resetButtonText = "HeartCore Report";
    this.readyButtonText = "View Report";
  }
}
