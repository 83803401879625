import { DataModel } from "../../common/DataModel";
import { AccessRules } from "../../common/roles";

export class Diagnoses extends DataModel {
  constructor(data, userLevel: number, userUUID: string) {
    super(data, userLevel, userUUID);
    this.hasHistory = true;
    this.sortBy = ["diagnosed_at", "diagnosis_name"];
    this.sortByNames = ["Diagnosed", "Name"];
    this.dateFields = ["diagnosed_at", "remission"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["diagnosis_name"];
    this.editable = [
      "diagnosed_at",
      "diagnosis_value",
      "diagnosis_confidence",
      "remission",
      "comment",
    ];
    this.initSort = [true, null];

    // access
    this.access = {
      editRules: { 2: AccessRules.owner, 3: AccessRules.visible },
      deleteRules: { 2: AccessRules.none, 3: AccessRules.owner },
      historyMinLevel: 3,
    };
  }
}
