import { enableTracking, pauseTracking, ReactiveEffect } from "@vue/reactivity";
import { enableExternalSource } from "solid-js";

export function initSolid() {
  // We can use Solid with @vue/reactivity thanks to this snippet
  enableExternalSource(
    (fn, trigger) => {
      const ef = new ReactiveEffect(() => {});

      ef.scheduler = () => {
        trigger();
      };

      return {
        track: (x) => {
          let next: unknown;
          ef.fn = () => (next = fn(x));
          ef.run();
          return next;
        },
        dispose: () => {
          ef.stop();
        },
      };
    },
    (fn) => {
      pauseTracking();
      const res = fn();
      enableTracking();
      return res;
    },
  );
}

/**
 * This actually doesn't do anything, it just allows us to use a `value()` syntax instead of just `value`,
 * which makes it occasionally convenient to avoid refactors (chaning `value()` to `value` and then back again).
 */
export function createStatic<T>(value: T): () => T {
  return () => value;
}
