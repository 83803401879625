import { reactive } from "@vue/reactivity";
import { createMemo, createSignal, Show } from "solid-js";
import {
  connectedRoles,
  languages,
  managingRoles,
} from "../../common/constants";
import { Button } from "../../common/uiKit/Button";
import { Container } from "../../common/uiKit/Container";
import { ChooseField } from "../../common/uiKit/fields/ChooseField";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { FormRow } from "../../common/uiKit/form/FormRow";
import { CellChoose } from "../../common/uiKit/tables/cells/CellChoose";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { HeaderTextFilter } from "../../common/uiKit/tables/headers/HeaderTextFilter";
import { maybeAsArray } from "../../common/utils/utils";
import { AdminConsole } from "../AdminConsole";
import { PatientsModal } from "../common/PatientsModal";
import { AddUser } from "./AddUser";
import { TemporaryPasswordDialog } from "./TemporaryPasswordDialog";

export function UsersAdmin(props: { admin: AdminConsole }) {
  const model = createMemo(() => props.admin.users);
  const [dataAdd, setDataAdd] = createSignal<AddUser>();

  return (
    <div id="modal-content">
      <TemporaryPasswordDialog admin={props.admin} />

      <h2>Users</h2>
      <span>
        We have <strong>{props.admin.users.data.length}</strong> user(s) in the
        database.
      </span>

      <br />
      <br />

      <Button
        onClick={() => {
          if (dataAdd()) {
            setDataAdd(null);
          } else {
            const model = reactive(new AddUser(props.admin.api, null));
            model.initEmpty();
            setDataAdd(model);
          }
        }}
      >
        {dataAdd() ? "Cancel" : "Create new user"}
      </Button>

      <br />
      <br />

      <Show when={!dataAdd()}>
        <DataTable
          columns={[
            {
              header1: "Username",
              header2: <HeaderSort attr="username" model={model()} />,
              header3: (
                <HeaderTextFilter model={model()} placeholder="username..." />
              ),
              cell: (item) => <strong>{item.username}</strong>,
            },
            {
              header1: "Role",
              header2: (
                <HeaderSelectFilter attr="role" model={model()} name="Role" />
              ),
              cell: (item) => item.role,
            },
            {
              header1: "Active",
              header2: (
                <HeaderSelectFilter
                  attr="active"
                  model={model()}
                  name="Active"
                />
              ),
              cell: (item) => String(item.active),
            },
            {
              header1: "Monitoring team",
              header2: (
                <HeaderSelectFilter
                  attr="monitoring_team"
                  model={model()}
                  name="Team"
                />
              ),
              cell: (item) => item.monitoring_team,
            },
            {
              header1: "Added",
              header2: <HeaderSort attr="created_at" model={model()} />,
              cell: (item) => item.created_at,
            },
            {
              header1: "Added by",
              header2: (
                <HeaderSelectFilter
                  attr="added_by"
                  model={model()}
                  name="Who added"
                  values={props.admin.users.userMapping}
                />
              ),
              cell: (item) => props.admin.users.userMapping[item.added_by],
            },
            {
              header1: "Last login",
              cell: (item) => item.last_login,
            },
            {
              header1: "Preferred language",
              cell: (item, index) => (
                <CellEnum
                  attr="preferred_language"
                  index={index}
                  model={model()}
                  values={languages}
                />
              ),
            },
            {
              header1: "Connected ID",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={model().editedField?.["connected_patient_id"]}
                  valueView={
                    <strong>
                      <a
                        style="color: #bb16a3"
                        href={`/patient/${item.connected_patient_id}`}
                      >
                        {item.connected_patient_id}
                      </a>
                    </strong>
                  }
                  disabled={!connectedRoles.includes(item.role)}
                  modal={({ onClose }) => (
                    <PatientsModal
                      title={`Change connected ID for user: ${item.username}`}
                      preselected={maybeAsArray(
                        model().editedField["connected_patient_id"],
                      )}
                      mode="single"
                      model={props.admin.createPatients()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["connected_patient_id"] =
                          selected[0];
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "Managed IDs",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  valueEdit={
                    <Show when={managingRoles.includes(item.role)}>
                      {`${model().editedField?.["managed_patients"]?.length ?? 0} chosen`}
                    </Show>
                  }
                  valueView={
                    <span
                      innerHTML={props.admin.users.generateLinks(
                        item.managed_patients.map(
                          (patient) => patient.patient_id,
                        ),
                      )}
                    />
                  }
                  disabled={!managingRoles.includes(item.role)}
                  modal={({ onClose }) => (
                    <PatientsModal
                      title={`Change managing patients for user: ${item.username}`}
                      preselected={model().editedField["managed_patients"]}
                      mode="multi"
                      model={props.admin.createPatients()}
                      onClose={onClose}
                      onConfirm={({ selected }) => {
                        model().editedField["managed_patients"] = selected;
                      }}
                    />
                  )}
                />
              ),
            },
            {
              header1: "# of monitored patients",
              cell: (item) =>
                item.monitoring_patients ? item.monitoring_patients.length : "",
            },
            props.admin.api.userLevel === 4 && {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updateUser(item.uuid, editedField);
                  }}
                />
              ),
            },
            props.admin.api.userLevel === 4 && {
              header1: "Reset user",
              cell: (item) => (
                <Show when={connectedRoles.includes(item.role)} fallback="--">
                  <div>
                    <Button
                      data-tooltip="Send 'forgot password' reset email."
                      onClick={() => {
                        props.admin.resetUser(item.uuid);
                      }}
                    >
                      Reset user
                    </Button>
                  </div>
                </Show>
              ),
            },
            props.admin.api.userLevel === 4 && {
              header1: "Temporary password",
              cell: (item) => (
                <Show when={connectedRoles.includes(item.role)} fallback="--">
                  <div>
                    <Button
                      data-tooltip={props.admin.tempPasswordTooltipText(item)}
                      disabled={item.has_temporary_password}
                      onClick={() => {
                        props.admin.temporaryPassword(item.uuid);
                      }}
                    >
                      Temp. password
                    </Button>
                  </div>
                </Show>
              ),
            },
          ]}
          model={model()}
          sticky
        />
      </Show>

      <Show when={dataAdd() && props.admin.api.userLevel === 4}>
        <div>
          <h3>Add new user</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Username (case INsensitive)"
                data={
                  <TextField attr="username" model={dataAdd().addingList} />
                }
              />

              <FormRow
                label="Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
              />

              <FormRow
                label="Password"
                data={
                  <TextField
                    attr="password"
                    model={dataAdd().addingList}
                    type="password"
                  />
                }
              />

              <FormRow
                label="Role"
                data={
                  <SelectField
                    attr="role"
                    values={dataAdd().allRoles}
                    model={dataAdd().addingList}
                    listTitle="Select role"
                  />
                }
              />

              <FormRow
                label="Preferred language"
                data={
                  <SelectField
                    attr="preferred_language"
                    values={languages}
                    model={dataAdd().addingList}
                  />
                }
              />

              <Show when={connectedRoles.includes(dataAdd().addingList.role)}>
                <FormRow
                  label="Connected patient"
                  data={
                    <ChooseField
                      value={
                        dataAdd().addingList["connected_patient_id"] ?? "None"
                      }
                      modal={({ onClose }) => (
                        <PatientsModal
                          title="Choose connected ID for a new user"
                          preselected={maybeAsArray(
                            dataAdd().addingList["connected_patient_id"],
                          )}
                          mode="single"
                          model={props.admin.createPatients()}
                          onClose={onClose}
                          onConfirm={({ selected }) => {
                            dataAdd().addingList["connected_patient_id"] =
                              selected[0];
                          }}
                        />
                      )}
                    />
                  }
                />
              </Show>

              <Show when={managingRoles.includes(dataAdd().addingList.role)}>
                <FormRow
                  label="Managing patients"
                  data={
                    <ChooseField
                      value={`${(dataAdd().addingList["managed_patient_ids"] ?? []).length} chosen`}
                      modal={({ onClose }) => (
                        <PatientsModal
                          title="Choose managed patient for a new managing user"
                          preselected={
                            dataAdd().addingList["managed_patient_ids"]
                          }
                          mode="multi"
                          model={props.admin.createPatients()}
                          onClose={onClose}
                          onConfirm={({ selected }) => {
                            dataAdd().addingList["managed_patient_ids"] =
                              selected;
                          }}
                        />
                      )}
                    />
                  }
                />
              </Show>
            </table>

            <br />
            <br />

            <Button
              onClick={() => {
                dataAdd().post();
              }}
            >
              Add user
            </Button>
          </form>
        </div>
      </Show>

      <Container column alignItems="center">
        <p>User roles:</p>
        <table class="info-table" id="user-class">
          <tbody>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>self-only</em>
              </td>
              <td>
                <strong>patient-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and only sees its
                <em>connected patient ID</em>. This is typically user for app
                that has patient at home.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>managed</em>
              </td>
              <td>
                <strong>physician-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and can see its
                <em>managed patient IDs</em>. This can be app that some
                physician might have at their office so they can measure their
                patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>full</em>
              </td>
              <td>
                <strong>study-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and can see <em>all patient IDs</em>. This
                is SmartCare app at Premedix Office, so we can measure any
                patient.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 2</strong> | <em>self-only</em>
              </td>
              <td>
                <strong>patient</strong>
              </td>
              <td>
                Limited read/write for any data, can update data they created.
                Only sees its <em>connected patient ID</em>. This is user for
                Seerlinq phone app for submitting basic patient and medial data.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 2</strong> | <em>managed</em>
              </td>
              <td>
                <strong>physician</strong>
              </td>
              <td>
                Limited read/write for any data, can update data they created.
                It can see their <em>managed patient IDs</em>. This is user for
                any physician that monitors their patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 3</strong> | <em>managed</em>
              </td>
              <td>
                <strong>study-physician</strong>
              </td>
              <td>
                Full read/write for any data, can update data they created. It
                can see their <em>managed patient IDs</em>. This is our
                related-physician (think Amitai) that can see all and any data,
                run algorithms, but still sees only their patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 3</strong> | <em>full</em>
              </td>
              <td>
                <strong>seerlinq-user</strong>
              </td>
              <td>
                Full read/write for any data, can update data they created. It
                can see
                <em>all patient IDs</em>. This is for internal Seerlinq people
                for full access to the DB.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 4</strong> | <em>full</em>
              </td>
              <td>
                <strong>admin</strong>
              </td>
              <td>
                God of the API and DB. Can delete any data. Only admin can
                create new users.
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
      <br />
    </div>
  );
}
