import { createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { Button } from "../../common/uiKit/Button";
import styles from "../../common/uiKit/Table.module.css";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddMedicationsTable } from "./AddMedicationsTable";

export function MedicationsTable(props: {
  patient: Patient;
  api: ApiConnector;
}) {
  const [model] = createResource(() => props.patient.fetchMedications());
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Medications" loading={model.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add medications
      </Button>

      <Show when={adding()}>
        <AddMedicationsTable patient={props.patient} />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        columns={[
          {
            header1: "Started",
            header2: <HeaderSort attr="medication_started" model={model()} />,
            cell: (item, index) => (
              <CellDate
                attr="medication_started"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Ended",
            header2: <HeaderSort attr="medication_ended" model={model()} />,
            cell: (item, index) => (
              <CellDate
                attr="medication_ended"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Group",
            header2: (
              <HeaderSelectFilter
                attr="medication_group"
                model={model()}
                name="Group"
              />
            ),
            cell: (item) => item.medication_group,
          },
          {
            header1: "Name",
            header2: <HeaderSort attr="medication_name" model={model()} />,
            cell: (item, index) => (
              <CellText
                attr="medication_name"
                index={index}
                model={model()}
                width={50}
                disabled={!model().canEditName(item)}
              />
            ),
          },
          {
            header1: "Dose",
            cell: (item, index) => (
              <CellText
                attr="medication_dose"
                index={index}
                model={model()}
                width={40}
              />
            ),
          },
          {
            header1: "Unit",
            cell: (item, index) => (
              <CellText
                attr="medication_unit"
                index={index}
                model={model()}
                width={30}
              />
            ),
          },
          {
            header1: "Dosage",
            cell: (item, index) => (
              <Show
                when={model().editingId === index}
                fallback={item.medication_dosage.join("/")}
              >
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[0]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[0] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[1]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[1] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[2]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[2] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[3]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[3] =
                      event.target.value;
                  }}
                />
              </Show>
            ),
          },
          {
            header1: "Frequency",
            cell: (item, index) => model().freqString(item),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                width={80}
                multiline="autosize"
              />
            ),
          },
          {
            header1: "Change dosage",
            cell: (item, index) => (
              <>
                <Show
                  when={
                    model().editingId === index &&
                    model().editedField &&
                    "medication_change_date" in model().editedField
                  }
                >
                  <input
                    type="date"
                    value={model().editedField?.medication_change_date}
                    onChange={(event) => {
                      model().editedField.medication_change_date =
                        event.target.value;
                    }}
                  />
                </Show>

                <Show when={model().editingId !== index}>
                  <Button
                    onClick={() => {
                      model().startChangingDosage(index);
                    }}
                    class={styles.rowButton}
                  >
                    Change
                  </Button>
                </Show>
              </>
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  if ("medication_change_date" in editedField) {
                    props.patient.updateItem(
                      "medications/change",
                      item.uuid,
                      editedField,
                    );
                  } else {
                    props.patient.updateItem(
                      "medications",
                      item.uuid,
                      editedField,
                    );
                  }
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <CellDeleteButton
                index={index}
                model={model()}
                onConfirm={() =>
                  props.patient.deleteItem("medications", item.uuid)
                }
              />
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
        model={model()}
      />
    </PatientSubpage>
  );
}
