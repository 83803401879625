const defaultSize = 40;

export function Spinner(props: { size?: number }) {
  return (
    <div
      class="spinner"
      style={{
        width: `${props.size ?? defaultSize}px`,
        height: `${props.size ?? defaultSize}px`,
      }}
    >
      <img src="/static/imgs/sq_spin.png" />
    </div>
  );
}
