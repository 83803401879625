import { reactive } from "@vue/reactivity";
import { Button } from "../../common/uiKit/Button";
import { FileUploadDropZone } from "../../common/uiKit/FileUploadDropZone";
import { DateField } from "../../common/uiKit/fields/DateField";
import { SelectField } from "../../common/uiKit/fields/SelectField";
import { TextField } from "../../common/uiKit/fields/TextField";
import { Form } from "../../common/uiKit/form/Form";
import { FormRow } from "../../common/uiKit/form/FormRow";
import { Patient } from "../Patient";
import { AddReport } from "./AddReport";
import { reportTypes } from "./enums";

export function AddReportForm(props: { patient: Patient }) {
  const model = reactive(new AddReport(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <div>
      <hr />
      <div>
        <h3>{model.desc}</h3>(
        <span style="color: #bb16a3">
          <strong>*</strong>
        </span>{" "}
        = mandatory)
        <Form
          onSubmit={() => {
            model.post();
          }}
        >
          <table class="invisible-table invisible-table-data-add">
            <tbody>
              <FormRow
                label="Date"
                data={
                  <DateField
                    attr="report_date"
                    model={model.addingList}
                    type="date"
                    required
                  />
                }
                mandatory
              />

              <FormRow
                label="Type"
                data={
                  <SelectField
                    attr="report_type"
                    model={model.addingList}
                    values={reportTypes}
                    required
                  />
                }
                mandatory
              />

              <FormRow
                label="Comment"
                data={
                  <TextField
                    attr="comment"
                    model={model.addingList}
                    multiline="autosize"
                  />
                }
              />

              <FormRow
                label="File"
                data={
                  <FileUploadDropZone
                    onSelect={(file) => {
                      model.uploadFile(file);
                    }}
                    accept={[".pdf", ".png", ".jpg", ".jpeg", ".rtf", ".txt"]}
                  />
                }
                mandatory
              />
            </tbody>
          </table>
          <br />
          <br />
          <Button type="submit">Post</Button>
        </Form>
      </div>
      <hr />
    </div>
  );
}
