import { createResource } from "solid-js";
import { alertKinds, patientStates } from "../../common/enumToText";
import { Button } from "../../common/uiKit/Button";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSelectFilter } from "../../common/uiKit/tables/headers/HeaderSelectFilter";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { Tags } from "../../common/uiKit/Tags";
import { floatRounding } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";

export function AlertsTable(props: { patient: Patient }) {
  const [model] = createResource(() => props.patient.fetchAlerts());

  return (
    <PatientSubpage title="Alerts" loading={model.loading}>
      <div>
        Patient state:
        <br />
        <strong>{patientStates[props.patient.data.patient_state]}</strong>
        <br />
        <Button onClick={async () => await props.patient.togglePatientState()}>
          Toggle state
        </Button>
        <br />
      </div>

      <br />
      <br />

      <DataTable
        model={model()}
        columns={[
          {
            header1: "Timestamp",
            header2: <HeaderSort attr="alert_timestamp" model={model()} />,
            cell: (item) => item.alert_timestamp,
          },
          {
            header1: "Kind",
            header2: (
              <HeaderSelectFilter
                attr="alert_kind"
                model={model()}
                name="Kind"
                values={alertKinds}
              />
            ),
            cell: (item) => alertKinds[item.alert_kind],
          },
          {
            header1: "Variable",
            header2: (
              <HeaderSelectFilter
                attr="alert_variable"
                model={model()}
                name="Variable"
              />
            ),
            cell: (item) => item.alert_variable,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="alert_type"
                model={model()}
                name="Type"
                values={model().alertTypes}
              />
            ),
            cell: (item) => model().alertTypes[item.alert_type],
          },
          {
            header1: "Value",
            cell: (item) => floatRounding(item.alert_value, 1),
          },
          {
            header1: "Reason",
            cell: (item) => item.alert_reason,
          },
          {
            header1: "Seen",
            header2: (
              <HeaderSelectFilter attr="seenMark" model={model()} name="Seen" />
            ),
            cell: (item) => item.seenMark,
          },
          {
            header1: "Tags",
            header2: (
              <HeaderSelectFilter
                attr="alert_tags"
                model={model()}
                name="Tags"
              />
            ),
            cell: (item) => <Tags tags={item.alert_tags} />,
          },
        ]}
      />
    </PatientSubpage>
  );
}
