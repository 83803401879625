import { createMemo, createSignal, For, Show } from "solid-js";
import { ApiConnector } from "../api/ApiConnector";
import { App } from "../App";
import { races, sexes } from "../common/constants";
import {
  informedConsent,
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../common/enumToText";
import { Button } from "../common/uiKit/Button";
import { DateField } from "../common/uiKit/fields/DateField";
import { RadioField } from "../common/uiKit/fields/RadioField";
import { SelectField } from "../common/uiKit/fields/SelectField";
import { SelectMultipleField } from "../common/uiKit/fields/SelectMultipleField";
import { TextField } from "../common/uiKit/fields/TextField";
import { FormRow } from "../common/uiKit/form/FormRow";
import { FormRowEditable } from "../common/uiKit/form/FormRowEditable";
import { Spinner } from "../common/uiKit/Spinner";
import { floatRounding } from "../common/utils/utils";
import { CreateUserForm } from "./CreateUserForm";
import { Patient } from "./Patient";

export function PatientDetails(props: {
  patient: Patient;
  app: App;
  api: ApiConnector;
}) {
  const patient = createMemo(() => props.patient);
  const data = createMemo(() => patient()?.data);
  const form = createMemo(() => patient()?.detailsForm);
  const updateField = (editedField: Record<string, any>) => {
    patient().updatePatientField(editedField);
  };
  const [openPatSettings, setOpenPatSettings] = createSignal(false);
  const [addingConnUser, setAddingConnUser] = createSignal(false);

  return (
    <div>
      <table class="invisible-table invisible-table-big">
        <FormRowEditable
          form={form()}
          attr="date_of_birth"
          label="Date of birth"
          model={data()}
          viewMode={new Date(data().date_of_birth).toLocaleDateString("sk-SK")}
          editMode={
            <DateField
              attr="date_of_birth"
              model={form().editedField}
              type="date"
            />
          }
          onChange={updateField}
        />

        <FormRowEditable
          form={form()}
          attr="sex"
          label="Sex"
          model={data()}
          viewMode={data().sex}
          editMode={
            <RadioField attr="sex" model={form().editedField} values={sexes} />
          }
          onChange={updateField}
        />

        <FormRowEditable
          form={form()}
          attr="race"
          label="Race"
          model={data()}
          viewMode={data().race}
          editMode={
            <SelectField
              attr="race"
              model={form().editedField}
              values={races}
            />
          }
          onChange={updateField}
        />

        <FormRowEditable
          form={form()}
          attr="height"
          label="Height [cm]"
          model={data()}
          viewMode={floatRounding(data().height, 1)}
          editMode={
            <TextField attr="height" model={form().editedField} width={40} />
          }
          onChange={updateField}
        />

        <FormRowEditable
          form={form()}
          attr="description"
          label="Description"
          model={data()}
          viewMode={<div class="max-height">{data().description}</div>}
          editMode={
            <TextField
              attr="description"
              model={form().editedField}
              multiline="autosize"
              width={200}
            />
          }
          onChange={updateField}
        />

        <Show when={props.api.userLevel >= 3}>
          <FormRowEditable
            form={form()}
            attr="admin_note"
            label="Admin note"
            model={data()}
            viewMode={<div class="max-height">{data().admin_note}</div>}
            editMode={
              <TextField
                attr="admin_note"
                model={form().editedField}
                multiline="autosize"
                width={200}
              />
            }
            onChange={updateField}
          />
        </Show>

        <Show when={props.api.userLevel >= 3}>
          <FormRowEditable
            form={form()}
            attr="monitoring_note"
            label="Monitoring note"
            model={data()}
            viewMode={<div class="max-height">{data().monitoring_note}</div>}
            editMode={
              <TextField
                attr="monitoring_note"
                model={form().editedField}
                multiline="autosize"
                width={200}
              />
            }
            onChange={updateField}
          />
        </Show>
      </table>

      <Show when={props.api.userLevel >= 3}>
        <Button
          onClick={() => {
            setOpenPatSettings(!openPatSettings());
          }}
        >
          Toggle patient monitoring settings
        </Button>

        <Show when={openPatSettings()}>
          <table class="invisible-table invisible-table-big">
            <FormRow label="Name" data={data().name} />

            <FormRow
              label="Added"
              data={new Date(data().created_at).toLocaleString("sk-SK")}
            />

            <FormRow label="Added by" data={data().user.username} />

            <FormRow
              label="Informed consent"
              data={
                <div class={patient().consentOk() ? "text-green" : "text-red"}>
                  {informedConsent[data().informed_consent]}
                </div>
              }
            />

            <Show when={patient().isPaperConsent()}>
              <FormRow
                label="Change consent"
                data={
                  <>
                    <Show when={!patient().consentOk()}>
                      <Button
                        class="text-green"
                        onClick={() => {
                          props.api.approvePaperConsent(data().patient_id);
                        }}
                      >
                        Approve
                      </Button>
                    </Show>
                    <Show when={patient().consentOk()}>
                      <Button
                        class="text-red"
                        onClick={() => {
                          props.api.revokePaperConsent(data().patient_id);
                        }}
                      >
                        Revoke
                      </Button>
                    </Show>
                  </>
                }
              />
            </Show>

            <Show
              when={!patient().isPaperConsent() && props.api.userLevel === 4}
            >
              <FormRow
                label="Change consent"
                data={
                  <>
                    <Show when={!patient().consentOk()}>
                      <Button
                        class="text-green"
                        onClick={() => {
                          props.api.approveTeleConsent(data().patient_id);
                        }}
                      >
                        Approve
                      </Button>
                    </Show>
                    <Show when={patient().consentOk()}>
                      <Button
                        class="text-red"
                        onClick={() => {
                          props.api.revokeTeleConsent(data().patient_id);
                        }}
                      >
                        Revoke
                      </Button>
                    </Show>
                  </>
                }
              />
            </Show>

            <FormRowEditable
              form={form()}
              attr="realm"
              label="Realm"
              model={data()}
              viewMode={seerlinqRealms[data().realm]}
              editMode={
                <SelectField
                  attr="realm"
                  model={form().editedField}
                  values={seerlinqRealms}
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="patient_study"
              label="Seerlinq studies"
              model={data()}
              viewMode={data()
                .patient_study.map((st) => seerlinqStudies[st])
                .join("; ")}
              editMode={
                <SelectMultipleField
                  attr="patient_study"
                  model={form().editedField}
                  values={seerlinqStudies}
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="patient_status"
              label="Patient status"
              model={data()}
              viewMode={patientStatuses[data().patient_status]}
              editMode={
                <SelectField
                  attr="patient_status"
                  model={form().editedField}
                  values={patientStatuses}
                />
              }
              onChange={updateField}
            />

            <FormRow
              label="Connected to users"
              data={data()
                .connected_users.map((user) => user.username)
                .join("; ")}
            />

            <FormRow
              label="Is managed by physicians"
              data={data()
                .physicians.map((physician) =>
                  [physician.given_name, physician.surname].join(" "),
                )
                .join("; ")}
            />

            <FormRow
              label={
                <>
                  Is managed by users in role <strong>physician</strong>
                </>
              }
              data={data()
                .managing_physicians.map((user) => user.username)
                .join("; ")}
            />

            <FormRow
              label="Last measured PPG"
              data={patient().heartCore.last_ppg}
            />

            <For each={patient().schedules.paginatedData}>
              {(schedule) => (
                <FormRow
                  label="Schedule"
                  data={patient().schedules.schedString(schedule)}
                />
              )}
            </For>

            <FormRow label="Patient adherence" data="N/A" />
          </table>

          <Button
            onClick={() => {
              setAddingConnUser(!addingConnUser());
            }}
          >
            Create user for this patient
          </Button>

          <Show when={addingConnUser()}>
            <CreateUserForm patient={patient()} />
          </Show>
        </Show>
      </Show>

      <Show when={props.api.userLevel >= 3}>
        <div style="margin: 20px">
          <h3 style="margin-bottom: -5px">Seerlinq HeartCore</h3>
          <div style="margin-bottom: 10px">
            Patient has <strong>{patient().numDRIDataPoints()}</strong> DRI
            measurements.
            <br />
            <small>
              (Minimum for running the HeartCore is{" "}
              <span>{patient().minDRIHeartCore}</span>)
            </small>
            <br />
            <br />
            HeartCore Report would be generated from{" "}
            <strong>{patient().heartCore.reportStart}</strong>
            <br />
            <small>
              (HeartCore report requires DRIs, heart rates, systolic and
              diastolic blood pressures, fatigue scores, and shortness of
              breath;
              <br />
              at least <span>{patient().minDRIHeartCore}</span> measurements of
              each variable must be recorded for the time span from the starting
              date until today.)
            </small>
          </div>
          <div class="heartcore-container">
            <Button
              disabled={
                patient().driHeartCore.processing || !patient().consentOk()
              }
              onClick={() => {
                if (patient().driHeartCore.resultUrl) {
                  window.open(patient().driHeartCore.resultUrl, "_blank");
                } else {
                  patient().driHeartCore.submit([patient().patientId]);
                }
              }}
              class="heartcore-button"
            >
              <Show when={patient().driHeartCore.processing}>
                <Spinner size={25} />
              </Show>
              <Show when={!patient().driHeartCore.processing}>
                {patient().driHeartCore.buttonText}
              </Show>
            </Button>
            <Button
              disabled={
                patient().heartCoreReport.processing ||
                !patient().heartCore.can_generate_heartcore_report ||
                !patient().consentOk()
              }
              onClick={() => {
                if (patient().heartCoreReport.resultUrl) {
                  window.open(patient().heartCoreReport.resultUrl, "_blank");
                } else {
                  patient().heartCoreReport.submit([patient().patientId]);
                }
              }}
              class="heartcore-button"
            >
              <Show when={patient().heartCoreReport.processing}>
                <Spinner size={25} />
              </Show>
              <Show when={!patient().heartCoreReport.processing}>
                {patient().heartCoreReport.buttonText}
              </Show>
            </Button>
          </div>
        </div>
      </Show>
    </div>
  );
}
