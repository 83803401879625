import { useNavigate } from "@solidjs/router";
import { JSX } from "solid-js";
import { Toasts } from "./common/uiKit/Toasts";

export function RouterRoot(props: { children?: JSX.Element }) {
  const navigate = useNavigate();
  window.navigate = (path) => navigate(path, { scroll: false });

  return (
    <>
      {props.children}

      <Toasts />
    </>
  );
}
