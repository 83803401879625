export function CellCheckboxField(props: {
  model: Record<string, any>;
  attr: string;
  label?: string;
}) {
  return (
    <label>
      {props.label}
      <input
        type="checkbox"
        checked={props.model[props.attr]}
        onChange={(event) => {
          props.model[props.attr] = event.target.checked;
        }}
      />
    </label>
  );
}
