import { createMemo, createResource, createSignal, Show } from "solid-js";
import { ApiConnector } from "../../api/ApiConnector";
import { diagConfidence } from "../../common/constants";
import { Button } from "../../common/uiKit/Button";
import { CellDate } from "../../common/uiKit/tables/cells/CellDate";
import { CellDeleteButton } from "../../common/uiKit/tables/cells/CellDeleteButton";
import { CellEditButtons } from "../../common/uiKit/tables/cells/CellEditButtons";
import { CellEnum } from "../../common/uiKit/tables/cells/CellEnum";
import { CellText } from "../../common/uiKit/tables/cells/CellText";
import { DataTable } from "../../common/uiKit/tables/DataTable";
import { HeaderSort } from "../../common/uiKit/tables/headers/HeaderSort";
import { getObjectFromArray } from "../../common/utils/utils";
import { PatientSubpage } from "../common/PatientSubpage";
import { Patient } from "../Patient";
import { AddDiagsTable } from "./AddDiagsTable";

export function DiagnosesTable(props: { patient: Patient; api: ApiConnector }) {
  const [resource] = createResource(() => props.patient.fetchComorbidities());
  const model = createMemo(() => resource()?.data);
  const [adding, setAdding] = createSignal(false);

  return (
    <PatientSubpage title="Comorbidities" loading={resource.loading}>
      <Button
        onClick={() => {
          setAdding(!adding());
        }}
      >
        Add comorbidities
      </Button>

      <Show when={adding()}>
        <AddDiagsTable
          patient={props.patient}
          diagOptions={resource().diagOptions}
        />
      </Show>

      <br />
      <br />
      <br />

      <DataTable
        model={model()}
        legendExtra={<span>(Dates are in UTC)</span>}
        columns={[
          {
            header1: (
              <HeaderSort
                model={model()}
                attr="diagnosed_at"
                name="Diagnosed"
              />
            ),
            cell: (item, index) => (
              <CellDate
                attr="diagnosed_at"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="diagnosis_name" name="Name" />
            ),
            cell: (item) => item.diagnosis_name,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellEnum
                attr="diagnosis_value"
                index={index}
                model={model()}
                values={getObjectFromArray(
                  resource().diagOptions[item.diagnosis_name],
                )}
              />
            ),
          },
          {
            header1: "Confidence",
            cell: (item, index) => (
              <CellEnum
                attr="diagnosis_confidence"
                index={index}
                model={model()}
                values={getObjectFromArray(diagConfidence)}
              />
            ),
          },
          {
            header1: "Remission",
            cell: (item, index) => (
              <CellDate
                attr="remission"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellText
                attr="comment"
                index={index}
                model={model()}
                multiline="autosize"
                width={80}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("diagnoses", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.userLevel >= 3 && {
            header1: "Delete",
            cell: (item, index) => (
              <CellDeleteButton
                index={index}
                model={model()}
                onConfirm={() =>
                  props.patient.deleteItem("diagnoses", item.uuid)
                }
              />
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </PatientSubpage>
  );
}
