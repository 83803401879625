import { reactive } from "@vue/reactivity";
import { Show } from "solid-js";
import { AddTable } from "../../common/uiKit/tables/AddTable";
import { CellCheckboxField } from "../../common/uiKit/tables/cellFields/CellCheckboxField";
import { CellDateField } from "../../common/uiKit/tables/cellFields/CellDateField";
import { CellTextField } from "../../common/uiKit/tables/cellFields/CellTextField";
import { AddMedication } from "../medications/AddMedication";
import { Patient } from "../Patient";

export function AddSymptomsTable(props: { patient: Patient }) {
  const model = reactive(new AddMedication(props.patient.api, props.patient));
  model.initEmpty();

  return (
    <AddTable
      model={model}
      canAddRows={false}
      setAllDates="date"
      infoExtra={
        <>
          <h4 style="color: #bb16a3">Required symptoms endpoints</h4>
          (Please, use UTC dates to avoid misconceptions.)
        </>
      }
      columns={[
        {
          header: "Symptom date",
          cell: (item) => (
            <CellDateField attr="symptom_date" type="date" model={item} />
          ),
        },
        {
          header: "Symptom",
          cell: (item) => (
            <label>
              {item.symptom_name.charAt(0).toUpperCase() +
                item.symptom_name.slice(1)}
            </label>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <CellTextField attr="symptom_value" model={item} type="number" />
          ),
        },
        {
          header: "Change in 6 months?",
          cell: (item) => (
            <CellCheckboxField
              attr="symptom_change_in_last_six_m"
              model={item}
              label="Yes:"
            />
          ),
        },
        {
          header: "Change date",
          cell: (item) => (
            <Show when={item.symptom_change_in_last_six_m}>
              <CellDateField
                attr="symptom_change_date"
                model={item}
                type="date"
              />
            </Show>
          ),
        },
        {
          header: "Value before",
          cell: (item) => (
            <Show when={item.symptom_change_in_last_six_m}>
              <CellTextField
                attr="symptom_value_before"
                model={item}
                type="number"
              />
            </Show>
          ),
        },
        {
          header: "Comment",
          cell: (item) => (
            <CellTextField attr="comment" model={item} multiline="autosize" />
          ),
        },
      ]}
    />
  );
}
